import { Media } from '@assembly/shared/types/media'
import { Capability } from '../Capabilities/types'
import { Skill } from '../Skills/types'

export interface ICreateResource {
  id?: string
  label: string
  email: string
  phone: string | null
  status: string | null
  firstName: string
  lastName: string
  payoutFrequency: string | null
  payoutCurrency: string
  profileLink: string
  videoInterviewLink: string | null
  introLink: string
  capabilities: string[]
  bio: string
  desiredWeeklyHours: number | null
  desiredHourlyRate: number | null
  availableWeeklyHours: number | null
  paymentCountry: string
  profilePicture: Media | null
  availableStartDate: string | null
  assemblyEmail: string
  hasCompletedOnboarding: boolean
  hasSignedContract: boolean
  contractMonthlyRate: number | null
  benefitsDetail: string | null
  checklists?: any
  highlights?: Highlight[]
  portfolio?: Portfolio[]
  onboardingPages?: OnboardingPage[]
  skills: string[]
  introVideo: Media | null
}

export enum ResourceType {
  Contractor = 'CONTRACTOR',
  Team = 'TEAM',
  Tool = 'TOOL',
}

export enum Status {
  Created = 'CREATED',
  Initialized = 'INITIALIZED',
  Onboarded = 'ONBOARDED',
  Allocated = 'ALLOCATED',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Error = 'ERROR',
}

export enum PayoutFrequency {
  Weekly = 'WEEKLY',
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
}

export type Resource = {
  id: string
  label: string
  email: string
  assemblyEmail: string
  phone: string
  status: string
  firstName: string
  lastName: string
  payoutFrequency: PayoutFrequency
  payoutCurrency: string
  profileLink: string
  videoInterviewLink: string
  introLink: string
  capabilities: Capability[]
  skills: Skill[]
  highlights: Highlight[]
  bio: string
  desiredWeeklyHours: number | null
  desiredHourlyRate: number | null
  availableWeeklyHours: number | null
  paymentCountry: string
  profilePicture: Media | null
  availableStartDate: string
  hasCompletedOnboarding: boolean
  hasCompletedProfile: boolean
  hasSignedContract: boolean
  contractMonthlyRate: number | null
  benefitsDetail: string
  checklists: any
  introVideo: Media | null
  paymentAccountID: string
  paymentSetupComplete: boolean
  w8BEN: Media | null,
  associatedAssemblyLines: AssemblyLineSummary[] | []
}

export type ResourceFormValuesType = {
  label: string
  email: string
  assemblyEmail: string
  phone: string
  status: string
  firstName: string
  lastName: string
  payoutFrequency: string
  payoutCurrency: string
  profileLink: string
  videoInterviewLink: string
  introLink: string
  capabilities: Capability[]
  skills: Skill[]
  highlights: Highlight[]
  portfolio: Portfolio[]
  onboardingPages: OnboardingPage[]
  bio: string
  availableStartDate: any
  desiredWeeklyHours: number | null
  desiredHourlyRate: number | null
  availableWeeklyHours: number | null
  paymentCountry: string
  profilePicture: Media | null
  introVideo: Media | null
  hasCompletedOnboarding: boolean
  hasSignedContract: boolean
  contractMonthlyRate: number | null
  benefitsDetail: string
  invalid_payoutFrequency: boolean
  invalid_status: boolean
  invalid_label: boolean
  invalid_email: boolean
  invalid_firstName: boolean
  invalid_lastName: boolean
  invalid_phone: boolean
  invalid_availableStartDate: boolean
  invalid_assemblyEmail: boolean
}

export enum HighlightType {
  Media = 'MEDIA',
  Text = 'TEXT',
  Url = 'URL',
}

export type Highlight = {
  title: string
  description: string
  question: string
  answerMedia: Media | null
  answerText: string | null
  answerUrl: string | null
  type: HighlightType
}

export enum PortfolioType {
  Media = 'MEDIA',
  Url = 'URL',
}

export type Portfolio = {
  title: string
  description: string
  media: Media | null
  url: string | null
  type: PortfolioType
}

export type OnboardingQuestion = { question: string, choices: string[], answer: string }

export type OnboardingPage = {
  link: string
  title: string
  challengeQuestions: OnboardingQuestion[]
}

export type AssemblyLineSummary = {
  id: string
  name: string
  orgID: string
  startDate: string
}
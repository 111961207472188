import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import CreateCapabilityDialog from './CreateCapabilityDialog'
import { deleteCapability, getAllCapabilites } from '@assembly/api/capaility'
import {
  Capability,
  SelectedCapabilityInitialState,
} from '@assembly/pages/Capabilities/types'
import CapabilitiesList from './CapabilitiesList'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { debounce } from 'lodash'

export default function Capabilities() {
  const [createCapabilityDialogIsVisible, setCreateCapabilityDialogIsVisible] =
    React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { store, setCapabilities } = useAppStore()
  const didMount = React.useRef(false)
  const selectedCapabilityInitialState = { capability: undefined, index: -1 }
  const [selectedCapability, setSelectedCapability] =
    React.useState<SelectedCapabilityInitialState>(
      selectedCapabilityInitialState
    )
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const { addAlert } = useSnackbars()
  const [searchResults, setSearchResults] = React.useState<Capability[]>()
  const [isSearching, setIsSearching] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isCapabilitiesLoaded) {
        getAllCapabilitesAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const handleClickCreateCapability = () => {
    setCreateCapabilityDialogIsVisible(true)
  }

  const handleCloseCreateCapabilityDialog = () => {
    setCreateCapabilityDialogIsVisible(false)
    setSelectedCapability(selectedCapabilityInitialState)
  }

  const getAllCapabilitesAsync = async () => {
    try {
      setIsLoading(true)

      const params = new URLSearchParams()
      params.append('search', '')
      const { data } = await getAllCapabilites(params)
      setCapabilities(data)
      setIsLoading(false)
    } catch (error) {
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
      setIsLoading(false)
    }
  }

  const onClickDeleteCapability = (capability: Capability, index: number) => {
    setSelectedCapability({
      capability,
      index,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const onClickEditCapability = (capability: Capability, index: number) => {
    setSelectedCapability({
      capability,
      index,
    })
    setCreateCapabilityDialogIsVisible(true)
  }

  const onCapabilityEdited = (capability: Capability) => {
    store.capabilities[selectedCapability.index] = capability
    setCapabilities([...store.capabilities])
  }

  const onCapabilityCreated = (capability: Capability) => {
    store.capabilities.unshift(capability)
    setCapabilities([...store.capabilities])
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedCapability(selectedCapabilityInitialState)
  }

  const removeCapabilityFromList = () => {
    if (selectedCapability.index === -1) {
      return
    }

    store.capabilities.splice(selectedCapability.index, 1)
    setCapabilities([...store.capabilities])
  }

  const deleteCapabilityAsync = async () => {
    if (!selectedCapability.capability) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteCapability(selectedCapability.capability.id)
      setIsDeleting(false)
      setSelectedCapability(selectedCapabilityInitialState)
      setDeleteInputValue('')
      removeCapabilityFromList()
      addAlert({ message: 'Capability Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      setIsDeleting(false)
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
    }
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const handleSearchCapabilities = async (value: string) => {
    try {
      setIsSearching(true)
      const params = new URLSearchParams()
      params.append('search', value)
      const { data } = await getAllCapabilites(params)
      setSearchResults(data)
      setIsSearching(false)
    } catch (error) {
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
      setIsSearching(false)
    }
  }

  const handleChangeSearch = debounce((value: string) => {
    if (value === '') {
      setSearchResults(undefined)
      return
    }

    handleSearchCapabilities(value)
  }, 500)

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        onChangeSearch={handleChangeSearch}
        inputPlaceholder="Search Capabilities"
        buttonLabel="Create Capability"
        onClickButton={handleClickCreateCapability}
        isSearching={isSearching}
      />
      {searchResults && searchResults.length === 0 && (
        <Typography marginTop={6}>No capabilities found</Typography>
      )}
      <CapabilitiesList
        capabilities={searchResults || store.capabilities}
        onClickDelete={onClickDeleteCapability}
        onClickEdit={onClickEditCapability}
      />
      <CreateCapabilityDialog
        open={createCapabilityDialogIsVisible}
        onClose={handleCloseCreateCapabilityDialog}
        capability={selectedCapability.capability}
        onSuccess={
          selectedCapability.capability
            ? onCapabilityEdited
            : onCapabilityCreated
        }
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedCapability.capability?.name || ''}
        onClickDelete={deleteCapabilityAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

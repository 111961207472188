import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Roles, TeamMember } from '@assembly/pages/Teams/types'
import { getTeamMembersByOrgAndRole } from '@assembly/api/teamMember'
import { stringAvatar } from '@assembly/utils'

type OwnerSearchAndSelectProps = {
  onChange: (resource: TeamMember) => void
  value: TeamMember | null
  error: boolean
  helperText: string
  orgId: string
}

export default function OwnerSearchAndSelect({
  onChange,
  value,
  error,
  helperText,
  orgId,
}: OwnerSearchAndSelectProps) {
  const [options, setOptions] = React.useState<TeamMember[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (orgId) {
      getResourcesAsync()
    }
  }, [orgId])

  const getResourcesAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getTeamMembersByOrgAndRole(
        orgId,
        Roles.AssemblyLineAdmin
      )
      setOptions([...data])
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Autocomplete
      loading={loading}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      autoComplete
      includeInputInList
      value={value}
      onChange={(_: any, newValue: any) => {
        onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Owner"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: value ? (
              <InputAdornment position="start">
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: stringAvatar(value?.name || '').sx.bgcolor,
                  }}
                />
              </InputAdornment>
            ) : null,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <Avatar
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: stringAvatar(option.name).sx.bgcolor,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {option.name}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

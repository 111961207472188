import { axiosWithConfig } from '@assembly/config/axios'

export const getSkills = (payload: any) => {
    return axiosWithConfig.post(`/admin/assembly_line/skills/search`, payload, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
}

export const createSkill = (payload: any) => {
    return axiosWithConfig.post(`/admin/assembly_line/skills`, payload)
}

export const updateSkill = (payload: any) => {
    return axiosWithConfig.put(`/admin/assembly_line/skill`, payload)
}

export const deleteSkill = (id: string) => {
    return axiosWithConfig.delete(`/admin/assembly_line/skill/${id}`)
}
import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { Resource } from '@assembly/pages/Resources/types'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { InvoiceStatusTypes } from '../ResourcePayoutInvoices/types'
import { capitalize } from 'lodash'
import moment from 'moment-timezone'
import { createPayoutInvoice } from '@assembly/api/resources'
import { getApiErrorMsg } from '@assembly/utils'

const StyledTextField = styled(TextField)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const StyledDatePicker = styled(DatePicker)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '15px',
}))

const StyledFormControl = styled(FormControl)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

interface CreateResourcePayoutDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateResourcePayoutDialogTitle(
  props: CreateResourcePayoutDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateResourcePayoutDialogProps {
  open: boolean
  onClose: () => void
  resource?: Resource
}

export default function CreateResourcePayoutDialog(
  props: CreateResourcePayoutDialogProps
) {
  const { open, onClose, resource } = props
  const formInitialValues = {
    billingStart: null,
    billingEnd: null,
    billableAmount: '',
    billableUnits: '',
    details: '',
    billedAssemblyLine: '',
    status: InvoiceStatusTypes.CREATED,
    statusMessage: '',
    invalid_billingStart: false,
    invalid_billingEnd: false,
    invalid_billableAmount: false,
    invalid_billableUnits: false,
    invalid_details: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      !formValues.billingStart ||
      !formValues.billingEnd ||
      !formValues.billableAmount ||
      !formValues.billableUnits ||
      !formValues.details
    ) {
      setFormValues({
        ...formValues,
        invalid_billingStart: !formValues.billingStart,
        invalid_billingEnd: !formValues.billingEnd,
        invalid_billableAmount: !formValues.billableAmount,
        invalid_billableUnits: !formValues.billableUnits,
        invalid_details: !formValues.details,
      })
      return
    }

    setIsSaving(true)

    let payload = {
      billingStart: moment(formValues.billingStart).format('YYYYMMDD'),
      billingEnd: moment(formValues.billingEnd).format('YYYYMMDD'),
      billableAmount: Number(formValues.billableAmount),
      billableUnits: Number(formValues.billableUnits),
      details: formValues.details,
      status: formValues.status,
      statusMessage: formValues.statusMessage,
      resourceID: resource?.id,
      billedAssemblyLine: formValues.billedAssemblyLine,
    }

    try {
      const { data } = await createPayoutInvoice(payload)
      addAlert({
        message: data.message || 'Successfully created payout invoice',
        type: SnackbarType.Success,
      })
      setIsSaving(false)
      onClose()
    } catch (error) {
      setIsSaving(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeStartDate = (date: any) => {
    setFormValues({
      ...formValues,
      billingStart: date,
      invalid_billingStart: false,
    })
  }

  const handleChangeEndDate = (date: any) => {
    setFormValues({
      ...formValues,
      billingEnd: date,
      invalid_billingEnd: false,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateResourcePayoutDialogTitle onClose={handleClose}>
          Create Payout
        </CreateResourcePayoutDialogTitle>
        <DialogContent dividers={true}>
          <Row>
            <StyledDatePicker
              label="Billing Start Date"
              value={formValues.billingStart}
              onChange={handleChangeStartDate}
              slotProps={{
                textField: {
                  helperText: formValues.invalid_billingStart ? 'Required' : '',
                  error: formValues.invalid_billingStart,
                },
              }}
            />
            <StyledDatePicker
              label="Billing End Date"
              value={formValues.billingEnd}
              onChange={handleChangeEndDate}
              slotProps={{
                textField: {
                  helperText: formValues.invalid_billingEnd ? 'Required' : '',
                  error: formValues.invalid_billingEnd,
                },
              }}
            />
          </Row>
          <Row>
            <StyledTextField
              value={formValues.billableAmount}
              onChange={handleChangeFormField}
              name="billableAmount"
              label="Billable Amount"
              type="number"
              error={formValues.invalid_billableAmount}
              helperText={formValues.invalid_billableAmount ? 'Required' : ''}
            />
            <StyledTextField
              value={formValues.billableUnits}
              onChange={handleChangeFormField}
              name="billableUnits"
              label="Billable Units"
              type="number"
              error={formValues.invalid_billableUnits}
              helperText={formValues.invalid_billableUnits ? 'Required' : ''}
            />
          </Row>
          <StyledFormControl>
            <InputLabel id="billed-select-label">
              Billed Assembly Line
            </InputLabel>
            <Select
              labelId="billed-select-label"
              value={formValues.billedAssemblyLine}
              label="Billed Assembly Line"
              name="billedAssemblyLine"
              onChange={handleChangeSelect}
            >
              {(resource as any).associatedAssemblyLines.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledTextField
            value={formValues.details}
            onChange={handleChangeFormField}
            name="details"
            label="Details"
            multiline
            rows={4}
            error={formValues.invalid_details}
            helperText={formValues.invalid_details ? 'Required' : ''}
          />
          <StyledFormControl>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={formValues.status}
              label="Status"
              name="status"
              onChange={handleChangeSelect}
            >
              {Object.entries(InvoiceStatusTypes).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {capitalize(key.replace(/_/g, ' '))}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledTextField
            value={formValues.statusMessage}
            onChange={handleChangeFormField}
            name="statusMessage"
            label="Status Message"
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  FormControlLabel,
  Switch,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  colors,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import {
  ICreateResource,
  Resource,
  Status,
  PayoutFrequency,
  ResourceFormValuesType,
  Highlight,
  Portfolio,
  OnboardingPage,
} from '@assembly/pages/Resources/types'
import {
  createResource,
  updateResource,
  uploadMedia,
} from '@assembly/api/resources'
import validator from 'validator'
import ReactPhoneInput from 'react-phone-input-material-ui'
import {
  TextFieldPhoneInput,
  CapabilitiesSearchAndSelect,
  AvatarUpload,
  CircularProgressWithLabel,
} from '@assembly/components'
import { Capability } from '../Capabilities/types'
import { getApiErrorMsg, getMediaUrl } from '@assembly/utils'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment-timezone'
import { supportedCoutries } from '@assembly/shared/types/countriesAndCurrencies'
import { styled } from '@mui/material/styles'
import SkillsSearchAndSelect from '@assembly/components/SkillsSearchAndSelect'
import { Skill } from '../Skills/types'
import AddHighlightDialog from './AddHighlightDialog'
import HighlightIcon from '@mui/icons-material/Highlight'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddPortfolioDialog from './AddPortfolioDialog'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import AddOnboardingPageDialog from './AddOnboardingPageDialog'
import ArticleIcon from '@mui/icons-material/Article'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Media } from '@assembly/shared/types/media'

const SetupFeildsBox = styled(Box)(() => ({
  border: '2px solid',
  padding: '15px',
  borderColor: colors.grey[400],
  marginTop: '10px',
  borderRadius: '5px',
}))

const SectionLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
  marginTop: '20px',
  marginBottom: '10px',
}))

const MainSectionLabel = styled(Typography)(() => ({
  fontSize: '22px',
  fontWeight: 500,
  marginBottom: '10px',
}))

const OneLineTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  width: '80%',
}))

const StyledButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const HighlightsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '10px',
}))

const MediaButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}))

const UploadProgressIndicatorBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

interface CreateResourceDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateResourceDialogTitle(props: CreateResourceDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateResourceDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (resource: Resource) => void
  resource?: Resource
}

export default function CreateResourceDialog(props: CreateResourceDialogProps) {
  const { open, onClose, onSuccess, resource } = props
  const formInitialValues: ResourceFormValuesType = {
    label: '',
    email: '',
    assemblyEmail: '',
    phone: '',
    status: Status.Created,
    firstName: '',
    lastName: '',
    payoutFrequency: PayoutFrequency.Monthly,
    payoutCurrency: 'USD',
    paymentCountry: 'US',
    profileLink: '',
    videoInterviewLink: '',
    introLink: '',
    capabilities: [],
    skills: [],
    highlights: [],
    portfolio: [],
    onboardingPages: [],
    introVideo: null,
    bio: '',
    profilePicture: null,
    availableStartDate: null,
    desiredWeeklyHours: 40,
    desiredHourlyRate: null,
    availableWeeklyHours: 40,
    hasCompletedOnboarding: false,
    hasSignedContract: false,
    contractMonthlyRate: null,
    benefitsDetail: '',
    invalid_payoutFrequency: false,
    invalid_status: false,
    invalid_label: false,
    invalid_email: false,
    invalid_firstName: false,
    invalid_lastName: false,
    invalid_phone: false,
    invalid_availableStartDate: false,
    invalid_assemblyEmail: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  const [checkListItems, setCheckListItems] = React.useState({})
  const [addHighlightDialogOpen, setAddHighlightDialogOpen] =
    React.useState<boolean>(false)
  const [selectedHighlightIndex, setSelectedHighlightIndex] =
    React.useState<number>(-1)
  const [addPortfolioDialogOpen, setAddPortfolioDialogOpen] =
    React.useState<boolean>(false)
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] =
    React.useState<number>(-1)
  const [addOnboardingPageDialogOpen, setAddOnboardingPageDialogOpen] =
    React.useState<boolean>(false)
  const [selectedOnboardingPageIndex, setSelectedOnboardingPageIndex] =
    React.useState<number>(-1)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const [isIntroVideoUploading, setIsIntroVideoUploading] =
    React.useState<boolean>(false)

  const getCheckListItemLabel = (str: string) => {
    return str.split(' ').join('')
  }

  React.useEffect(() => {
    if (open) {
      if (resource) {
        setFormValues({
          ...formValues,
          ...resource,
          availableStartDate:
            resource.availableStartDate &&
            resource.availableStartDate !== 'Invalid date'
              ? moment(resource.availableStartDate, 'YYYY-MM-DD')
              : null,
          skills: resource.skills ? resource.skills : [],
        })

        if (resource.status && resource.checklists[resource.status]) {
          const items = resource.checklists[resource.status]

          items.forEach((item: any) => {
            setCheckListItems((prev) => ({
              ...prev,
              [getCheckListItemLabel(item.title)]: {
                ...item,
                key: getCheckListItemLabel(item.title),
              },
            }))
          })
        }
      }
    }
  }, [resource, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.firstName.length === 0 ||
      formValues.lastName.length === 0 ||
      formValues.email.length === 0 ||
      !validator.isEmail(formValues.email)
    ) {
      setFormValues({
        ...formValues,
        invalid_firstName: formValues.firstName.length === 0,
        invalid_lastName: formValues.lastName.length === 0,
        invalid_email:
          formValues.email.length === 0 || !validator.isEmail(formValues.email),
      })
      return
    }
    setIsSaving(true)
    const payload: ICreateResource = {
      profilePicture:
        formValues.profilePicture && formValues.profilePicture.id
          ? formValues.profilePicture
          : null,
      label: formValues.label,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      assemblyEmail: formValues.assemblyEmail,
      phone: formValues.phone || null,
      status: formValues.status || null,
      payoutFrequency: formValues.payoutFrequency || PayoutFrequency.Monthly,
      payoutCurrency: formValues.payoutCurrency,
      paymentCountry: formValues.paymentCountry,
      capabilities: formValues.capabilities.map((capability) => capability.id),
      desiredHourlyRate: formValues.desiredHourlyRate,
      desiredWeeklyHours: formValues.desiredWeeklyHours,
      availableWeeklyHours: formValues.availableWeeklyHours,
      profileLink: formValues.profileLink,
      videoInterviewLink: formValues.videoInterviewLink,
      introLink: formValues.introLink,
      bio: formValues.bio,
      availableStartDate: formValues.availableStartDate
        ? moment(formValues.availableStartDate).format('YYYY-MM-DD')
        : null,
      hasCompletedOnboarding: formValues.hasCompletedOnboarding,
      hasSignedContract: formValues.hasSignedContract,
      contractMonthlyRate: formValues.contractMonthlyRate,
      benefitsDetail: formValues.benefitsDetail,
      highlights: formValues.highlights,
      portfolio: formValues.portfolio,
      onboardingPages: formValues.onboardingPages,
      skills: formValues.skills.map((skill) => skill.id),
      introVideo: formValues.introVideo,
    }

    if (resource) {
      try {
        const { data } = await updateResource({
          id: resource.id,
          ...payload,
          checklists: {
            ...resource.checklists,
            [resource.status]: Object.values(checkListItems).map(
              (item: any) => ({
                title: item.title,
                description: item.description,
                done: item.done,
              })
            ),
          },
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Resource Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createResource(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Resource Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setCheckListItems({})
    setIsSaving(false)
    onClose()
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangePhone = (value: any) => {
    setFormValues({
      ...formValues,
      phone: value,
      invalid_phone: false,
    })
  }

  const handleChangeCapabilities = (capabilities: Capability[]) => {
    setFormValues({
      ...formValues,
      capabilities,
    })
  }

  const handleChangeSkills = (skills: Skill[]) => {
    setFormValues({
      ...formValues,
      skills,
    })
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])

    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData)
      setFormValues({
        ...formValues,
        profilePicture: data,
      })
      setIsMediaUploading(false)
    } catch (error: any) {
      console.error(error)
      setIsMediaUploading(false)
      addAlert({
        message: 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleRemoveMedia = () => {
    setFormValues({
      ...formValues,
      profilePicture: null,
    })
  }

  const handleChangeStartDate = (date: any) => {
    setFormValues({
      ...formValues,
      availableStartDate: date,
      invalid_availableStartDate: false,
    })
  }

  const handleCloseHighlightDialog = () => {
    setAddHighlightDialogOpen(false)
    setSelectedHighlightIndex(-1)
  }

  const handleOpenAddHighlightDialog = () => {
    setAddHighlightDialogOpen(true)
  }

  const onHighlightCreated = (highlight: Highlight) => {
    setFormValues({
      ...formValues,
      highlights: [...formValues.highlights, highlight],
    })
  }

  const onHighlightEdited = (highlight: Highlight) => {
    const newHighlights = [...formValues.highlights]
    newHighlights[selectedHighlightIndex] = highlight
    setFormValues({
      ...formValues,
      highlights: newHighlights,
    })
    setSelectedHighlightIndex(-1)
  }

  const handleClickDeleteHighlight = (index: number) => {
    const newHighlights = [...formValues.highlights]
    newHighlights.splice(index, 1)
    setFormValues({
      ...formValues,
      highlights: newHighlights,
    })
  }

  const handleClickEditHighlight = (index: number) => {
    setSelectedHighlightIndex(index)
    setAddHighlightDialogOpen(true)
  }

  const handleOpenAddPortfolioDialog = () => {
    setAddPortfolioDialogOpen(true)
  }

  const handleClosePortfolioDialog = () => {
    setAddPortfolioDialogOpen(false)
    setSelectedPortfolioIndex(-1)
  }

  const onPortfolioCreated = (portfolio: Portfolio) => {
    setFormValues({
      ...formValues,
      portfolio: [...formValues.portfolio, portfolio],
    })
  }

  const onPortfolioEdited = (portfolio: Portfolio) => {
    const newPortfolio = [...formValues.portfolio]
    newPortfolio[selectedPortfolioIndex] = portfolio
    setFormValues({
      ...formValues,
      portfolio: newPortfolio,
    })
    setSelectedPortfolioIndex(-1)
  }

  const handleClickDeletePortfolio = (index: number) => {
    const newPortfolio = [...formValues.portfolio]
    newPortfolio.splice(index, 1)
    setFormValues({
      ...formValues,
      portfolio: newPortfolio,
    })
  }

  const handleClickEditPortfolio = (index: number) => {
    setSelectedPortfolioIndex(index)
    setAddPortfolioDialogOpen(true)
  }

  const handleClickOpenAddOnboardingPageDialog = () => {
    setAddOnboardingPageDialogOpen(true)
  }

  const handleCloseAddOnboardingPageDialog = () => {
    setAddOnboardingPageDialogOpen(false)
    setSelectedOnboardingPageIndex(-1)
  }

  const handleClickDeleteOnboardingPage = (index: number) => {
    const newOnboardingPages = [...formValues.onboardingPages]
    newOnboardingPages.splice(index, 1)
    setFormValues({
      ...formValues,
      onboardingPages: newOnboardingPages,
    })
  }

  const handleClickEditOnboardingPage = (index: number) => {
    setSelectedOnboardingPageIndex(index)
    setAddOnboardingPageDialogOpen(true)
  }

  const onOnboardingPageCreated = (onboardingPage: OnboardingPage) => {
    setFormValues({
      ...formValues,
      onboardingPages: [...formValues.onboardingPages, onboardingPage],
    })
  }

  const onOnboardingPageEdited = (onboardingPage: OnboardingPage) => {
    const newOnboardingPages = [...formValues.onboardingPages]
    newOnboardingPages[selectedOnboardingPageIndex] = onboardingPage
    setFormValues({
      ...formValues,
      onboardingPages: newOnboardingPages,
    })
    setSelectedOnboardingPageIndex(-1)
  }

  const handleChangeUploadIntroVideo = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsIntroVideoUploading(true)
      const { data } = await uploadMedia(formData, (progress: any) => {
        setUploadProgress(progress)
      })
      setFormValues({
        ...formValues,
        introVideo: data,
      })
      setIsIntroVideoUploading(false)
      setUploadProgress(0)
    } catch (error: any) {
      setIsIntroVideoUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateResourceDialogTitle onClose={handleClose}>
          {resource ? 'Edit' : 'Create'} Resource
        </CreateResourceDialogTitle>
        <DialogContent dividers={true}>
          <AvatarUpload
            onChangeMedia={handleChangeMedia}
            isUploading={isMediaUploading}
            src={getMediaUrl(formValues.profilePicture?.media || '')}
            onClearPhoto={handleRemoveMedia}
          />
          <SetupFeildsBox>
            <MainSectionLabel>Setup Fields</MainSectionLabel>
            <Box display="flex" justifyContent="space-between" marginTop="10px">
              <Box width="48%">
                <TextField
                  value={formValues.firstName}
                  margin="dense"
                  name="firstName"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={formValues.invalid_firstName}
                  onChange={handleChangeFormField}
                  helperText={formValues.invalid_firstName ? 'Required' : ''}
                />
              </Box>
              <Box width="48%">
                <TextField
                  value={formValues.lastName}
                  margin="dense"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={formValues.invalid_lastName}
                  onChange={handleChangeFormField}
                  helperText={formValues.invalid_lastName ? 'Required' : ''}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop="10px">
              <Box width="48%">
                <TextField
                  value={formValues.email}
                  margin="dense"
                  name="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  error={formValues.invalid_email}
                  onChange={handleChangeFormField}
                  helperText={
                    formValues.invalid_email
                      ? formValues.email.length === 0
                        ? 'Required'
                        : 'Invalid email'
                      : ''
                  }
                />
              </Box>
              <Box width="48%" marginTop="8px">
                <TextField
                  value={formValues.contractMonthlyRate || ''}
                  margin="dense"
                  name="contractMonthlyRate"
                  label="Contractor Monthly Rate"
                  type="number"
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeFormField}
                  sx={{ margin: 0 }}
                />
              </Box>
            </Box>
            <Box marginTop="10px" display="flex" justifyContent="space-between">
              <Box width="100%">
                <TextField
                  value={formValues.benefitsDetail || ''}
                  margin="dense"
                  name="benefitsDetail"
                  label="Benefits Detail"
                  type="string"
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeFormField}
                />
              </Box>
            </Box>
            <TextField
              value={formValues.bio || ''}
              margin="dense"
              name="bio"
              label="Bio"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
              multiline
              rows={10}
              sx={{ marginTop: '15px' }}
            />
            <Box marginTop="25px">
              <CapabilitiesSearchAndSelect
                onChange={handleChangeCapabilities}
                value={formValues.capabilities}
              />
            </Box>
            <Box marginTop="25px">
              <SkillsSearchAndSelect
                onChange={handleChangeSkills}
                value={formValues.skills}
              />
            </Box>
          </SetupFeildsBox>
          <HighlightsBox>
            <SectionLabel>Highlights</SectionLabel>
            <StyledButton
              variant="contained"
              onClick={handleOpenAddHighlightDialog}
            >
              Add Highlight
            </StyledButton>
          </HighlightsBox>
          <List>
            {formValues.highlights.map((highlight, index) => (
              <React.Fragment>
                <ListItem
                  key={index}
                  secondaryAction={
                    <Box>
                      <IconButton
                        onClick={() => handleClickEditHighlight(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDeleteHighlight(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <HighlightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <OneLineTypography>{highlight.title}</OneLineTypography>
                    }
                    secondary={highlight.type}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <HighlightsBox>
            <SectionLabel>Portfolio</SectionLabel>
            <StyledButton
              variant="contained"
              onClick={handleOpenAddPortfolioDialog}
            >
              Add Portfolio
            </StyledButton>
          </HighlightsBox>
          <List>
            {formValues.portfolio.map((item, index) => (
              <Box key={index}>
                <ListItem
                  secondaryAction={
                    <Box>
                      <IconButton
                        onClick={() => handleClickEditPortfolio(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDeletePortfolio(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <InsertPhotoIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <OneLineTypography>{item.title}</OneLineTypography>
                    }
                    secondary={item.type}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
          <HighlightsBox>
            <SectionLabel>Onboarding Pages</SectionLabel>
            <StyledButton
              variant="contained"
              onClick={handleClickOpenAddOnboardingPageDialog}
            >
              Add Onboarding Page
            </StyledButton>
          </HighlightsBox>
          <List>
            {formValues.onboardingPages.map((item, index) => (
              <Box key={index}>
                <ListItem
                  secondaryAction={
                    <Box>
                      <IconButton
                        onClick={() => handleClickEditOnboardingPage(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDeleteOnboardingPage(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ArticleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<OneLineTypography>{item.link}</OneLineTypography>}
                  />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
          <SectionLabel>Intro Video</SectionLabel>
          <MediaButtonBox>
            <LoadingButton
              variant="contained"
              component="label"
              sx={{ boxShadow: 'none' }}
              loading={isMediaUploading}
            >
              {formValues.introVideo ? 'Change Video' : 'Add Video'}
              <input
                type="file"
                hidden
                value={''}
                onChange={handleChangeUploadIntroVideo}
                disabled={isMediaUploading}
                accept="video/mp4"
              />
            </LoadingButton>
            {isIntroVideoUploading && (
              <UploadProgressIndicatorBox>
                <CircularProgressWithLabel value={uploadProgress} />
                <Typography variant="body2">
                  {isMediaUploading && uploadProgress < 100
                    ? 'Uploading...'
                    : 'Processing...'}
                </Typography>
              </UploadProgressIndicatorBox>
            )}
          </MediaButtonBox>
          {formValues.introVideo && (
            <VideoPlayer
              url={getMediaUrl((formValues.introVideo as Media).media)}
            />
          )}
          <SectionLabel>Other Fields</SectionLabel>
          <TextField
            value={formValues.label}
            margin="dense"
            label="Label"
            type="text"
            name="label"
            fullWidth
            variant="outlined"
            onChange={handleChangeFormField}
            error={formValues.invalid_label}
            helperText={formValues.invalid_label ? 'Required' : ''}
          />
          <Box marginTop="25px">
            <ReactPhoneInput
              value={formValues.phone || ''}
              onChange={handleChangePhone}
              component={TextFieldPhoneInput}
              country={'us'}
            />
          </Box>
          <TextField
            value={formValues.assemblyEmail || ''}
            margin="dense"
            name="assemblyEmail"
            label="Assembly Email"
            type="email"
            fullWidth
            variant="outlined"
            error={formValues.invalid_assemblyEmail}
            onChange={handleChangeFormField}
            helperText={
              formValues.invalid_assemblyEmail
                ? formValues.assemblyEmail.length === 0
                  ? 'Required'
                  : 'Invalid  email'
                : ''
            }
            sx={{ margin: 0, marginTop: '25px' }}
          />
          <Box display="flex" justifyContent="space-between">
            <Box marginTop="25px" width="100%">
              <FormControl fullWidth>
                <InputLabel id="type-select-label">Payout Frequency</InputLabel>
                <Select
                  labelId="payout-select-label"
                  id="payout-select"
                  value={formValues.payoutFrequency}
                  label="Payout Frequency"
                  name="payoutFrequency"
                  onChange={handleChangeSelect}
                  error={formValues.invalid_payoutFrequency}
                >
                  {Object.entries(PayoutFrequency).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                {formValues.invalid_payoutFrequency && (
                  <FormHelperText error={formValues.invalid_payoutFrequency}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box marginTop="25px">
            <DatePicker
              label="Available Start Date"
              sx={{ width: '100%' }}
              value={formValues.availableStartDate}
              onChange={handleChangeStartDate}
              slotProps={{
                textField: {
                  helperText: formValues.invalid_availableStartDate
                    ? 'Invalid Start Date'
                    : '',
                  error: formValues.invalid_availableStartDate,
                },
              }}
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box marginTop="25px" width="48%">
              <FormControl fullWidth>
                <InputLabel id="payout-select-label">
                  Payout Currency
                </InputLabel>
                <Select
                  labelId="payout-select-label"
                  value={formValues.payoutCurrency}
                  label="Payout Currency"
                  name="payoutCurrency"
                  onChange={handleChangeSelect}
                >
                  {supportedCoutries.map((country) => (
                    <MenuItem
                      key={country.countryCode}
                      value={country.currency}
                    >
                      {country.currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box marginTop="25px" width="48%">
              <FormControl fullWidth>
                <InputLabel id="pc-select-label">Payment Country</InputLabel>
                <Select
                  labelId="pc-select-label"
                  value={formValues.paymentCountry}
                  label="Payment Country"
                  name="paymentCountry"
                  onChange={handleChangeSelect}
                >
                  {supportedCoutries.map((country) => (
                    <MenuItem
                      key={country.countryCode}
                      value={country.countryCode}
                    >
                      {country.countryCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box marginTop="10px" display="flex" justifyContent="space-between">
            <Box width="48%">
              <TextField
                value={formValues.profileLink || ''}
                margin="dense"
                name="profileLink"
                label="Profile Link"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            </Box>
            <Box width="48%">
              <TextField
                value={formValues.introLink || ''}
                margin="dense"
                name="introLink"
                label="Intro Link"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            </Box>
          </Box>
          <Box marginTop="10px" display="flex" justifyContent="space-between">
            <Box width="96%">
              <TextField
                value={formValues.videoInterviewLink || ''}
                margin="dense"
                name="videoInterviewLink"
                label="Video Interview Link"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            </Box>
          </Box>
          <Box marginTop="10px">
            <TextField
              value={formValues.desiredWeeklyHours}
              margin="dense"
              name="desiredWeeklyHours"
              label="Desired Weekly Hours"
              type="number"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
            />
          </Box>
          <Box marginTop="10px" display="flex" justifyContent="space-between">
            <Box width="48%">
              <TextField
                value={formValues.desiredHourlyRate || ''}
                margin="dense"
                name="desiredHourlyRate"
                label="Desired Hourly Rate"
                type="number"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            </Box>
            <Box width="48%">
              <TextField
                value={formValues.availableWeeklyHours}
                margin="dense"
                name="availableWeeklyHours"
                label="Available Weekly Hours"
                type="number"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            </Box>
          </Box>
          <Box marginTop="20px">
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.hasCompletedOnboarding}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      hasCompletedOnboarding: event.target.checked,
                    })
                  }}
                />
              }
              label="Has Completed Onboarding"
            />
          </Box>
          <Box marginTop="10px">
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.hasSignedContract}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      hasSignedContract: event.target.checked,
                    })
                  }}
                />
              }
              label="Has Signed Contract"
            />
          </Box>
          <Box marginTop="25px">
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={formValues.status}
                label="Status"
                name="status"
                onChange={handleChangeSelect}
                error={formValues.invalid_status}
              >
                {Object.entries(Status).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              {formValues.invalid_status && (
                <FormHelperText error={formValues.invalid_status}>
                  Required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {resource && (
            <Box marginTop={3}>
              {Object.values(checkListItems).length > 0 && (
                <Typography variant="h5" fontWeight={500} marginBottom={1}>
                  Checklist
                </Typography>
              )}
              <Box display="flex" flexWrap="wrap" gap={4}>
                {Object.values(checkListItems).map((item: any) => (
                  <FormControlLabel
                    key={item.key}
                    control={
                      <Switch
                        checked={item.done}
                        onChange={(event) => {
                          setCheckListItems({
                            ...checkListItems,
                            [item.key]: {
                              ...item,
                              done: event.target.checked,
                            },
                          })
                        }}
                      />
                    }
                    label={item.title}
                  />
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            loading={isSaving}
            onClick={handleClickSave}
            disabled={isIntroVideoUploading || isMediaUploading}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AddHighlightDialog
        open={addHighlightDialogOpen}
        onClose={handleCloseHighlightDialog}
        onSuccess={
          selectedHighlightIndex !== -1 ? onHighlightEdited : onHighlightCreated
        }
        highlight={formValues.highlights[selectedHighlightIndex]}
      />
      <AddPortfolioDialog
        open={addPortfolioDialogOpen}
        onClose={handleClosePortfolioDialog}
        onSuccess={
          selectedPortfolioIndex !== -1 ? onPortfolioEdited : onPortfolioCreated
        }
        portfolio={formValues.portfolio[selectedPortfolioIndex]}
      />
      <AddOnboardingPageDialog
        open={addOnboardingPageDialogOpen}
        onClose={handleCloseAddOnboardingPageDialog}
        onSuccess={
          selectedOnboardingPageIndex !== -1
            ? onOnboardingPageEdited
            : onOnboardingPageCreated
        }
        onboardingPage={formValues.onboardingPages[selectedOnboardingPageIndex]}
      />
    </div>
  )
}

function VideoPlayer({ url }: any) {
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (
    <video
      controls
      ref={videoRef}
      width={'100%'}
      style={{ marginTop: '15px', borderRadius: '5px', maxHeight: '320px' }}
    >
      <source src={url} />
    </video>
  )
}

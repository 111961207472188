import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import { Candidate, CandidateState, EvaluationState } from './types'
import {CircularProgressWithLabel, InfoItem} from '@assembly/components'
import isEmail from 'validator/lib/isEmail'
import { updateCandidate, uploadMedia } from '@assembly/api/candidates'
import { Media } from '@assembly/shared/types/media'

const StyledTextField = styled(TextField)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const StyledFormControl = styled(FormControl)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const MediaButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  marginBottom: '20px',
}))

const UploadProgressIndicatorBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

const SectionTitle = styled(Typography)(() => ({
  fontSize: '20px',
  marginBottom: '10px',
  fontWeight: 500,
}))

type EditCandidateDialogProps = {
  open: boolean
  onClose: () => void
  candidate: Candidate | null
  onSuccess: (candidate: Candidate) => void
}

type InitialFormValues = {
  firstName: string
  lastName: string
  email: string
  notes: string
  referral: string
  resume: Media | null
  resumeLink: string
  source: string
  evaluationState: string
  state: string
  expectedCompUSD: number
  invalid_firstName: boolean
  invalid_lastName: boolean
  invalid_email: boolean
}

export default function EditCandidateDialog({
  open,
  onClose,
  candidate,
  onSuccess,
}: EditCandidateDialogProps) {
  const initialFormValues: InitialFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    notes: '',
    referral: '',
    resume: null,
    resumeLink: '',
    source: '',
    evaluationState: '',
    state: '',
    expectedCompUSD: 0,
    invalid_firstName: false,
    invalid_lastName: false,
    invalid_email: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)

  React.useEffect(() => {
    if (open && candidate) {
      setFormValues({
        ...formValues,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        notes: candidate.notes || '',
        referral: candidate.referral || '',
        resumeLink: candidate.resumeLink || '',
        source: candidate.source || '',
        evaluationState: candidate.evaluationState || '',
        state: candidate.state || '',
        expectedCompUSD: candidate.expectedCompUSD || 0,
        resume: candidate.resume as Media,
      })
    }
  }, [candidate])

  const handleChangeFormField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value, [`invalid_${name}`]: false })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(initialFormValues)
    onClose()
  }

  const handleChangeUploadMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData, (progress: any) => {
        setUploadProgress(progress)
      })
      setFormValues({
        ...formValues,
        resume: data,
      })
      setIsMediaUploading(false)

      setUploadProgress(0)
    } catch (error: any) {
      setIsMediaUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickSave = async () => {
    if (
      formValues.firstName.length === 0 ||
      formValues.lastName.length === 0 ||
      formValues.email.length === 0 ||
      !isEmail(formValues.email)
    ) {
      setFormValues({
        ...formValues,
        invalid_firstName: formValues.firstName.length === 0,
        invalid_lastName: formValues.lastName.length === 0,
        invalid_email:
          formValues.email.length === 0 || !isEmail(formValues.email),
      })
      return
    }

    const payload = {
      ...candidate,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      notes: formValues.notes,
      referral: formValues.referral,
      resumeLink: formValues.resumeLink,
      source: formValues.source,
      evaluationState: formValues.evaluationState,
      state: formValues.state,
        expectedCompUSD: formValues.expectedCompUSD,
      resume: formValues.resume,
    }

    try {
      setIsLoading(true)
      const { data } = await updateCandidate(payload)
      onSuccess(data)
      setIsLoading(false)
      addAlert({
        message: 'Candidate updated successfully',
        type: SnackbarType.Success,
      })
      handleClose()
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Candidate</DialogTitle>
      <DialogContent dividers>
        <StyledTextField
          value={formValues.firstName}
          onChange={handleChangeFormField}
          label="First Name"
          name="firstName"
          error={formValues.invalid_firstName}
          helperText={formValues.invalid_firstName ? 'Required' : ''}
        />
        <StyledTextField
          value={formValues.lastName}
          onChange={handleChangeFormField}
          label="Last Name"
          name="lastName"
          error={formValues.invalid_lastName}
          helperText={formValues.invalid_lastName ? 'Required' : ''}
        />
        <StyledTextField
          value={formValues.email}
          onChange={handleChangeFormField}
          label="Email"
          name="email"
          error={formValues.invalid_email}
          helperText={
            formValues.invalid_email
              ? formValues.email.length === 0
                ? 'Required'
                : 'Invalid email'
              : ''
          }
        />
        <StyledTextField
          value={formValues.notes}
          onChange={handleChangeFormField}
          label="Notes"
          name="notes"
          multiline
          rows={15}
        />
        <StyledTextField
          value={formValues.referral}
          onChange={handleChangeFormField}
          label="Referral"
          name="referral"
        />
        <StyledTextField
          value={formValues.source}
          onChange={handleChangeFormField}
          label="Source"
          name="source"
        />

        <StyledFormControl>
          <InputLabel id="state-select-label">Evaluation State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={formValues.evaluationState}
            label="Evaluation State"
            name="evaluationState"
            onChange={handleChangeSelect}
          >
            {Object.entries(EvaluationState).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <InfoItem label={"Warning"} value={"This will change the candidate's state forcefully without actually transitioning them through the process. If you need to transition them through a process use the update state button on the top right"} />
        <StyledFormControl>
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={formValues.state}
            label="State"
            name="state"
            onChange={handleChangeSelect}
          >
            {Object.entries(CandidateState).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <SectionTitle>Resume</SectionTitle>
        {formValues.resume && (
          <Typography sx={{ marginBottom: '10px' }}>
            {(formValues.resume as Media).name}
          </Typography>
        )}
        <MediaButtonBox>
          <LoadingButton
            variant="contained"
            component="label"
            sx={{ boxShadow: 'none' }}
            loading={isMediaUploading}
          >
            {formValues.resume ? 'Change Resume' : 'Add Resume'}
            <input
              type="file"
              hidden
              value={''}
              onChange={handleChangeUploadMedia}
              disabled={isMediaUploading}
              accept="application/pdf"
            />
          </LoadingButton>
          {isMediaUploading && (
            <UploadProgressIndicatorBox>
              <CircularProgressWithLabel value={uploadProgress} />
              <Typography variant="body2">
                {isMediaUploading && uploadProgress < 100
                  ? 'Uploading...'
                  : 'Processing...'}
              </Typography>
            </UploadProgressIndicatorBox>
          )}
        </MediaButtonBox>
        <StyledTextField
          value={formValues.resumeLink}
          onChange={handleChangeFormField}
          label="Resume Link"
          name="resumeLink"
        />
        <TextField type={"number"}
          value={formValues.expectedCompUSD}
          onChange={handleChangeFormField}
          label="Expected Compensation (USD)"
          name="expectedCompUSD"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import { Avatar, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

type AvatarUploadProps = {
  src?: string
  onChangeMedia: (event: any) => void
  isUploading: boolean
  onClearPhoto: () => void
  forLogo?: boolean
}

export default function AvatarUpload({
  src,
  onChangeMedia,
  isUploading,
  onClearPhoto,
  forLogo,
}: AvatarUploadProps) {
  const label = forLogo ? 'Logo' : 'Photo'
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Avatar sx={{ width: 100, height: 100 }} src={src}>
        {forLogo && <CorporateFareIcon sx={{ fontSize: 50 }} />}
      </Avatar>
      <LoadingButton
        sx={{ marginTop: 1 }}
        component={src ? 'button' : 'label'}
        loading={isUploading}
        onClick={src ? onClearPhoto : () => {}}
      >
        {src ? `Remove ${label}` : `Upload ${label}`}
        <input
          type="file"
          hidden
          value={''}
          onChange={onChangeMedia}
          disabled={isUploading}
          accept="image/*"
        />
      </LoadingButton>
    </Box>
  )
}

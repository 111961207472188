import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { PayoutEntry } from './types'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { deletePayoutEntry } from '@assembly/api/resources'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import React from 'react'

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
}))

type DeletePayoutEntryProps = {
  open: boolean
  onClose: () => void
  payoutEntry: PayoutEntry | null
  onSuccess: (payEntry: PayoutEntry) => void
}

export default function DeletePaymentEntryDialog({
  open,
  onClose,
  payoutEntry,
  onSuccess,
}: DeletePayoutEntryProps) {
  const { addAlert } = useSnackbars()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleClickDelete = async () => {
    try {
      setLoading(true)
      const { data } = await deletePayoutEntry(payoutEntry?.id as string)
      setLoading(false)
      onSuccess(payoutEntry as PayoutEntry)
      addAlert({
        message:
          data.message ||
          'Payout Entry deleted successfully for billing : ' +
            payoutEntry?.billingStart +
            ' - ' +
            payoutEntry?.billingEnd,
        type: SnackbarType.Success,
      })
      onClose()
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <StyledText>
          Are you sure, you want to delete the payout entry ?<br />
          <b>
            Billing Period : {payoutEntry?.billingStart} -{' '}
            {payoutEntry?.billingEnd}
          </b>
          <br />
          <b>Assembly Name : {payoutEntry?.assembly?.name}</b>
        </StyledText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

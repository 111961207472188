import { Media } from '@assembly/shared/types/media'

export type Candidate = {
  id: string
  firstName: string
  lastName: string
  email: string
  notes: string | null
  resume: Media | null
  resumeLink: string
  source: string
  referral: string | null
  evaluationState: string
  state: string
  lastUpdateToCandidate: string
  applicationMetaData: ApplicationMetaData[]
  expectedCompUSD: number
  assessmentResponses: { [key: string]: any }
  profileReviewScoreDetails: string
}

type ApplicationMetaData = {
  applyDate: number
  externalJobBoardCandidateLink: string
  externalJobBoardId: string
  getExternalJobBoardApplicationId: string
  jobTitle: string
  questionnaire: { [key: string]: [value: string] }[]
  videoInterviewLink: string
}

export enum EvaluationState {
  OPEN = 'OPEN',
  PROFILE_COMPLETE = 'PROFILE_COMPLETE',
  EQUIPMENT_EVAL_COMPLETE = 'EQUIPMENT_EVAL_COMPLETE',
  ASSESSMENT_COMPLETE = 'ASSESSMENT_COMPLETE',
  SURVEY_COMPLETE = 'SURVEY_COMPLETE',
  SCORING_COMPLETE = 'SCORING_COMPLETE',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_COMPLETE = 'INTERVIEW_COMPLETE',
  CONTRACTED = 'CONTRACTED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
}

export enum CandidateState {
  IMPORTED = 'IMPORTED',
  APPLIED = 'APPLIED',
  WAITLISTED = 'WAITLISTED',
  ELIGIBLE_FOR_EVALUATION = 'ELIGIBLE_FOR_EVALUATION',
  EVALUATION_INVITE_SENT = 'EVALUATION_INVITE_SENT',
  EVALUATION_STARTED = 'EVALUATION_STARTED',
  EVALUATION_COMPLETE = 'EVALUATION_COMPLETE',
  INTERVIEW_SCHEDULED = 'INTERVIEW_SCHEDULED',
  INTERVIEW_COMPLETE = 'INTERVIEW_COMPLETE',
  CONTRACTED = 'CONTRACTED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
}

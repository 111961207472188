import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Avatar, Chip, CircularProgress } from '@mui/material'
import { getArrayDifferenceById, stringAvatar } from '@assembly/utils'
import { RoleLabelMapping, TeamMember } from '@assembly/pages/Teams/types'
import { getOrganizationMembers } from '@assembly/api/organization'

type OrgMembersSearchAndSelectProps = {
  orgId: string
  onChange: (members: TeamMember[]) => void
  value: TeamMember[]
  error?: boolean
  helperText?: string
}

export default function OrgMembersSearchAndSelect({
  onChange,
  value,
  error,
  helperText,
  orgId,
}: OrgMembersSearchAndSelectProps) {
  const [options, setOptions] = React.useState<TeamMember[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (orgId) {
      getOrgMembersAsync()
    }
  }, [orgId])

  const getOrgMembersAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getOrganizationMembers(orgId)
      setOptions([...data])
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      multiple
      loading={loading}
      getOptionLabel={(option) => option.name}
      options={getArrayDifferenceById(options, value)}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No members"
      onChange={(_: any, newValue: any) => {
        onChange(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add Members"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option: TeamMember) => {
        return (
          <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <Avatar
                  sx={{
                    width: 35,
                    height: 35,
                    bgcolor: stringAvatar(option.name || option.email).sx
                      .bgcolor,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {option.name || option.email}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {RoleLabelMapping[option.role]}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      renderTags={(value: any, getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            avatar={<Avatar />}
            color="primary"
            label={option.name || option.email}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}

import React from 'react'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import CreateResourceDialog from '@assembly/pages/Resources/CreateResourceDialog'
import { Resource, Status } from '@assembly/pages/Resources/types'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import ResourcesList from '@assembly/pages/Resources/ResourceList'
import { deleteResource, getAllResources } from '@assembly/api/resources'
import { SnackbarType } from '@assembly/contexts'
import { capitalize, debounce } from 'lodash'
import { styled } from '@mui/material/styles'

const FiltersBox = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export default function Resources() {
  const [createResourceDialogVisible, setCreateResourceDialogVisible] =
    React.useState<boolean>(false)
  const [selectedResource, setSelectedResource] = React.useState<{
    resource?: Resource
    index: number
  }>({
    index: -1,
  })
  const { store, setResources } = useAppStore()
  const didMount = React.useRef(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const { addAlert } = useSnackbars()
  const [status, setSatatus] = React.useState<string>('')
  const [isSeachLoading, setIsSearchLoading] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isResoucesLoaded) {
        getResourcesAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const getResourcesAsync = async () => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('search', '')
      const { data } = await getAllResources(params)
      setResources(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const searchResources = async (search: string, status: string) => {
    try {
      setIsSearchLoading(true)
      const params = new URLSearchParams()
      params.append('search', search)
      if (status) {
        params.append('status', status)
      }

      const { data } = await getAllResources(params)
      setResources(data)
      setIsSearchLoading(false)
    } catch (error) {
      console.error(error)
      setIsSearchLoading(false)
      addAlert({
        message: 'Error',
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeSearch = debounce((value) => {
    searchResources(value, status)
    setSearchValue(value)
  }, 1000)

  const handleClickCreateButton = () => {
    setCreateResourceDialogVisible(true)
  }

  const onEditResource = (value: Resource): void => {
    store.resources[selectedResource.index] = value
    setResources([...store.resources])
  }

  const onCreateResource = (value: Resource): void => {
    store.resources.unshift(value)
    setResources([...store.resources])
  }

  const handleClickDeleteResource = (
    resource: Resource,
    index: number
  ): void => {
    setSelectedResource({
      index: index,
      resource,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const handleClickEditResource = (resource: Resource, index: number): void => {
    setSelectedResource({
      index: index,
      resource,
    })
    setCreateResourceDialogVisible(true)
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedResource({
      resource: undefined,
      index: -1,
    })
  }

  const removeResourceFromList = () => {
    if (selectedResource.index === -1) {
      return
    }

    store.resources.splice(selectedResource.index, 1)
    setResources([...store.resources])
  }

  const deleteOrganizationAsync = async () => {
    if (!selectedResource.resource) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteResource(selectedResource.resource.id)
      setIsDeleting(false)
      setSelectedResource({ resource: undefined, index: -1 })
      setDeleteInputValue('')
      removeResourceFromList()
      addAlert({ message: 'Resource Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { value } = event.target
    setSatatus(value)
    searchResources(searchValue, value)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        buttonLabel="Create Resource"
        onChangeSearch={handleChangeSearch}
        onClickButton={handleClickCreateButton}
        inputPlaceholder="Search Resources"
        isSearching={isSeachLoading}
      />
      <FiltersBox>
        <FormControl fullWidth size="small">
          <InputLabel id="status-select-label">Search By Status</InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            label={'Search by status'}
            name="status"
            onChange={handleChangeSelect}
            placeholder="Search by status"
          >
            {Object.values(Status).map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FiltersBox>
      <ResourcesList
        resources={store.resources}
        onClickDelete={handleClickDeleteResource}
        onClickEdit={handleClickEditResource}
      />
      <CreateResourceDialog
        open={createResourceDialogVisible}
        onClose={() => {
          setCreateResourceDialogVisible(false)
          setSelectedResource({ resource: undefined, index: -1 })
        }}
        onSuccess={
          selectedResource.resource ? onEditResource : onCreateResource
        }
        resource={selectedResource.resource}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedResource.resource?.firstName || ''}
        onClickDelete={deleteOrganizationAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

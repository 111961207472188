import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, colors } from '@mui/material'
import { stringAvatar } from '@assembly/utils'
import { Skill } from './types'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import PsychologyIcon from '@mui/icons-material/Psychology'

interface SkillsListProps {
  skills: Skill[]
  onClickDelete: (skill: Skill, index: number) => void
  onClickEdit: (skill: Skill, index: number) => void
}

export default function SkillsList(props: SkillsListProps) {
  const height = window.innerHeight - 162

  return (
    <FixedSizeList
      className="no-scrollbars"
      height={height}
      width={'100%'}
      itemSize={73}
      itemCount={props.skills.length}
      overscanCount={5}
      itemData={{
        skills: props.skills,
        onClickDelete: props.onClickDelete,
        onClickEdit: props.onClickEdit,
      }}
    >
      {renderRow}
    </FixedSizeList>
  )
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const { skills, onClickEdit, onClickDelete } = data

  const skill = skills[index]

  return (
    <div key={index} style={style}>
      <ListItem
        sx={{ padding: 0 }}
        secondaryAction={
          <Box display="flex" gap={3}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => onClickEdit(skill, index)}
                edge="end"
                aria-label="delete"
                sx={{ color: colors.grey[800] }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => onClickDelete(skill, index)}
                edge="end"
                aria-label="delete"
                sx={{ color: colors.grey[800] }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      >
        <ListItemButton
          onClick={() => {}}
          sx={{ cursor: 'default' }}
          disableRipple
        >
          <ListItemAvatar>
            <Avatar {...stringAvatar(skill.name)}>
              <PsychologyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={skill.name || ''}
            secondary={skill.description || ''}
            secondaryTypographyProps={{
              marginRight: '100px',
              noWrap: true,
            }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </div>
  )
}

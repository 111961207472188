import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent, Typography,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { Job, JobStatus } from './types'
import SkillAssessmentSearchAndSelect from '@assembly/components/SkillAssessmentSearchAndSelect'
import { SkillAssessment } from '../SkillsAssessment/types'
import { createJob, updateJob } from '@assembly/api/jobs'

const StyledTextField = styled(TextField)({
  margin: 0,
})

const FormFieldBox = styled(Box)({
  marginTop: '20px',
})

interface CreateJobDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateJobDialogTitle(props: CreateJobDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateJobDialogProps {
  open: boolean
  onClose: () => void
  job?: Job
  onSuccess: (job: Job) => void
}

type FormValuesType = {
  title: string
  description: string
  status: string
  invalid_title: boolean
  skillAssessments: SkillAssessment[]
  invalid_skillAssessments: boolean
  atsLink: string
  atsID: string
  videoInterviewLink: string
}

export default function CreateJobDialog(props: CreateJobDialogProps) {
  const { open, onClose, job, onSuccess } = props
  const formInitialValues: FormValuesType = {
    title: '',
    description: '',
    status: JobStatus.DRAFT,
    invalid_title: false,
    skillAssessments: [],
    invalid_skillAssessments: false,
    atsLink: '',
    atsID: '',
    videoInterviewLink: '',
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open) {
      if (job) {
        setFormValues({
          ...formValues,
          ...job,
        })
      }
    }
  }, [job, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.title.length === 0 ||
      formValues.skillAssessments.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_title: formValues.title.length === 0,
        invalid_skillAssessments: formValues.skillAssessments.length === 0,
      })
      console.log(formValues)
      console.log("something is invalid")
      return
    }
    setIsSaving(true)
    const payload = {
      title: formValues.title,
      description: formValues.description,
      status: formValues.status,
      atsLink: formValues.atsLink,
      atsID: formValues.atsID,
      videoInterviewLink: formValues.videoInterviewLink,
      skillAssessmentIDs: formValues.skillAssessments.map((s) => s.id),
    }
    if (job) {
      try {
        const { data } = await updateJob({
          id: job.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Job Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        addAlert({
          message: getApiErrorMsg(error),
          type: SnackbarType.Error,
        })
      }
    } else {
      try {
        const { data } = await createJob(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Job Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        addAlert({
          message: getApiErrorMsg(error),
          type: SnackbarType.Error,
        })
      }
    }
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  const handleChangeSkillAssessments = (
    skillAssessments: SkillAssessment[]
  ) => {
    setFormValues({
      ...formValues,
      skillAssessments,
      invalid_skillAssessments: false,
    })
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateJobDialogTitle onClose={handleClose}>
          {job ? 'Edit' : 'Create'} Job
        </CreateJobDialogTitle>
        <DialogContent>
          <FormFieldBox>
            <StyledTextField
              value={formValues.title}
              margin="dense"
              label="Title"
              type="text"
              name="title"
              fullWidth
              onChange={handleChangeFormField}
              error={formValues.invalid_title}
              helperText={formValues.invalid_title ? 'Required' : ''}
            />
          </FormFieldBox>
          <FormFieldBox>
            <Typography
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: formValues.description}}
            />
          </FormFieldBox>
          <FormFieldBox>
            <SkillAssessmentSearchAndSelect
              onChange={handleChangeSkillAssessments}
              value={formValues.skillAssessments}
              error={formValues.invalid_skillAssessments}
              helperText={formValues.invalid_skillAssessments ? 'Required' : ''}
            />
          </FormFieldBox>
          <FormFieldBox>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                value={formValues.status}
                label="Status"
                name="status"
                onChange={handleChangeSelect}
              >
                {Object.values(JobStatus).map((value) => (
                  <MenuItem key={value} value={value}>
                    {capitalize(value.replace('_', ' '))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.atsLink}
              margin="dense"
              label="ATS Link"
              type="text"
              name="atsLink"
              fullWidth
              onChange={handleChangeFormField}
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.atsID}
              margin="dense"
              label="ATS ID"
              type="text"
              name="atsID"
              fullWidth
              onChange={handleChangeFormField}
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.videoInterviewLink}
              margin="dense"
              label="Video Interview Link"
              type="text"
              name="videoInterviewLink"
              fullWidth
              onChange={handleChangeFormField}
            />
          </FormFieldBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import { deleteJob, getJobs, searchJobs } from '@assembly/api/jobs'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import { SnackbarType } from '@assembly/contexts'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material'
import React from 'react'
import JobsList from './JobsList'
import CreateJobDialog from './JobCreateDialog'
import { Job, JobStatus } from './types'
import { capitalize, debounce } from 'lodash'

const FiltersBox = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export default function Jobs() {
  const { addAlert } = useSnackbars()
  const { store, setJobs } = useAppStore()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [createJobDialogOpen, setCreateJobDialogOpen] =
    React.useState<boolean>(false)
  const [selectedJob, setSelectedJob] = React.useState<{
    job: Job | undefined
    index: number
  }>({
    job: undefined,
    index: -1,
  })
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const [searchResults, setSearchResults] = React.useState<Job[]>()
  const [status, setSatatus] = React.useState<string>('')
  const [isSeachLoading, setIsSearchLoading] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>('')

  React.useEffect(() => {
    getJobsAsync()
  }, [])

  const handleChangeSearch = debounce((value) => {
    setSearchValue(value)
    searchJobsAsync(value, status)
  }, 1000)

  const handleCreate = () => {
    setCreateJobDialogOpen(true)
  }

  const getJobsAsync = async () => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('search', '')
      params.append('status', '')
      const { data } = await searchJobs(params)
      setJobs(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  const handleOnCreateSuccess = (job: Job) => {
    setJobs([...store.jobs, job])
  }

  const handleOnEditSuccess = (job: Job) => {
    const newJobs = [...store.jobs]
    newJobs[selectedJob.index] = job
    setJobs(newJobs)
  }

  const handleClickEdit = (job: Job, index: number) => {
    setSelectedJob({ job, index })
    setCreateJobDialogOpen(true)
  }

  const handleCloseConfirmDeleteDialog = () => {
    setConfirmDeleteDialogVisible(false)
    setSelectedJob({ job: undefined, index: -1 })
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const removeFromList = () => {
    if (selectedJob.index === -1) {
      return
    }

    const newJobs = [...store.jobs]
    newJobs.splice(selectedJob.index, 1)
    setJobs(newJobs)
  }

  const deleteAsync = async () => {
    if (!selectedJob.job) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteJob(selectedJob.job.id)
      setIsDeleting(false)
      removeFromList()
      setSelectedJob({ job: undefined, index: -1 })
      setDeleteInputValue('')
      addAlert({
        message: 'Job Deleted!',
        type: SnackbarType.Success,
      })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleClickDelete = (job: Job, index: number) => {
    setSelectedJob({ job, index })
    setConfirmDeleteDialogVisible(true)
  }

  const handeClose = () => {
    setCreateJobDialogOpen(false)
    setSelectedJob({ job: undefined, index: -1 })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { value } = event.target
    setSatatus(value)
    searchJobsAsync(searchValue, value)
  }

  const searchJobsAsync = async (search: string, status: string) => {
    try {
      setIsSearchLoading(true)
      const params = new URLSearchParams()
      params.append('search', search || '')
      if (status) {
        params.append('status', status || '')
      }

      const { data } = await searchJobs(params)
      setJobs(data)
      setIsSearchLoading(false)
    } catch (error) {
      setIsSearchLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  return (
    <Box>
      <SearchInputAndButton
        inputPlaceholder="Search Jobs"
        onChangeSearch={handleChangeSearch}
        buttonLabel="Create Job"
        onClickButton={handleCreate}
      />
      <FiltersBox>
        <FormControl fullWidth size="small">
          <InputLabel id="status-select-label">Search By Status</InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            label={'Search by status'}
            name="status"
            onChange={handleChangeSelect}
            placeholder="Search by status"
          >
            {Object.values(JobStatus).map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FiltersBox>
      {isSeachLoading ? (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <CircularProgress />
        </Box>
      ) : (
        <JobsList
          jobs={searchResults || store.jobs}
          onClickDelete={handleClickDelete}
          onClickEdit={handleClickEdit}
        />
      )}
      <CreateJobDialog
        open={createJobDialogOpen}
        onClose={handeClose}
        job={selectedJob.job}
        onSuccess={
          selectedJob.job ? handleOnEditSuccess : handleOnCreateSuccess
        }
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedJob.job?.title || ''}
        onClickDelete={deleteAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import { Avatar, CircularProgress, InputAdornment } from '@mui/material'
import { Organization } from '@assembly/pages/OrganizationManager/types'
import { searchOrganizations } from '@assembly/api/organization'
import { getMediaUrl, stringAvatar } from '@assembly/utils'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

type OrganizationSearchAndSelectProps = {
  onChange: (organization: Organization) => void
  value: Organization | null
  error?: boolean
  helperText?: string
  small?: boolean
  hideLabel?: boolean
}

export default function OrganizationSearchAndSelect({
  onChange,
  value,
  error,
  helperText,
  small,
  hideLabel,
}: OrganizationSearchAndSelectProps) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<Organization[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetch = React.useMemo(
    () =>
      debounce(
        (request: { input: string }, callback: (results?: any) => void) => {
          if (request.input.length) {
            getOrganizationsAsync(request.input, callback)
          } else {
            callback([])
          }
        },
        400
      ),
    []
  )

  const getOrganizationsAsync = async (search: string, callback: any) => {
    try {
      setLoading(true)
      const { data } = await searchOrganizations(search)
      callback(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetch({ input: inputValue }, (results?: any) => {
      setOptions([...results])
    })
  }, [inputValue, fetch])

  return (
    <Autocomplete
      sx={{ margin: 0 }}
      fullWidth
      size={small ? 'small' : 'medium'}
      loading={loading}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      value={value}
      noOptionsText="No organizations"
      onChange={(_: any, newValue: any) => {
        onChange(newValue)
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!hideLabel ? 'Select organization' : ''}
          placeholder="Type organization name"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ marginLeft: '5px' }}>
                {value ? (
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      ...stringAvatar(value.name).sx,
                    }}
                    src={getMediaUrl(value.settings.logo?.media || '')}
                  >
                    <CorporateFareIcon sx={{ width: 20, height: 20 }} />
                  </Avatar>
                ) : (
                  <CorporateFareIcon />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <Avatar
                  src={getMediaUrl(option.settings.logo?.media || '')}
                  sx={{
                    width: 35,
                    height: 35,
                    ...stringAvatar(option.name).sx,
                  }}
                >
                  <CorporateFareIcon />
                </Avatar>
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {option.name}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.mainContact}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

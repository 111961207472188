import React, { useEffect } from 'react'
import { TextField, Chip } from '@mui/material'
import Downshift from 'downshift'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: any) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25, 0.5, 0.25),
  },
}))

export default function TagsInput({ ...props }) {
  const classes = useStyles()
  const { selectedTags, placeholder, tags, defaultTags, ...other } = props
  const [inputValue, setInputValue] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState<string[]>([])
  const didMount = React.useRef(false)

  useEffect(() => {
    if (defaultTags) {
      setSelectedItem(defaultTags)
    }
  }, [defaultTags])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    } else {
      if (tags.length !== selectedItem.length) {
        selectedTags(selectedItem)
      }
    }
  }, [selectedItem])

  function handleKeyDown(event: any) {
    if (event.key === 'Enter') {
      const newSelectedItem: any = [...selectedItem]
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      )

      if (duplicatedValues !== -1) {
        setInputValue('')
        return
      }
      if (!event.target.value.replace(/\s/g, '').length) return

      newSelectedItem.push(event.target.value.trim())
      setSelectedItem(newSelectedItem)
      setInputValue('')
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
    }
  }
  function handleChange(item: any) {
    let newSelectedItem: any = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue('')
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = (item: any) => () => {
    const newSelectedItem: any = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  function handleInputChange(event: any) {
    setInputValue(event.target.value)
  }
  return (
    <React.Fragment>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}
      >
        {({ getInputProps }: any) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          })
          return (
            <div>
              <TextField
                sx={{ flexWrap: 'wrap' }}
                InputProps={{
                  style: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingTop: selectedItem.length > 0 ? '20px' : 0,
                  },
                  startAdornment: selectedItem.map((item) => (
                    <Chip
                      color="primary"
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={handleDelete(item)}
                    />
                  )),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event)
                    onChange(event)
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />
            </div>
          )
        }}
      </Downshift>
    </React.Fragment>
  )
}

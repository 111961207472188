import { axiosWithConfig } from '@assembly/config/axios'
import { ICreateResource } from '@assembly/pages/Resources/types'

const path = `/admin/contractor`

export const createResource = (payload: ICreateResource) => {
  return axiosWithConfig.post(path, payload)
}

export const updateResource = (payload: ICreateResource) => {
  return axiosWithConfig.put(path, payload)
}

export const deleteResource = (id: string) => {
  return axiosWithConfig.delete(`${path}/${id}`)
}

export const getAllResources = (params: any) => {
  return axiosWithConfig.post(`${path}/search`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const getResource = (id: string) => {
  return axiosWithConfig.get(`${path}/${id}`)
}


export const uploadMedia = (payload: any, onProgressChange?: any) => {
  return axiosWithConfig.put(`/media/contractor/setup`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgressChange) onProgressChange(percentCompleted)
    },
  })
}

export const getPayoutEntries = (params: any) => {
  return axiosWithConfig.post(`${path}/payout/daily/payout-entries`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const getPayoutInvoices = (params: any) => {
  return axiosWithConfig.post(`${path}/payout/payout-invoices`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const updatePayment = (id: string, payload: any) => {
  return axiosWithConfig.post(`${path}/payout/${id}/update`, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const sendPayment = (id: string) => {
  return axiosWithConfig.post(`${path}/payout/${id}/finalize`)
}

export const deletePayoutEntry = (id: string) => {
  return axiosWithConfig.delete(`${path}/payout/daily/payout-entries/${id}`)
}

export const talentInvoices = (params: any) => {
  return axiosWithConfig.post(`admin/payout/payout-invoices`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const createPayoutInvoice = (payload: any) => {
  return axiosWithConfig.put(`admin/contractor/payout`, payload)
}

export const reprocessPayoutInvoice = (payload: any) => {
  return axiosWithConfig.post(`admin/contractor/reprocess-payout`, payload)
}

export const reprocessPayoutEntries = (payload: any) => {
  return axiosWithConfig.post(`admin/contractor/reprocess-payout-entries`, payload)
}

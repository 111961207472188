import { getAssemblyTemplate } from '@assembly/api/assemblyTemplate'
import { useAppStore } from '@assembly/hooks'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  colors,
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { AssemblyTemplate as IAssemblyTemplate } from '../AssemblyTemplates/types'
import { InfoItem } from '@assembly/components'
import { getMediaUrl, stringAvatar } from '@assembly/utils'
import { capitalize } from 'lodash'
import { styled } from '@mui/material/styles'
import CreateAssemblyTemplateDialog from '../AssemblyTemplates/CreateAssemblyTemplateDialog'

const EditBtnBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
})

const EditBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  marginBottom: '10px',
})

const AssemblyTemplate: React.FC = () => {
  const { setAppBarTitle } = useAppStore()
  const didMount = React.useRef(false)
  const params = useParams()
  const [data, setData] = React.useState<IAssemblyTemplate>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const { store, setAssemblyTemplates } = useAppStore()

  React.useEffect(() => {
    if (!didMount.current) {
      getDataAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getDataAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAssemblyTemplate(params.id || '')
      setAppBarTitle(data.name)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleClickEdit = () => {
    setEditDialogOpen(true)
  }

  const onEditSuccess = (value: IAssemblyTemplate): void => {
    const index = store.assemblyTemplates.findIndex(
      (assemblyTemplate) => assemblyTemplate.id === value.id
    )
    if (index !== -1) {
      store.assemblyTemplates[index] = value
      setAssemblyTemplates([...store.assemblyTemplates])
      setData({ ...value })
    }
  }

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <EditBtnBox>
        <EditBtn variant="contained" onClick={handleClickEdit}>
          Edit
        </EditBtn>
      </EditBtnBox>
      <InfoItem label="Name" value={data?.name || ''} />
      <InfoItem label="Description" value={data?.description || ''} />
      <InfoItem
        label="Approximate Monthly Budget"
        value={'$' + data?.approximateMonthlyBudget || ''}
      />
      <InfoItem
        label="Capabilities"
        value={
          data?.capabilities?.map((capability) => capability.name).join(', ') ||
          ''
        }
      />
      <Typography variant="h5" fontWeight={500}>
        Allocations
      </Typography>
      {data?.allocations.map((allocation, index) => (
        <Paper
          key={index}
          sx={{
            marginBottom: 2,
            padding: 2,
            marginTop: 2,
            boxShadow: 'none',
            border: '1px solid',
            borderColor: colors.grey[200],
          }}
        >
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Avatar
              {...stringAvatar(allocation.resource?.firstName || '')}
              src={getMediaUrl(
                allocation.resource?.profilePicture?.media || ''
              )}
            />
            <Box>
              <Typography marginLeft={2} fontSize={17}>
                {allocation.resource?.firstName +
                  ' ' +
                  allocation.resource?.lastName}
              </Typography>
              <Typography variant="body2" marginLeft={2}>
                {allocation.resource?.label}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={2} marginLeft={7}>
            <Typography>
              <b>Type: </b>
              {capitalize(allocation.type)}
            </Typography>
            <Typography>
              <b>Amount: </b>
              {allocation.amount}
            </Typography>
            <Typography>
              <b>Rate: </b>
              {allocation.rate}
            </Typography>
            <Typography>
              <b>Rate Unit: </b>
              {allocation.rateUnit}
            </Typography>
            <Typography>
              <b>Frequency: </b>
              {capitalize(allocation.frequency)}
            </Typography>
            <Typography>
              <b>Status: </b>
              {capitalize(allocation.status)}
            </Typography>
            <Typography>
              <b>Status Message: </b>
              {capitalize(allocation.statusMessage || '---')}
            </Typography>
            <Typography>
              <b>Approved: </b>
              {allocation.approved ? 'Yes' : 'No'}
            </Typography>
            <Typography>
              <b>Approved By: </b>
              {allocation.approvedBy || '---'}
            </Typography>
            <Typography>
              <b>Active: </b>
              {allocation.active ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Paper>
      ))}
      <CreateAssemblyTemplateDialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        assemblyTemplate={data}
        onSuccess={onEditSuccess}
      />
    </Box>
  )
}

export default AssemblyTemplate

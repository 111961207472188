import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { ICreateTeam, Team } from './types'
import { createTeam, updateTeam } from '@assembly/api/teams'
import validator from 'validator'

interface CreateTeamDialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function CreateTeamDialogTitle(props: CreateTeamDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateTeamDialogProps {
  open: boolean
  onClose: () => void
  organizationID: string
  onSuccess: (team: Team) => void
  team?: Team
}

export default function CreateTeamDialog(props: CreateTeamDialogProps) {
  const { open, onClose, organizationID, onSuccess, team } = props
  const formInitialValues = {
    name: '',
    mainContact: '',
    invalid_mainContact: false,
    invalid_name: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open) {
      if (team) {
        setFormValues({
          ...formValues,
          ...team,
        })
      }
    }
  }, [team, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.name.length === 0 ||
      formValues.mainContact.length === 0 ||
      !validator.isEmail(formValues.mainContact)
    ) {
      setFormValues({
        ...formValues,
        invalid_name: formValues.name.length === 0,
        invalid_mainContact:
          formValues.mainContact.length === 0 ||
          !validator.isEmail(formValues.mainContact),
      })
      return
    }

    setIsSaving(true)

    const payload: ICreateTeam = {
      name: formValues.name,
      mainContact: formValues.mainContact,
      organizationID,
    }

    if (team) {
      try {
        const { data } = await updateTeam({
          id: team.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Team Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createTeam(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Team Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateTeamDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Team
        </CreateTeamDialogTitle>
        <DialogContent dividers>
          <TextField
            value={formValues.name}
            margin="dense"
            label="Name"
            type="text"
            name="name"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_name}
            helperText={formValues.invalid_name ? 'Required' : ''}
          />
          <TextField
            value={formValues.mainContact}
            margin="dense"
            name="mainContact"
            label="Main Contact Email"
            type="email"
            fullWidth
            error={formValues.invalid_mainContact}
            onChange={handleChangeFormField}
            helperText={
              formValues.invalid_mainContact
                ? formValues.mainContact.length === 0
                  ? 'Required'
                  : 'Invalid email'
                : ''
            }
            sx={{ margin: 0, marginTop: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

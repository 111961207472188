import { axiosWithConfig } from '@assembly/config/axios'

export const createAssemblyLine = (payload: any) => {
  return axiosWithConfig.post(`/admin/assembly_line`, payload)
}

export const updateAssemblyLine = (payload: any) => {
  return axiosWithConfig.put(`/admin/assembly_line`, payload)
}

export const deleteAssemblyLine = (id: string) => {
  return axiosWithConfig.delete(`/admin/assembly/${id}`)
}

export const getAssemblyLine = (id: string) => {
  return axiosWithConfig.get(`/admin/assembly_line/${id}`)
}

export const getAssemblyLines = (payload: any) => {
  return axiosWithConfig.post(`/admin/assembly_line/search`, payload)
}

export const estimateStartDate = (assemblyId: string) => {
  return axiosWithConfig.post(`/admin/assembly_line/${assemblyId}/proposal/start_date`)
}
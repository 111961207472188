import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  SelectChangeEvent,
} from '@mui/material'
import {
  createOrganization,
  updateOrganization,
} from '@assembly/api/organization'
import { useSnackbars } from '@assembly/hooks'
import { ICreateOrganization, Organization } from './types'
import { SnackbarType } from '@assembly/contexts'
import validator from 'validator'
import { AvatarUpload } from '@assembly/components'
import { getMediaUrl } from '@assembly/utils'
import { uploadMedia } from '@assembly/api/media'
import { Media } from '@assembly/shared/types/media'
import { supportedCoutries } from '@assembly/shared/types/countriesAndCurrencies'
import { timezones } from '@assembly/shared/timezones'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginTop: '20px',
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: 0,
  marginTop: '20px',
}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
}))

const defaultCurrency = 'USD'
const defaultTimezone = 'America/Los_Angeles'

interface CreateOrganizationDialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function CreateOrganizationDialogTitle(
  props: CreateOrganizationDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateGroupDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (organization: Organization) => void
  organization?: Organization
}

export default function CreateOrganizationDialog(
  props: CreateGroupDialogProps
) {
  const { open, onClose, organization, onSuccess } = props
  const formInitialValues = {
    name: '',
    mainContact: '',
    mainContactFirstName: '',
    mainContactLastName: '',
    clientSetupLink: '',
    apContact: '',
    timezone: defaultTimezone,
    currency: defaultCurrency,
    logo: undefined,
    website: '',
    invalid_mainContact: false,
    invalid_apContact: false,
    invalid_name: false,
    invalid_timezone: false,
    invalid_currency: false,
    invalid_mainContactFirstName: false,
    invalid_clientSetupLink: false,
    invalid_website: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (open) {
      if (organization) {
        setFormValues({
          ...formValues,
          logo: organization.settings.logo as any,
          timezone: organization.settings.timezone,
          currency: organization.settings.currency,
          ...organization,
          mainContactFirstName: organization.mainContactFirstName || '',
          mainContactLastName: organization.mainContactLastName || '',
          clientSetupLink: organization.clientSetupLink || '',
          website: organization.website || '',
        })
      }
    }
  }, [organization, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.name.trim().length === 0 ||
      formValues.mainContact.trim().length === 0 ||
      formValues.mainContactFirstName.trim().length === 0 ||
      !validator.isEmail(formValues.mainContact.trim()) ||
      (formValues.clientSetupLink !== null &&
        formValues.clientSetupLink.length > 0 &&
        !validator.isURL(formValues.clientSetupLink.trim())) ||
      (formValues.website !== null &&
        formValues.website.length > 0 &&
        !validator.isURL(formValues.website.trim()))
    ) {
      setFormValues({
        ...formValues,
        invalid_name: formValues.name.trim().length === 0,
        invalid_mainContact:
          formValues.mainContact.trim().length === 0 ||
          !validator.isEmail(formValues.mainContact.trim()),
        invalid_mainContactFirstName:
          formValues.mainContactFirstName.trim().length === 0,
        invalid_clientSetupLink:
          formValues.clientSetupLink !== null &&
          formValues.clientSetupLink.length > 0 &&
          !validator.isURL(formValues.clientSetupLink.trim()),
        invalid_website:
          formValues.website !== null &&
          formValues.website.length > 0 &&
          !validator.isURL(formValues.website.trim()),
      })
      return
    }

    setIsSaving(true)

    const payload: ICreateOrganization = {
      name: formValues.name,
      mainContact: formValues.mainContact,
      mainContactFirstName: formValues.mainContactFirstName,
      mainContactLastName: formValues.mainContactLastName,
      clientSetupLink: formValues.clientSetupLink,
      apContact: formValues.apContact,
      website: formValues.website,
      settings: {
        timezone: formValues.timezone,
        currency: formValues.currency,
        logo: formValues.logo,
      },
    }

    if (organization) {
      try {
        const { data } = await updateOrganization({
          id: organization.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Organization Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createOrganization(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Organization Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleClose = () => {
    setTimeout(() => {
      setFormValues(formInitialValues)
    }, 300)
    setIsSaving(false)
    onClose()
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])

    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData)
      setFormValues({
        ...formValues,
        logo: data,
      })
      setIsMediaUploading(false)
    } catch (error: any) {
      console.error(error)
      setIsMediaUploading(false)
      addAlert({
        message: 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }
  const handleRemoveMedia = () => {
    setFormValues({
      ...formValues,
      logo: undefined,
    })
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateOrganizationDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {organization ? 'Edit' : 'Create'} Organization
        </CreateOrganizationDialogTitle>
        <DialogContent>
          <AvatarUpload
            onChangeMedia={handleChangeMedia}
            isUploading={isMediaUploading}
            src={getMediaUrl(
              (formValues.logo && (formValues.logo as Media)?.media) || ''
            )}
            onClearPhoto={handleRemoveMedia}
            forLogo
          />
          <StyledTextField
            value={formValues.name}
            label="Organization Name"
            type="text"
            name="name"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_name}
            helperText={formValues.invalid_name ? 'Required' : ''}
          />
          <StyledTextField
            value={formValues.website}
            label="Website"
            type="text"
            name="website"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_website}
            helperText={formValues.invalid_website ? 'Invalid website URL' : ''}
          />
          <StyledBox>
            <StyledTextField
              value={formValues.mainContactFirstName}
              label="Main Contact First Name"
              type="text"
              name="mainContactFirstName"
              fullWidth
              onChange={handleChangeFormField}
              error={formValues.invalid_mainContactFirstName}
              helperText={
                formValues.invalid_mainContactFirstName ? 'Required' : ''
              }
            />
            <StyledTextField
              value={formValues.mainContactLastName}
              label="Main Contact Last Name"
              type="text"
              name="mainContactLastName"
              fullWidth
              onChange={handleChangeFormField}
            />
          </StyledBox>
          <StyledTextField
            value={formValues.mainContact}
            name="mainContact"
            label="Main Contact Email"
            type="email"
            fullWidth
            error={formValues.invalid_mainContact}
            onChange={handleChangeFormField}
            helperText={
              formValues.invalid_mainContact
                ? formValues.mainContact.length === 0
                  ? 'Required'
                  : 'Invalid email'
                : ''
            }
          />
          <StyledTextField
            value={formValues.apContact}
            label="Ap Contact Email"
            name="apContact"
            type="email"
            fullWidth
            error={formValues.invalid_apContact}
            onChange={handleChangeFormField}
            helperText={
              formValues.invalid_apContact
                ? formValues.apContact.length === 0
                  ? 'Required'
                  : 'Invalid email'
                : ''
            }
          />
          <StyledTextField
            value={formValues.clientSetupLink}
            label="Client Setup Link"
            type="text"
            name="clientSetupLink"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_clientSetupLink}
            helperText={formValues.invalid_clientSetupLink ? 'Invalid URL' : ''}
          />
          <StyledFormControl fullWidth>
            <InputLabel id="type-select-label">Timezone</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={formValues.timezone}
              label="Timezone"
              name="timezone"
              onChange={handleChangeSelect}
            >
              {timezones.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl fullWidth>
            <InputLabel id="type-select-label">Currency</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={formValues.currency}
              label="Currency"
              name="currency"
              onChange={handleChangeSelect}
            >
              {supportedCoutries.map((country) => (
                <MenuItem key={country.countryCode} value={country.currency}>
                  {country.currency}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import { CandidateState } from './types'
import { updateCandidatesState } from '@assembly/api/candidates'

const StyledFormControl = styled(FormControl)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

type EditCandidatesStateDialogProps = {
  open: boolean
  onClose: () => void
  candidateIds: string[]
  onSuccess: (state: string) => void
}

export default function EditCandidatesStateDialog({
  open,
  onClose,
  candidateIds,
  onSuccess,
}: EditCandidatesStateDialogProps) {
  const initialFormValues = {
    state: '',
    invalid_state: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(initialFormValues)
    onClose()
  }

  const handleClickSave = async () => {
    if (formValues.state.length === 0) {
      setFormValues({
        ...formValues,
        invalid_state: true,
      })
      return
    }

    const params = new URLSearchParams()
    params.append('candidates', candidateIds.join(','))

    try {
      setIsLoading(true)
      const { data } = await updateCandidatesState(params, formValues.state)
      setIsLoading(false)
      addAlert({
        message: data.message || 'Request was successful',
        type: SnackbarType.Success,
      })
      onSuccess(formValues.state)
      handleClose()
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Candidates State</DialogTitle>
      <DialogContent dividers>
        <StyledFormControl>
          <InputLabel id="state-select-label">State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={formValues.state}
            label="State"
            name="state"
            onChange={handleChangeSelect}
            error={formValues.invalid_state}
          >
            {Object.entries(CandidateState).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
          {formValues.invalid_state && (
            <FormHelperText error>Required</FormHelperText>
          )}
        </StyledFormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import React from 'react';

interface ProfileReviewScoreDialogProps {
    open: boolean
    onClose: () => void
    value: string | undefined
}

export default function ProfileReviewScoreDialog({
                                                     open,
                                                     onClose,
                                                     value,
                                                 }: ProfileReviewScoreDialogProps) {

    // Function to split \n and return as array of elements
    const formatValue = (text: string) => {
        return text.split('\n').map((item, index) => (
            <React.Fragment key={index}>
                <li>{item}</li>
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Profile Review Score</DialogTitle>
            <DialogContent>
                <Typography color={!value ? 'error' : 'black'}>
                    {value ? formatValue(value) : 'No text available'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

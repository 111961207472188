import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, colors } from '@mui/material'
import { stringAvatar } from '@assembly/utils'
import { SkillAssessment } from './types'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React from 'react'

interface SkillAssessmentListProps {
  skillsAssessment: SkillAssessment[]
  onClickDelete: (skillsAssessments: SkillAssessment, index: number) => void
  onClickEdit: (skillsAssessments: SkillAssessment, index: number) => void
}

export default function SkillAssessmentList(props: SkillAssessmentListProps) {
  const height = window.innerHeight - 162

  return (
    <FixedSizeList
      className="no-scrollbars"
      height={height}
      width={'100%'}
      itemSize={73}
      itemCount={props.skillsAssessment.length}
      overscanCount={5}
      itemData={{
        skillsAssessment: props.skillsAssessment,
        onClickDelete: props.onClickDelete,
        onClickEdit: props.onClickEdit,
      }}
    >
      {RenderRow}
    </FixedSizeList>
  )
}

function RenderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const { skillsAssessment, onClickEdit, onClickDelete } = data
  const [tooltipText, setTooltipText] = React.useState('Copy ID')

  const skillAssessment = skillsAssessment[index]

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    setTooltipText('Copied!')
    setTimeout(() => {
      setTooltipText('Copy ID')
    }, 2000)
  }

  return (
    <div key={index} style={style}>
      <ListItem
        sx={{ padding: 0 }}
        secondaryAction={
          <Box display="flex" gap={3}>
            <Tooltip
              title={tooltipText}
              sx={{ marginLeft: 1, color: colors.grey[800] }}
            >
              <IconButton onClick={() => copyToClipboard(skillAssessment.id)}>
                <ContentCopyIcon sx={{ width: 20, height: 20 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => onClickEdit(skillAssessment, index)}
                edge="end"
                aria-label="delete"
                sx={{ color: colors.grey[800] }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => onClickDelete(skillAssessment, index)}
                edge="end"
                aria-label="delete"
                sx={{ color: colors.grey[800] }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      >
        <ListItemButton
          onClick={() => {}}
          sx={{ cursor: 'default' }}
          disableRipple
        >
          <ListItemAvatar>
            <Avatar {...stringAvatar(skillAssessment.title)}>
              <AssessmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={skillAssessment.title || ''}
            secondary={skillAssessment.description || ''}
            secondaryTypographyProps={{
              marginRight: '100px',
              noWrap: true,
            }}
          />
        </ListItemButton>
      </ListItem>
      <Divider />
    </div>
  )
}

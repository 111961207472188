import { getPayoutEntries } from '@assembly/api/resources'
import { SnackbarType } from '@assembly/contexts'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg, interpolateRouteUtil } from '@assembly/utils'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PayoutEntry } from './types'
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import { RoutePaths } from '@assembly/constants'
import moment from 'moment-timezone'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { PayoutInvoice } from '@assembly/pages/ResourcePayoutInvoices/types'
import SendPaymentDialog from '@assembly/pages/ResourcePayoutInvoices/SendPaymentDialog'
import DeletePaymentEntryDialog from '@assembly/pages/ResourcePayoutEntries/DeletePaymentEntryDialog'

const ColValue = styled(Typography)(() => ({
  fontSize: '15px',
}))

const DIALOG_TRANSITION_DURATION = 300

const StyledLink = styled(Link)(() => ({
  fontSize: '15px',
  cursor: 'pointer',
  color: '#000',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
}))
const DetailCol = styled(Typography)(() => ({
  fontSize: '15px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}))

export default function ResourcePayoutEntries() {
  const { setAppBarTitle } = useAppStore()
  const pathParams = useParams<{ id: string }>()
  const { addAlert } = useSnackbars()
  const didMount = React.useRef<boolean>(false)
  const [data, setData] = React.useState<PayoutEntry[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [selectedPayoutEntry, setSelectedPayoutEntry] =
    React.useState<PayoutEntry | null>(null)
  const [confirmDeletePayoutEntry, setConfirmDeletePayoutEntry] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    if (!didMount.current) {
      getPayoutEntriesAsync()
      didMount.current = true
    }
    setAppBarTitle(`Payout Entries For Resource (${pathParams.id})`)

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getPayoutEntriesAsync = async () => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('contractorID', pathParams.id as string)
      const { data } = await getPayoutEntries(params)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const assemblyCol = (params: GridCellParams) => {
    return (
      <StyledLink
        href={interpolateRouteUtil(RoutePaths.AssemblyLine, {
          id: params.row.assembly.id,
        })}
        target="_blank"
      >
        {params.row.assembly.name}
      </StyledLink>
    )
  }

  const billableAmountCol = (params: GridCellParams) => {
    return <ColValue>$ {params.row.billableAmount}</ColValue>
  }

  const billableUnitsCol = (params: GridCellParams) => {
    return <ColValue>{params.row.billableUnits}</ColValue>
  }

  const statusCol = (params: GridCellParams) => {
    return <ColValue>{capitalize(params.row.status)}</ColValue>
  }

  const dateCol = (params: GridCellParams) => {
    const formattedDate = moment(params.row[params.field], 'YYYYMMDD').format(
      'MM-DD-YYYY'
    )
    return <ColValue>{formattedDate}</ColValue>
  }

  const timestampCol = (params: GridCellParams) => {
    const formatted = moment(params.row.timestamp).format('MM-DD-YYYY')
    return <ColValue>{formatted}</ColValue>
  }

  const StyledButton = styled(LoadingButton)(() => ({
    boxShadow: 'none',
    textTransform: 'none',
  }))

  const handleDeletePayoutEntry = (payoutEntry: PayoutEntry) => {
    setSelectedPayoutEntry(payoutEntry)
    setConfirmDeletePayoutEntry(true)
  }

  const deletePayoutEntryCol = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleDeletePayoutEntry(params.row)}
      >
        Delete Entry
      </StyledButton>
    )
  }

  const statusMessageCol = (params: GridCellParams) => {
    return <DetailCol>{params.row.statusMessage}</DetailCol>
  }

  const resourceIdCol = (params: GridCellParams) => {
    return (
      <StyledLink
        href={interpolateRouteUtil(RoutePaths.Resource, {
          id: params.row.resourceID,
        })}
        target="_blank"
      >
        {params.row.resourceID}
      </StyledLink>
    )
  }

  const dataGridColumns: GridColDef[] = [
    {
      type: 'string',
      field: 'assembly',
      headerName: 'Assembly',
      width: 180,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: assemblyCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.assembly.name
      },
    },
    {
      type: 'string',
      field: 'resourceID',
      headerName: 'Resource',
      width: 180,
      editable: false,
      sortable: false,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: resourceIdCol,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridCellParams) => {
        return params.row.resourceID
      },
    },
    {
      type: 'number',
      field: 'billableAmount',
      headerName: 'Billable Amount',
      width: 123,
      editable: false,
      sortable: false,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableAmountCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.billableAmount
      },
    },
    {
      type: 'number',
      field: 'billableUnits',
      headerName: 'Billable Units',
      width: 110,
      editable: false,
      sortable: false,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableUnitsCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.billableUnits
      },
    },
    {
      type: 'date',
      field: 'billingStart',
      headerName: 'Billing Start Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
      valueGetter: (params: GridCellParams) => {
        return moment(params.row.billingStart, 'YYYYMMDD').toDate()
      },
    },
    {
      type: 'date',
      field: 'billingEnd',
      headerName: 'Billing End Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
      valueGetter: (params: GridCellParams) => {
        return moment(params.row.billingEnd, 'YYYYMMDD').toDate()
      },
    },
    {
      type: 'string',
      field: 'status',
      headerName: 'Status',
      width: 100,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.status
      },
    },
    {
      type: 'string',
      field: 'statusMessage',
      headerName: 'Status Message',
      width: 350,
      editable: false,
      sortable: false,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusMessageCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.statusMessage
      },
    },
    {
      type: 'date',
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 120,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: timestampCol,
      valueGetter: (params: GridCellParams) => {
        return moment(params.row.timestamp).toDate()
      },
    },
    {
      field: 'deletePayoutEntry',
      headerName: 'Delete Payout Entry',
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: deletePayoutEntryCol,
    },
  ]

  const handleCloseConfirmPayoutEntryDeletionDialog = () => {
    setConfirmDeletePayoutEntry(false)
    setTimeout(() => {
      setSelectedPayoutEntry(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleUpdateSuccess = (payoutEntry: PayoutEntry) => {
    const index = data.findIndex((item) => item.id === payoutEntry.id)
    if (index !== -1) {
      const newData = [...data]
      newData.splice(index, 1)
      setData(newData)
    }
  }

  return (
    <Box>
      <DataGrid
        columns={dataGridColumns}
        rows={data}
        loading={isLoading}
        sx={{ height: 'calc(100vh - 120px)', borderWidth: '0px' }}
        // getRowId={() => Math.random().toString()}
        showCellVerticalBorder
        showColumnVerticalBorder
      />
      <DeletePaymentEntryDialog
        open={confirmDeletePayoutEntry}
        onClose={handleCloseConfirmPayoutEntryDeletionDialog}
        payoutEntry={selectedPayoutEntry}
        onSuccess={handleUpdateSuccess}
      />
    </Box>
  )
}

import { axiosWithConfig } from '@assembly/config/axios'

export const createAssemblyTemplate = (payload: any) => {
  return axiosWithConfig.post(`/admin/assembly_line/template`, payload)
}

export const updateAssemblyTemplate = (payload: any) => {
  return axiosWithConfig.put(`/admin/assembly_line/template`, payload)
}

export const deleteAssemblyTemplate = (id: string) => {
  return axiosWithConfig.delete(`/admin/assembly_line/template/${id}`)
}

export const getAllAssemblyTemplates = (payload: any) => {
  return axiosWithConfig.post(
    `/admin/assembly_line/templates/search`,
    payload
  )
}

export const getAssemblyTemplate = (id: string) => {
  return axiosWithConfig.get(`/admin/assembly_line/template/${id}`)
}

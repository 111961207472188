import { axiosWithConfig } from '@assembly/config/axios'
import { ICreateTeamMember } from '@assembly/pages/Teams/types'

export const createTeamMember = (
  payload: ICreateTeamMember,
  teamId: string
) => {
  return axiosWithConfig.post(`/admin/team/${teamId}/team_member`, payload)
}

export const updateTeamMember = (payload: ICreateTeamMember) => {
  return axiosWithConfig.put(`/admin/team_member`, payload)
}

export const getAllTeamMembersOfTeam = (teamId: string) => {
  return axiosWithConfig.get(`/admin/team/${teamId}/team_members`)
}

export const deleteTeamMember = (teamMemberId: string) => {
  return axiosWithConfig.delete(`/admin/team_member/id/${teamMemberId}`)
}

export const getTeamMemberById = (teamMemberId: string) => {
  return axiosWithConfig.get(`/admin/team_member/id/${teamMemberId}`)
}

export const getTeamMembersByOrgAndRole = (orgId: string, role: string) => {
  return axiosWithConfig.get(`/admin/team_member/org/${orgId}/role/${role}`)
}

import {
  ConfirmDeleteDialog,
  OrganizationSearchAndSelect,
  SearchInputAndButton,
} from '@assembly/components'
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import CreateAssemblyLineDialog from './CreateAssemblyLineDialog'
import {
  deleteAssemblyLine,
  getAssemblyLines,
} from '@assembly/api/assemblyLine'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import AssemblyLinesList from './AssemblyLinesList'
import { AssemblyLine, AssemblyLineStatus } from './types'
import { Organization } from '../OrganizationManager/types'
import { styled } from '@mui/material/styles'
import { capitalize, debounce } from 'lodash'

const OrganizationSearchAndSelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 1,
  gap: theme.spacing(1),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const AssemblyLines: React.FC = () => {
  const [createAssemblyLineDialogOpen, setCreateAssemblyLineDialogOpen] =
    React.useState<boolean>(false)
  const [
    confirmDeleteAssemblyLineDialogOpen,
    setConfirmDeleteAssemblyLineDialogOpen,
  ] = React.useState<boolean>(false)
  const [selectedAssemblyLine, setSelectedAssemblyLine] = React.useState<{
    assemblyLine: AssemblyLine | undefined
    index: number
  }>({
    assemblyLine: undefined,
    index: -1,
  })
  const didMount = React.useRef(false)
  const { addAlert } = useSnackbars()
  const { store, setAssemblyLines } = useAppStore()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    Organization | undefined
  >()
  const [isSeachLoading, setIsSearchLoading] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [status, setSatatus] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMount.current) {
      if (!store.isAssemblyLinesLoaded) {
        getAssemblyLinesAsync()
      } else {
        setIsLoading(false)
      }
      didMount.current = true
    }
  }, [])

  const getAssemblyLinesAsync = async () => {
    try {
      setIsLoading(true)
      const payload = { search: '', orgID: selectedOrganization?.id || '' }
      const { data } = await getAssemblyLines(payload)
      setAssemblyLines(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      addAlert({
        message: 'Error',
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const searchAssemblyLines = async (
    search: string,
    orgID: string,
    status: string
  ) => {
    try {
      setIsSearchLoading(true)
      const payload = { search, orgID, status: status || null }

      const { data } = await getAssemblyLines(payload)
      setAssemblyLines(data)
      setIsSearchLoading(false)
    } catch (error) {
      console.error(error)
      setIsSearchLoading(false)
      addAlert({
        message: 'Error',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickDelete = (assemblyLine: AssemblyLine, index: number) => {
    setSelectedAssemblyLine({ assemblyLine, index })
    setConfirmDeleteAssemblyLineDialogOpen(true)
  }

  const handleClickEdit = (assemblyLine: AssemblyLine, index: number) => {
    setSelectedAssemblyLine({ assemblyLine, index })
    setCreateAssemblyLineDialogOpen(true)
  }

  const handleCloseAssemblyLineDialog = () => {
    setSelectedAssemblyLine({ assemblyLine: undefined, index: -1 })
    setCreateAssemblyLineDialogOpen(false)
  }

  const onEdit = (assemblyLine: AssemblyLine) => {
    store.assemblyLines[selectedAssemblyLine.index] = assemblyLine
    setAssemblyLines([...store.assemblyLines])
  }

  const onCreate = (assemblyLine: AssemblyLine) => {
    setAssemblyLines([assemblyLine, ...store.assemblyLines])
    setSelectedAssemblyLine({ assemblyLine, index: 0 })
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteAssemblyLineDialogOpen(false)
    setDeleteInputValue('')
    setSelectedAssemblyLine({ assemblyLine: undefined, index: -1 })
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const removeFromList = () => {
    if (selectedAssemblyLine.index === -1) {
      return
    }

    store.assemblyLines.splice(selectedAssemblyLine.index, 1)
    setAssemblyLines([...store.assemblyLines])
  }

  const deleteAsync = async () => {
    if (!selectedAssemblyLine.assemblyLine) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteAssemblyLine(selectedAssemblyLine.assemblyLine.id)
      setIsDeleting(false)
      setSelectedAssemblyLine({ assemblyLine: undefined, index: -1 })
      setDeleteInputValue('')
      removeFromList()
      addAlert({
        message: 'Assembly Line Deleted!',
        type: SnackbarType.Success,
      })
      setConfirmDeleteAssemblyLineDialogOpen(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleChangeSearch = debounce((value) => {
    searchAssemblyLines(value, selectedOrganization?.id || '', status)
    setSearchValue(value)
  }, 1000)

  const handleChangeOrganization = (organization: Organization) => {
    setSelectedOrganization(organization)
    searchAssemblyLines(searchValue, organization?.id || '', status)
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { value } = event.target
    setSatatus(value)
    searchAssemblyLines(searchValue, selectedOrganization?.id || '', value)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        buttonLabel="Create Assembly Line"
        inputPlaceholder="Search Assembly Lines"
        onClickButton={() => setCreateAssemblyLineDialogOpen(true)}
        onChangeSearch={(value) => handleChangeSearch(value)}
      />
      <OrganizationSearchAndSelectBox>
        <OrganizationSearchAndSelect
          value={selectedOrganization || null}
          onChange={handleChangeOrganization}
          small
          hideLabel
        />
        <FormControl fullWidth size="small">
          <InputLabel id="status-select-label">Search By Status</InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            label={'Search by status'}
            name="status"
            onChange={handleChangeSelect}
            placeholder="Search by status"
          >
            {Object.values(AssemblyLineStatus).map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </OrganizationSearchAndSelectBox>

      {isSeachLoading ? (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <CircularProgress />
        </Box>
      ) : (
        <AssemblyLinesList
          assemblyLines={store.assemblyLines}
          onClickDelete={handleClickDelete}
          onClickEdit={handleClickEdit}
        />
      )}
      <CreateAssemblyLineDialog
        open={createAssemblyLineDialogOpen}
        onClose={handleCloseAssemblyLineDialog}
        onSuccess={selectedAssemblyLine.assemblyLine ? onEdit : onCreate}
        assemblyLine={selectedAssemblyLine.assemblyLine}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteAssemblyLineDialogOpen}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedAssemblyLine.assemblyLine?.name || ''}
        onClickDelete={deleteAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

export default AssemblyLines

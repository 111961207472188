import {
  getAllTeamsOfOrganization,
  getOrganization,
} from '@assembly/api/organization'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import CreateTeamDialog from './CreateTeamDialog'
import TeamsList from './TeamsList'
import { Team } from './types'
import { deleteTeam } from '@assembly/api/teams'
import { SnackbarType } from '@assembly/contexts'

export default function Teams() {
  const { setAppBarTitle } = useAppStore()
  const params = useParams()
  const organizationId = params.id || ''
  const didMount = React.useRef(false)
  const [organizationTeams, setOrganizationTeams] = React.useState<Team[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [createTeamDialogIsVisible, setCreateTeamDialogIsVisible] =
    React.useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = React.useState<{
    team?: Team
    index: number
  }>({
    index: -1,
  })
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const { addAlert } = useSnackbars()
  const [searchResults, setSearchResults] = React.useState<Team[]>()

  React.useEffect(() => {
    if (!didMount.current) {
      getTeamsAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getTeamsAsync = async () => {
    try {
      setIsLoading(true)
      const orgTeamsResponse = await getAllTeamsOfOrganization(organizationId)
      const orgResponse = await getOrganization(organizationId)
      setAppBarTitle(`${orgResponse.data.name} - Teams`)
      setOrganizationTeams(orgTeamsResponse.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = organizationTeams.filter(
          (team) => team.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
        setSearchResults(filtered)
      } else {
        setSearchResults(undefined)
      }
    },
    [organizationTeams]
  )

  const handleClickCreateButton = async () => {
    toggleCreateTeamDialogVisibility(true)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  const renderNoTeamsFound = () => {
    if (organizationTeams && organizationTeams.length === 0) {
      return (
        <Box marginTop={3}>
          <Typography>No teams found</Typography>
        </Box>
      )
    }
  }

  const toggleCreateTeamDialogVisibility = (value: boolean) => {
    setCreateTeamDialogIsVisible(value)
  }

  const handleClickDelete = (team: Team, index: number) => {
    setSelectedTeam({
      index: index,
      team,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const handleClickEdit = (team: Team, index: number) => {
    setSelectedTeam({
      index: index,
      team,
    })
    toggleCreateTeamDialogVisibility(true)
  }

  const onTeamCreated = (value: Team): void => {
    organizationTeams.unshift(value)
    setOrganizationTeams([...organizationTeams])
  }

  const onTeamEdited = (value: Team): void => {
    organizationTeams[selectedTeam.index] = value
    setOrganizationTeams([...organizationTeams])
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedTeam({
      team: undefined,
      index: -1,
    })
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const removeTeamFromList = () => {
    if (selectedTeam.index === -1) {
      return
    }

    organizationTeams.splice(selectedTeam.index, 1)
    setOrganizationTeams([...organizationTeams])
  }

  const deleteTeamAsync = async () => {
    if (!selectedTeam.team) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteTeam(selectedTeam.team.id)
      setIsDeleting(false)
      setSelectedTeam({ team: undefined, index: -1 })
      setDeleteInputValue('')
      removeTeamFromList()
      addAlert({ message: 'Team Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  return (
    <Box>
      <SearchInputAndButton
        buttonLabel="Create Team"
        onChangeSearch={handleChangeSearch}
        onClickButton={handleClickCreateButton}
        inputPlaceholder="Search Teams"
      />
      {renderNoTeamsFound()}
      <TeamsList
        teams={searchResults || organizationTeams}
        onClickDelete={handleClickDelete}
        onClickEdit={handleClickEdit}
      />
      <CreateTeamDialog
        open={createTeamDialogIsVisible}
        onClose={() => toggleCreateTeamDialogVisibility(false)}
        organizationID={organizationId}
        onSuccess={selectedTeam.team ? onTeamEdited : onTeamCreated}
        team={selectedTeam.team}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedTeam.team?.name || ''}
        onClickDelete={deleteTeamAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import {
  SkillAssessment,
  SkillAssessmentDifficulty,
  SkillAssessmentType,
} from './types'
import { Capability } from '../Capabilities/types'
import { Skill } from '../Skills/types'
import SkillsSearchAndSelect from '@assembly/components/SkillsSearchAndSelect'
import { CapabilitiesSearchAndSelect } from '@assembly/components'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import {
  createSkillAssessment,
  updateSkillAssessment,
} from '@assembly/api/skillsAssessment'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'

const StyledTextField = styled(TextField)({
  margin: 0,
})

const FormFieldBox = styled(Box)({
  marginTop: '20px',
})

interface CreateSkillAssessmentDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateSkillAssessmentDialogTitle(
  props: CreateSkillAssessmentDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateSkillAssessmentDialogProps {
  open: boolean
  onClose: () => void
  skillAssessment?: SkillAssessment
  onSuccess: (skillAssessment: SkillAssessment) => void
}

type FormValuesType = {
  title: string
  description: string
  skills: Skill[]
  capabilities: Capability[]
  instructions: string
  hint: string
  durationSecs: number
  difficulty: string
  internalNotes: string
  assessmentCreationPrompt: string
  assessmentScoringPrompt: string
  type: string
  invalid_description: boolean
  invalid_title: boolean
  invalid_skills: boolean
  invalid_capabilities: boolean
}

export default function CreateSkillAssessmentDialog(
  props: CreateSkillAssessmentDialogProps
) {
  const { open, onClose, skillAssessment, onSuccess } = props
  const formInitialValues: FormValuesType = {
    title: '',
    description: '',
    skills: [],
    capabilities: [],
    instructions: '',
    hint: '',
    durationSecs: 0,
    difficulty: SkillAssessmentDifficulty.EASY,
    internalNotes: '',
    assessmentCreationPrompt: '',
    assessmentScoringPrompt: '',
    type: SkillAssessmentType.CODING,
    invalid_description: false,
    invalid_title: false,
    invalid_skills: false,
    invalid_capabilities: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open) {
      if (skillAssessment) {
        setFormValues({
          ...formValues,
          ...skillAssessment,
        })
      }
    }
  }, [skillAssessment, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.title.length === 0 ||
      formValues.description.length === 0 ||
      formValues.skills.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_title: formValues.title.length === 0,
        invalid_description: formValues.description.length === 0,
        invalid_skills: formValues.skills.length === 0,
      })
      return
    }

    setIsSaving(true)
    const payload = {
      title: formValues.title,
      description: formValues.description,
      skills: formValues.skills.map((s) => s.id),
      capabilities: formValues.capabilities.map((c) => c.id),
      instructions: formValues.instructions,
      hint: formValues.hint,
      durationSecs: formValues.durationSecs,
      difficulty: formValues.difficulty,
      internalNotes: formValues.internalNotes,
      assessmentCreationPrompt: formValues.assessmentCreationPrompt,
      assessmentScoringPrompt: formValues.assessmentScoringPrompt,
      type: formValues.type,
    }

    if (skillAssessment) {
      try {
        const { data } = await updateSkillAssessment({
          id: skillAssessment.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Skill Assessment Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        addAlert({
          message: getApiErrorMsg(error),
          type: SnackbarType.Error,
        })
      }
    } else {
      try {
        const { data } = await createSkillAssessment(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Skill Assessment Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        addAlert({
          message: getApiErrorMsg(error),
          type: SnackbarType.Error,
        })
      }
    }
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateSkillAssessmentDialogTitle onClose={handleClose}>
          {skillAssessment ? 'Edit' : 'Create'} Skill Assessment
        </CreateSkillAssessmentDialogTitle>
        <DialogContent dividers>
          <FormFieldBox>
            <StyledTextField
              value={formValues.title}
              margin="dense"
              label="Title"
              type="text"
              name="title"
              fullWidth
              onChange={handleChangeFormField}
              error={formValues.invalid_title}
              helperText={formValues.invalid_title ? 'Required' : ''}
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.description}
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              error={formValues.invalid_description}
              onChange={handleChangeFormField}
              helperText={formValues.invalid_description ? 'Required' : ''}
              rows={4}
              multiline
            />
          </FormFieldBox>
          <FormFieldBox>
            <SkillsSearchAndSelect
              value={formValues.skills}
              onChange={(skills) => {
                setFormValues({
                  ...formValues,
                  skills,
                  invalid_skills: false,
                })
              }}
              error={formValues.invalid_skills}
              helperText={formValues.invalid_skills ? 'Required' : ''}
            />
          </FormFieldBox>
          <FormFieldBox>
            <CapabilitiesSearchAndSelect
              value={formValues.capabilities}
              onChange={(capabilities) => {
                setFormValues({
                  ...formValues,
                  capabilities,
                  invalid_capabilities: false,
                })
              }}
              error={formValues.invalid_capabilities}
              helperText={formValues.invalid_capabilities ? 'Required' : ''}
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.instructions}
              margin="dense"
              name="instructions"
              label="Instructions"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
              rows={15}
              multiline
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.hint}
              margin="dense"
              name="hint"
              label="Hint"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.durationSecs}
              margin="dense"
              name="durationSecs"
              label="Duration In Seconds"
              type="number"
              fullWidth
              onChange={handleChangeFormField}
            />
          </FormFieldBox>
          <FormFieldBox>
            <FormControl fullWidth>
              <InputLabel id="difficulty-select-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-select-label"
                value={formValues.difficulty}
                label="Difficulty"
                name="difficulty"
                onChange={handleChangeSelect}
              >
                {Object.values(SkillAssessmentDifficulty).map((value) => (
                  <MenuItem key={value} value={value}>
                    {capitalize(value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.assessmentCreationPrompt}
              margin="dense"
              name="assessmentCreationPrompt"
              label="Assessment Creation Prompt"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
              rows={10}
              multiline
            />
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.assessmentScoringPrompt}
              margin="dense"
              name="assessmentScoringPrompt"
              label="Assessment Scoring Prompt"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
              rows={10}
              multiline
            />
          </FormFieldBox>
          <FormFieldBox>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                value={formValues.type}
                label="Type"
                name="type"
                onChange={handleChangeSelect}
              >
                {Object.values(SkillAssessmentType).map((value) => (
                  <MenuItem key={value} value={value}>
                    {capitalize(value.replace('_', ' '))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormFieldBox>
          <FormFieldBox>
            <StyledTextField
              value={formValues.internalNotes}
              margin="dense"
              name="internalNotes"
              label="Internal Notes"
              type="text"
              fullWidth
              onChange={handleChangeFormField}
              rows={3}
              multiline
            />
          </FormFieldBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

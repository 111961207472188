import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useNavigate, useLocation } from 'react-router-dom'
import { RoutePaths } from '@assembly/constants'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { useAppStore } from '@assembly/hooks'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { Avatar, Button, CircularProgress, Menu, colors } from '@mui/material'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import { Roles } from '@assembly/pages/Teams/types'
import AdbIcon from '@mui/icons-material/Adb'
import { theme } from '@assembly/theme'
import { logout } from '@assembly/api/auth'
import PsychologyIcon from '@mui/icons-material/Psychology'
import AssessmentIcon from '@mui/icons-material/Assessment'
import ReceiptIcon from '@mui/icons-material/Receipt'
import GroupIcon from '@mui/icons-material/Group'
import WorkIcon from '@mui/icons-material/Work'

const drawerWidth = 260

interface Props {
  children: any
}

export default function SideNavbar(props: Props) {
  const { children } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const appStore = useAppStore()
  const userInfo = appStore.store.userInfo
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const navItems: { name: string; path: string; icon: any }[] = [
    {
      name: 'Organizations',
      path: RoutePaths.OrganizationManager,
      icon: <CorporateFareIcon />,
    },
    {
      name: 'Resources',
      path: RoutePaths.Resources,
      icon: <EngineeringIcon />,
    },
    {
      name: 'Capabilities',
      path: RoutePaths.Capabilities,
      icon: <DesignServicesIcon />,
    },
    {
      name: 'Skills',
      path: RoutePaths.Skills,
      icon: <PsychologyIcon />,
    },
    {
      name: 'Assembly Templates',
      path: RoutePaths.AssemblyTemplates,
      icon: <BlurLinearIcon />,
    },
    {
      name: 'Assembly Lines',
      path: RoutePaths.AssemblyLines,
      icon: <LinearScaleIcon />,
    },
    {
      name: 'Skills Assessment',
      path: RoutePaths.SkillsAssessment,
      icon: <AssessmentIcon />,
    },
    {
      name: 'Talent Invoices',
      path: RoutePaths.TalentInvoices,
      icon: <ReceiptIcon />,
    },
    {
      name: 'Candidates',
      path: RoutePaths.Candidates,
      icon: <GroupIcon />,
    },
    {
      name: 'Jobs',
      path: RoutePaths.Jobs,
      icon: <WorkIcon />,
    },
  ]

  if (userInfo && (userInfo.role === Roles.InternalAdmin || userInfo.role === Roles.SuperAdmin)) {
    navItems.push({
      name: 'Debug',
      path: RoutePaths.Debug,
      icon: <AdbIcon />,
    })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const getTitle = () => {
    const foundNavItem = navItems.find(
      (navItem) => navItem.path === location.pathname
    )
    return (
      foundNavItem?.name || (
        <CircularProgress size={25} sx={{ color: 'white' }} />
      )
    )
  }

  const openDebug = () => {
    window.open(RoutePaths.Debug, '_blank', 'noreferrer')
  }

  const isNavItemSelected = (path: string) => {
    return location.pathname.includes(path)
  }

  const drawer = (
    <div>
      <Toolbar disableGutters>
        <Box width="100%" display="flex" pl="20px" marginTop={2}>
          <img
            src={require('../../assets/logo.png')}
            alt="logo"
            width="140px"
          />
        </Box>
      </Toolbar>
      <List sx={{ marginTop: '20px' }}>
        {navItems.map((navItem, index) => (
          <ListItem key={index}>
            <ListItemButton
              selected={isNavItemSelected(navItem.path)}
              onClick={() =>
                navItem.path === RoutePaths.Debug
                  ? openDebug()
                  : navigate(navItem.path)
              }
              sx={{
                backgroundColor: isNavItemSelected(navItem.path)
                  ? `${theme.palette.primary.main} !important`
                  : 'unset',
                borderRadius: '5px',
                color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '35px',
                  color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
                }}
              >
                {navItem.icon}
              </ListItemIcon>
              <ListItemText primary={navItem.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  )

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: '#fff',
          color: '#000',
          borderBottom: '1px solid #eceefe',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography variant="h5" noWrap component="div" fontWeight={600}>
              {appStore.store.appBarTitle || getTitle()}
            </Typography>
          </Box>

          {userInfo && (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    bgcolor: '#1b17f514',
                    color: '#000',
                    fontWeight: 600,
                  }}
                >
                  {userInfo.name ? userInfo.name[0] : userInfo.email[0]}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box padding="10px 15px">
                  <Typography textAlign="center" variant="h6" fontWeight={600}>
                    {userInfo.name}
                  </Typography>
                  <Typography textAlign="center" sx={{ opacity: 0.6 }}>
                    {userInfo.email}
                  </Typography>
                  <Box display="flex" justifyContent="center" marginTop={2}>
                    <Button variant="outlined" onClick={handleLogout}>
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: colors.grey[50],
              border: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
          PaperProps={{
            sx: {
              backgroundColor: colors.grey[50],
              border: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

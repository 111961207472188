import { axiosWithConfig } from '@assembly/config/axios'

export const getJobs = () => {
  return axiosWithConfig.get(`/admin/jobs/all`)
}

export const createJob = (payload: any) => {
  return axiosWithConfig.post(`/admin/jobs`, payload)
}

export const updateJob = (payload: any) => {
  return axiosWithConfig.put(`/admin/jobs`, payload)
}

export const deleteJob = (id: string) => {
  return axiosWithConfig.delete(`/admin/jobs/${id}`)
}

export const searchJobs = (params: any) => {
  return axiosWithConfig.post(`/admin/jobs/search`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}
import { Box, Link, Typography, colors } from '@mui/material'
import isURL from 'validator/lib/isURL'

export default function InfoItem({
  label,
  value,
  isLink = false,
}: {
  label: string
  value: string
  isLink?: boolean
}) {
  return (
    <Box
      marginBottom={2}
      sx={{
        backgroundColor: colors.grey[50],
        padding: 2,
        borderRadius: 2,
        width: '100%',
      }}
    >
      <Typography variant="h6">{label}</Typography>
      {isLink && isURL(value) ? (
        <Link target="_blank" href={value}>
          {value}
        </Link>
      ) : (
        <Typography>{value}</Typography>
      )}
    </Box>
  )
}

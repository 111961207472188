import React from 'react'
import { getOrganization } from '@assembly/api/organization'
import { Box, CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Organization as OrganizationType } from '@assembly/pages/OrganizationManager/types'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { interpolateRouteUtil } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import { InfoItem, LinkButton } from '@assembly/components'
import { getTeamMemberById } from '@assembly/api/teamMember'
import {
  TeamMember as TeamMemberType,
  Team,
  RoleLabelMapping,
} from '@assembly/pages/Teams/types'
import { getTeam } from '@assembly/api/teams'

export default function TeamMember() {
  const params = useParams<{
    orgId: string
    teamId: string
    teamMemberId: string
  }>()
  const orgId = params.orgId || ''
  const teamId = params.teamId || ''
  const teamMemberId = params.teamMemberId || ''
  const didMount = React.useRef(false)
  const [organizationData, setOrganizationData] =
    React.useState<OrganizationType>()
  const [teamMemberData, setTeamMemberData] = React.useState<TeamMemberType>()
  const [teamData, setTeamData] = React.useState<Team>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { addAlert } = useSnackbars()
  const { setAppBarTitle } = useAppStore()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!didMount.current) {
      getDataAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getDataAsync = async () => {
    try {
      setIsLoading(true)
      const organizationResponse = await getOrganization(orgId)
      const teamMemberResponse = await getTeamMemberById(teamMemberId)
      const teamResponse = await getTeam(teamId)
      setOrganizationData(organizationResponse.data)
      setTeamMemberData(teamMemberResponse.data)
      setTeamData(teamResponse.data)
      setAppBarTitle(
        teamMemberResponse.data.name || teamMemberResponse.data.email
      )
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      addAlert({
        message: 'Error: Failed to load organization',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickViewOrganization = () => {
    navigate(interpolateRouteUtil(RoutePaths.Organization, { id: orgId }))
  }

  const handleClickViewTeam = () => {
    navigate(
      interpolateRouteUtil(RoutePaths.Team, { orgId: orgId, teamId: teamId })
    )
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <InfoItem label="Email" value={teamMemberData?.email || '-'} />
      <InfoItem label="Phone" value={teamMemberData?.phone || '-'} />
      <InfoItem
        label="Role"
        value={
          (teamMemberData && RoleLabelMapping[teamMemberData?.role]) || '-'
        }
      />
      <InfoItem label="Team" value={teamData?.name || '-'} />
      <LinkButton onClick={handleClickViewTeam} label="View Team" />
      <Box marginTop="20px">
        <InfoItem label="Organization" value={organizationData?.name || ''} />
      </Box>
      <LinkButton
        onClick={handleClickViewOrganization}
        label="View Organization"
      />
    </Box>
  )
}

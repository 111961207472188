import React from 'react'
import { getOrganization } from '@assembly/api/organization'
import { Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Organization as OrganizationType } from '@assembly/pages/OrganizationManager/types'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { interpolateRouteUtil } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import { InfoItem } from '@assembly/components'

export default function Organization() {
  const params = useParams<{ id: string }>()
  const id = params.id || ''
  const didMount = React.useRef(false)
  const [organizationData, setOrganizationData] =
    React.useState<OrganizationType>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { addAlert } = useSnackbars()
  const { setAppBarTitle } = useAppStore()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!didMount.current) {
      getOrganizationAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getOrganizationAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getOrganization(id)
      setOrganizationData(data)
      setAppBarTitle(data.name)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      addAlert({
        message: 'Error: Failed to load organization',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickViewTeams = () => {
    navigate(interpolateRouteUtil(RoutePaths.Teams, { id }))
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <InfoItem label="Name" value={organizationData?.name || ''} />
      <InfoItem
        label="First Name (Main Contact)"
        value={organizationData?.mainContactFirstName || '---'}
      />
      <InfoItem
        label="Last Name (Main Contact)"
        value={organizationData?.mainContactLastName || '---'}
      />
      <InfoItem
        label="Email (Main Contact)"
        value={organizationData?.mainContact || '---'}
      />
      <InfoItem
        label="Ap Contact"
        value={organizationData?.apContact || '---'}
      />
      <InfoItem
        label="Client Setup Link"
        value={organizationData?.clientSetupLink || '---'}
        isLink
      />
      <InfoItem
        label="Timezone"
        value={organizationData?.settings.timezone || '---'}
      />
      <InfoItem
        label="Currency"
        value={organizationData?.settings.currency || '---'}
      />
      <InfoItem
        label="Billing Setup"
        value={
          organizationData?.billing?.billingSetupComplete
            ? 'Complete'
            : 'In Progress'
        }
      />
      <InfoItem
        label="Billing Setup Link"
        value={organizationData?.billing?.paymentSetupURL || '---'}
        isLink
      />
      <Typography variant="h6" marginTop={2} marginBottom={2} fontWeight={500}>
        Teams
      </Typography>
      <Button variant="outlined" onClick={handleClickViewTeams}>
        <span>View Teams</span>
        <ArrowForwardIcon sx={{ marginLeft: '5px', fontSize: '20px' }} />
      </Button>
    </Box>
  )
}

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import { sendEmail } from '@assembly/api/candidates'

const StyledTextField = styled(TextField)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

type SendEmailDialogProps = {
  open: boolean
  onClose: () => void
  candidateIds: string[]
}

export default function SendEmailDialog({
  open,
  onClose,
  candidateIds,
}: SendEmailDialogProps) {
  const initialFormValues = {
    subject: '',
    body: '',
    invalid_subject: false,
    invalid_body: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  const handleChangeFormField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value, [`invalid_${name}`]: false })
  }

  const handleClose = () => {
    setFormValues(initialFormValues)
    onClose()
  }

  const handleClickSave = async () => {
    if (formValues.subject.length === 0 || formValues.body.length === 0) {
      setFormValues({
        ...formValues,
        invalid_subject: formValues.subject.length === 0,
        invalid_body: formValues.body.length === 0,
      })
      return
    }

    const params = new URLSearchParams()
    params.append('candidates', candidateIds.join(','))
    params.append('subject', formValues.subject)
    params.append('body', formValues.body)

    try {
      setIsLoading(true)
      const { data } = await sendEmail(params)
      addAlert({
        type: SnackbarType.Success,
        message: data.message,
      })
      setIsLoading(false)
      handleClose()
    } catch (error) {
      addAlert({
        type: SnackbarType.Error,
        message: getApiErrorMsg(error),
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent dividers>
        <StyledTextField
          value={formValues.subject}
          onChange={handleChangeFormField}
          label="Subject"
          name="subject"
          error={formValues.invalid_subject}
          helperText={formValues.invalid_subject ? 'Required' : ''}
        />
        <StyledTextField
          value={formValues.body}
          onChange={handleChangeFormField}
          label="Body"
          name="body"
          multiline
          rows={16}
          error={formValues.invalid_body}
          helperText={formValues.invalid_body ? 'Required' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickSave}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

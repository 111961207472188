const PATH_CHUNK_DIVIDER = '/'
const QUERY_PARAMETERS_DIVIDER = '&'
const PARAMETER_PREFIX = ':'

/**
 * Interpolates the given path with the given params.
 * @param  {string} path
 * @param  {Object} [params={}]
 * @returns {string}
 */
export function interpolateRouteUtil(path: string, params: any = {}) {
  const pathChunks = path.split(PATH_CHUNK_DIVIDER)
  // Assert the params have all the pathChunk
  pathChunks.forEach((pathChunk, index) => {
    // If it's not a pathChunk with parameters, noop
    if (!pathChunk.includes(PARAMETER_PREFIX)) {
      return
    }
    const parametersArr = pathChunk.split(QUERY_PARAMETERS_DIVIDER)
    parametersArr.forEach((parameter) => {
      const parameterKey = parameter.slice(
        parameter.indexOf(PARAMETER_PREFIX) + 1
      )
      // If the params has the `pathChunk` replace it
      if (Object.hasOwnProperty.call(params, parameterKey)) {
        pathChunks[index] = pathChunks[index].replace(
          `${PARAMETER_PREFIX}${parameterKey}`,
          params[parameterKey]
        )
      }
    })
    pathChunk.replace(/:|\?/g, '')
  })
  return pathChunks.join('/')
}

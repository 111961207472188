import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  Typography,
  Paper,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { createCapability, updateCapability } from '@assembly/api/capaility'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  SAMPLE_WORK_PLACEHOLDER_COVER,
  getMediaUrl,
} from '@assembly/utils/media'
import { Capability, ICreateCapability, SampleWork } from './types'
import DeleteIcon from '@mui/icons-material/Delete'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { uploadMedia } from '@assembly/api/media'
import { Media } from '@assembly/shared/types/media'
import { Skill } from '../Skills/types'
import SkillsSearchAndSelect from '@assembly/components/SkillsSearchAndSelect'

interface CreateCapabilityDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateCapabilityDialogTitle(props: CreateCapabilityDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateCapabilityDialogProps {
  open: boolean
  onClose: () => void
  capability?: Capability
  onSuccess: (capability: Capability) => void
}

type FormValuesType = {
  name: string
  description: string
  skills: Skill[]
  invalid_description: boolean
  invalid_name: boolean
  invalid_skills: boolean
}

type SampleWorkValuesType = {
  name: string
  description: string
  mediaList: Media[]
  invalid_name: boolean
  invalid_description: boolean
}

export default function CreateCapabilityDialog(
  props: CreateCapabilityDialogProps
) {
  const { open, onClose, capability, onSuccess } = props
  const formInitialValues: FormValuesType = {
    name: '',
    description: '',
    skills: [],
    invalid_description: false,
    invalid_name: false,
    invalid_skills: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  const [isSampleWorkFormVisible, setIsSampleWorkFormVisible] =
    React.useState<boolean>(false)

  const [isMediaUploading, setIsMediaUploading] = React.useState(false)
  const sampleWorkInitialValues: SampleWorkValuesType = {
    name: '',
    description: '',
    mediaList: [],
    invalid_name: false,
    invalid_description: false,
  }
  const [sampleWork, setSampleWork] = React.useState(sampleWorkInitialValues)
  const [sampleWorks, setSampleWorks] = React.useState<SampleWork[]>([])
  const [selectedSampleWork, setSelectedSampleWork] = React.useState<{
    sampleWork?: SampleWork
    index: number
  }>({ index: -1 })

  React.useEffect(() => {
    if (open) {
      if (capability) {
        setFormValues({
          ...formValues,
          ...capability,
        })

        setSampleWorks([...capability.sampleWork])
      }
    }
  }, [capability, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeSampleWorkFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setSampleWork({
      ...sampleWork,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (formValues.name.length === 0 || formValues.description.length === 0) {
      setFormValues({
        ...formValues,
        invalid_name: formValues.name.length === 0,

        invalid_description: formValues.description.length === 0,
      })
      return
    }
    setIsSaving(true)
    const payload: ICreateCapability = {
      name: formValues.name,
      description: formValues.description,
      skills: formValues.skills.map((skill) => skill.id),
      sampleWork: sampleWorks,
    }
    if (capability) {
      try {
        const { data } = await updateCapability({
          id: capability.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Capabiltity Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createCapability(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Capability Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setSampleWorks([])
    setSelectedSampleWork({ sampleWork: undefined, index: -1 })
    setSampleWork(sampleWorkInitialValues)
    setIsSaving(false)
    onClose()
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])

    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData)
      sampleWork.mediaList.push(data)
      setSampleWork({
        ...sampleWork,
        mediaList: [...sampleWork.mediaList],
      })
      setIsMediaUploading(false)
    } catch (error: any) {
      console.error(error)
      setIsMediaUploading(false)
      addAlert({
        message: 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickDeleteMedia = (index: number) => {
    sampleWork.mediaList.splice(index, 1)
    setSampleWork({
      ...sampleWork,
      mediaList: [...sampleWork.mediaList],
    })
  }

  const handleSaveSampleWork = () => {
    if (sampleWork.name.length === 0 || sampleWork.description.length === 0) {
      setSampleWork({
        ...sampleWork,
        invalid_name: sampleWork.name.length === 0,
        invalid_description: sampleWork.description.length === 0,
      })

      return
    }

    if (selectedSampleWork.sampleWork && selectedSampleWork.index !== -1) {
      sampleWorks[selectedSampleWork.index] = {
        name: sampleWork.name,
        description: sampleWork.description,
        mediaList: sampleWork.mediaList,
      }
    } else {
      sampleWorks.push({
        name: sampleWork.name,
        description: sampleWork.description,
        mediaList: sampleWork.mediaList,
      })
    }

    setSampleWorks([...sampleWorks])
    setSampleWork(sampleWorkInitialValues)
    setIsSampleWorkFormVisible(false)
    setSelectedSampleWork({ sampleWork: undefined, index: -1 })
  }

  const onClickDeleteSampleWork = (index: number) => {
    sampleWorks.splice(index, 1)
    setSampleWorks([...sampleWorks])
  }

  const onClickEditSampleWork = (index: number) => {
    setSelectedSampleWork({
      sampleWork: sampleWorks[index],
      index,
    })
    setIsSampleWorkFormVisible(true)
    setSampleWork({
      ...sampleWork,
      ...sampleWorks[index],
    })
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateCapabilityDialogTitle onClose={handleClose}>
          {capability ? 'Edit' : 'Create'} Capability
        </CreateCapabilityDialogTitle>
        <DialogContent>
          <TextField
            value={formValues.name}
            margin="dense"
            label="Name"
            type="text"
            name="name"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_name}
            helperText={formValues.invalid_name ? 'Required' : ''}
          />
          <TextField
            value={formValues.description}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            error={formValues.invalid_description}
            onChange={handleChangeFormField}
            helperText={formValues.invalid_description ? 'Required' : ''}
            rows={4}
            multiline
          />
          <Box marginTop="20px">
            <SkillsSearchAndSelect
              value={formValues.skills}
              onChange={(skills) => {
                setFormValues({
                  ...formValues,
                  skills,
                  invalid_skills: false,
                })
              }}
            />
          </Box>
          <Box marginTop="25px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight={500}>
                Sample Work
              </Typography>
              <Button
                variant="outlined"
                onClick={() => setIsSampleWorkFormVisible(true)}
                sx={{ opacity: isSampleWorkFormVisible ? 0 : 1 }}
                disabled={isSampleWorkFormVisible}
              >
                Add Sample Work
              </Button>
            </Box>
            {isSampleWorkFormVisible && (
              <Paper sx={{ padding: 2 }}>
                <TextField
                  value={sampleWork.name}
                  margin="dense"
                  label="Name"
                  type="text"
                  name="name"
                  fullWidth
                  onChange={handleChangeSampleWorkFormField}
                  error={sampleWork.invalid_name}
                  helperText={sampleWork.invalid_name ? 'Required' : ''}
                />
                <TextField
                  value={sampleWork.description}
                  margin="dense"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  error={sampleWork.invalid_description}
                  onChange={handleChangeSampleWorkFormField}
                  helperText={sampleWork.invalid_description ? 'Required' : ''}
                />
                <Box marginTop="20px">
                  <Typography variant="h5" fontWeight={500}>
                    Media
                  </Typography>
                  <Box
                    marginTop="10px"
                    marginBottom="10px"
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                  >
                    {sampleWork.mediaList.map((media, index) => (
                      <Box key={index} width="48%">
                        <img
                          width="100%"
                          height={200}
                          style={{
                            objectFit: 'contain',
                            background: '#4f59cc57',
                            borderRadius: '5px',
                          }}
                          src={getMediaUrl(media.media)}
                        />
                        <Box
                          display="flex"
                          justifyContent="center"
                          marginBottom="15px"
                        >
                          <IconButton
                            onClick={() => handleClickDeleteMedia(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isMediaUploading}
                >
                  Add Media
                  <input
                    type="file"
                    hidden
                    value={''}
                    onChange={handleChangeMedia}
                    disabled={isMediaUploading}
                    accept="image/*"
                  />
                </LoadingButton>
                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsSampleWorkFormVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    loading={isSaving}
                    onClick={handleSaveSampleWork}
                    sx={{ marginLeft: 1 }}
                  >
                    Save
                  </Button>
                </Box>
              </Paper>
            )}
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              marginTop="20px"
            >
              {sampleWorks.map((sampleWork, index) => (
                <SampleWorkCard
                  key={index}
                  name={sampleWork.name}
                  description={sampleWork.description}
                  onClickDelete={() => onClickDeleteSampleWork(index)}
                  onClickEdit={() => onClickEditSampleWork(index)}
                  coverUrl={
                    sampleWork.mediaList.length > 0
                      ? getMediaUrl(sampleWork.mediaList[0].media)
                      : SAMPLE_WORK_PLACEHOLDER_COVER
                  }
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            loading={isSaving}
            onClick={handleClickSave}
            disabled={isSampleWorkFormVisible}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type SampleWorkCardProps = {
  name: string
  description: string
  coverUrl?: string
  onClickDelete: () => void
  onClickEdit: () => void
}

function SampleWorkCard({
  name,
  description,
  coverUrl,
  onClickDelete,
  onClickEdit,
}: SampleWorkCardProps) {
  return (
    <Card sx={{ width: '48%' }}>
      <CardMedia sx={{ height: 140 }} image={coverUrl} title="cover" />
      <CardContent>
        <Typography noWrap gutterBottom variant="h5" component="div">
          {name}
        </Typography>
        <Typography noWrap variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={onClickEdit}>
          Edit
        </Button>
        <Button size="small" onClick={onClickDelete}>
          Delete
        </Button>
      </CardActions>
    </Card>
  )
}

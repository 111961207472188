export type PayoutInvoice = {
  id: string
  orgName: string
  billedAssemblyLines: Assembly[]
  billedAssemblyLine: Assembly
  billableAmount: number
  billableUnits: number
  billingEnd: string
  billingStart: string
  details: string
  invoiceLink: string
  resourceID: string
  status: string
  statusMessage: string
  timestamp: number
  transferID: string
  message?: string
}

type Assembly = {
  id: string
  name: string
  orgID: string
  startDate: number
}

export enum InvoiceStatusTypes {
  DISPATCHED = 'DISPATCHED',
  CREATED = 'CREATED',
  PAID = 'PAID',
  PAST_DUE = 'PAST_DUE',
  DISPUTED = 'DISPUTED',
  ERROR = 'ERROR',
}

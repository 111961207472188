import { axiosWithConfig } from '@assembly/config/axios'

export const loginClientDashboard = (payload: any) => {
  return axiosWithConfig.post(`/auth/debug-login/client-dashboard`, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const loginPartnerDashboard = (payload: any) => {
  return axiosWithConfig.post(`/auth/debug-login/partner-dashboard`, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Candidate } from './types'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { getMediaUrl } from '@assembly/utils'
import Markdown from 'react-markdown'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const StyledDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingBottom: '18px',
  paddingTop: '18px',
}))

const StyledButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  padding: '3px 6px',
  paddingRight: '9px',
}))

type InterviewResponsesDialogProps = {
  open: boolean
  onClose: () => void
  candidate: Candidate | null
}

export default function InterviewResponsesDialog({
  open,
  onClose,
  candidate,
}: InterviewResponsesDialogProps) {
  const assessmentResponses =
    candidate?.assessmentResponses as Candidate['assessmentResponses']
  const assessmentResponsesArray = assessmentResponses
    ? Object.values(assessmentResponses)
    : []
  const [index, setIndex] = React.useState<number>(0)

  const currentAssessmentResponse = assessmentResponsesArray[index]

  if (!candidate || !currentAssessmentResponse) {
    return null
  }

  const renderResponse = () => {
    switch (currentAssessmentResponse.type) {
      case 'VIDEO':
        return (
          <video
            key={index}
            controls
            style={{
              width: '100%',
              height: '400px',
              objectFit: 'cover',
              borderRadius: '5px',
            }}
          >
            <source
              src={getMediaUrl(currentAssessmentResponse.videoResponse.media)}
              type="video/webm"
            />
            Your browser does not support the video tag.
          </video>
        )
      case 'WRITTEN':
        return (
          <Typography component="div">
            <Markdown
                children={currentAssessmentResponse.textResponse && currentAssessmentResponse.textResponse.replace(/\\n/g, '\n')}
            />
          </Typography>
        )
    }
  }

  const handleClickNextAssessment = () => {
    if (index === assessmentResponsesArray.length - 1) {
      return
    }

    setIndex((prev) => prev + 1)
  }

  const handleClickPreviousAssessment = () => {
    if (index === 0) {
      return
    }

    setIndex((prev) => prev - 1)
  }

  const isNextDisabled = index === assessmentResponsesArray.length - 1
  const isBackDisabled = index === 0

  const handleClose = () => {
    setIndex(0)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>
        Interview Response ({candidate?.firstName} {candidate?.lastName})
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" fontWeight={600} marginBottom={2}>
          {currentAssessmentResponse.title}
        </Typography>
        {renderResponse()}
      </DialogContent>
      <StyledDialogActions>
        <StyledButton
          variant="outlined"
          onClick={handleClickPreviousAssessment}
          disabled={isBackDisabled}
        >
          <ArrowBackIcon sx={{ width: '19px', height: '19px' }} />
          <span>Back</span>{' '}
        </StyledButton>
        <StyledButton
          onClick={handleClickNextAssessment}
          variant="outlined"
          disabled={isNextDisabled}
        >
          <span>Next</span>{' '}
          <ArrowForwardIcon sx={{ width: '19px', height: '19px' }} />
        </StyledButton>
      </StyledDialogActions>
    </Dialog>
  )
}

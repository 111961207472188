import { axiosWithConfig } from '@assembly/config/axios'

export const getSkillsAssessment = (payload: any) => {
    return axiosWithConfig.post(`/recruiting/skills-assessment/search`, payload)
}

export const createSkillAssessment = (payload: any) => {
    return axiosWithConfig.post(`/recruiting/skills-assessment`, payload)
}

export const updateSkillAssessment = (payload: any) => {
    return axiosWithConfig.put(`/recruiting/skills-assessment`, payload)
}

export const deleteSkillAssessment = (id: string) => {
    return axiosWithConfig.delete(`/recruiting/skills-assessment/${id}`)
}
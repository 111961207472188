import React from 'react'
import {
  loginClientDashboard,
  loginPartnerDashboard,
} from '@assembly/api/debug'
import { Box, Button, TextField } from '@mui/material'
import isEmail from 'validator/lib/isEmail'
import { styled } from '@mui/material/styles'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'

const ButtonsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  marginTop: '15px',
}))

const EmailInput = styled(TextField)(({ theme }) => ({
  margin: 0,
  width: 500,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export default function Debug() {
  const [email, setEmail] = React.useState<string>('')
  const [invalid_email, setInvalid_email] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  const loginClientDashboardAsync = async () => {
    if (email.length === 0 || !isEmail(email)) {
      setInvalid_email(true)
      return
    }

    try {
      const params = new URLSearchParams()
      params.append('email', email)
      const { data } = await loginClientDashboard(params)
      window.location.href = data.message
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const loginPartnerDashboardAsync = async () => {
    if (email.length === 0 || !isEmail(email)) {
      setInvalid_email(true)
      return
    }

    try {
      const params = new URLSearchParams()
      params.append('email', email)
      const { data } = await loginPartnerDashboard(params)
      window.location.href = data.message
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setInvalid_email(false)
  }

  return (
    <Box>
      <EmailInput
        value={email}
        margin="dense"
        label="Email"
        type="email"
        fullWidth
        variant="outlined"
        error={invalid_email}
        onChange={handleChangeEmail}
        helperText={invalid_email ? 'Required' : ''}
        sx={{ margin: 0, width: 500 }}
      />
      <ButtonsBox>
        <Button variant="contained" onClick={loginClientDashboardAsync}>
          Login Client Dashboard
        </Button>
        <Button variant="contained" onClick={loginPartnerDashboardAsync}>
          Login Partner Dashboard
        </Button>
      </ButtonsBox>
    </Box>
  )
}

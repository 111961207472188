import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { Organization } from './types'
import { ListItemButton, colors } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  getMediaUrl,
  interpolateRouteUtil,
  stringAvatar,
} from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'

interface OrganizationListProps {
  organizations: Organization[]
  onClickDelete: (organization: Organization, index: number) => void
  onClickEdit: (organization: Organization, index: number) => void
}

export default function OrganizationsList(props: OrganizationListProps) {
  const navigate = useNavigate()
  const handleClickOrganization = (id: string) => {
    navigate(interpolateRouteUtil(RoutePaths.Organization, { id }))
  }

  return (
    <List>
      {props.organizations.map((organization, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(organization, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(organization, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton
              onClick={() => handleClickOrganization(organization.id)}
            >
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(organization.name)}
                  src={getMediaUrl(organization.settings.logo?.media) || ''}
                >
                  <CorporateFareIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={organization.name || ''}
                secondary={organization.mainContact || ''}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

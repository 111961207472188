import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, colors } from '@mui/material'
import { stringAvatar } from '@assembly/utils'
import { Capability } from './types'
import DesignServicesIcon from '@mui/icons-material/DesignServices'

interface CapabilitiesListProps {
  capabilities: Capability[]
  onClickDelete: (capability: Capability, index: number) => void
  onClickEdit: (capabilityr: Capability, index: number) => void
}

export default function CapabilitiesList(props: CapabilitiesListProps) {
  const handleClickCapability = (id: string) => {}

  return (
    <List>
      {props.capabilities.map((capability, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(capability, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(capability, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton
              onClick={() => handleClickCapability(capability.id)}
              sx={{ cursor: 'default' }}
              disableRipple
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(capability.name)}>
                  <DesignServicesIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={capability.name || ''}
                secondary={capability.description || ''}
                secondaryTypographyProps={{
                  marginRight: '100px',
                  noWrap: true,
                }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

import { Capability } from '@assembly/pages/Capabilities/types'
import { Resource } from '@assembly/pages/Resources/types'

export type ICreateAssemblyTemplate = {
  id?: string
  name: string
  description: string
  capabilities: string[]
  approximateMonthlyBudget: number | null
  allocations: Allocation[]
}

export type AssemblyTemplate = {
  id: string
  name: string
  description: string
  capabilities: Capability[]
  approximateMonthlyBudget: number | null
  allocations: Allocation[]
}

export type Allocation = {
  resourceID: string
  resource?: Resource
  amount: number | null
  rate: number | null
  rateUnit: RateUnit
  frequency: Frequency
  status: Status
  statusMessage: string
  approved: boolean
  approvedBy: string
  startDate: string
  startDateAccepted: boolean | false
  active: boolean
  type: Type
}

export enum RateUnit {
  Hours = 'HOURS',
  Calls = 'CALLS',
  Metric = 'METRIC',
  Imperial = 'IMPERIAL',
}

export enum Frequency {
  Weekly = 'WEEKLY',
  BiWeekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
  Project = 'PROJECT',
}

export enum Status {
  Initialized = 'INITIALIZED',
  Proposal = 'PROPOSAL',
  Provisioning = 'PROVISIONING',
  Ready = 'READY',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum Type {
  Contractor = 'CONTRACTOR',
  API = 'API',
  Service = 'SERVICE',
}

export type CreateAssemblyTemplateForm = {
  name: string
  description: string
  capabilities: Capability[]
  approximateMonthlyBudget: number | null
  allocations: Allocation[]
  invalid_name: boolean
  invalid_description: boolean
  invalid_capabilities: boolean
  invalid_approximateMonthlyBudget: boolean
  invalid_allocations: boolean
}

export type CreateAllocationForm = {
  resourceID: string
  amount: number | null
  rate: number | null
  rateUnit: string
  frequency: string
  status: string
  statusMessage: string
  approved: boolean
  approvedBy: string
  active: boolean
  type: string
  startDate: string
  startDateAccepted: boolean | false
  invalid_resourceID: boolean
  invalid_amount: boolean
  invalid_rate: boolean
  invalid_rateUnit: boolean
  invalid_frequency: boolean
  invalid_status: boolean
  invalid_type: boolean
}

import { getOrganization } from '@assembly/api/organization'
import { getTeam } from '@assembly/api/teams'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Organization } from '@assembly/pages/OrganizationManager/types'
import {
  ConfirmDeleteDialog,
  InfoItem,
  SearchInputAndButton,
  ViewButton,
} from '@assembly/components'
import { TeamMember, Team as TeamType } from '@assembly/pages/Teams/types'
import { interpolateRouteUtil } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import CreateTeamMemberDialog from './CreateTeamMemberDialog'
import {
  deleteTeamMember,
  getAllTeamMembersOfTeam,
} from '@assembly/api/teamMember'
import TeamMembersList from './TeamMembersList'
import { SnackbarType } from '@assembly/contexts'

export default function Team() {
  const navigate = useNavigate()
  const params = useParams()
  const teamId = params.teamId || ''
  const orgId = params.orgId || ''
  const didMount = React.useRef(false)
  const { setAppBarTitle } = useAppStore()
  const [organization, setOrganization] = React.useState<Organization>()
  const [team, setTeam] = React.useState<TeamType>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [createTeamDialogIsVisible, setCreateTeamDialogIsVisible] =
    React.useState<boolean>(false)
  const [selectedTeamMember, setSelectedTeamMember] = React.useState<{
    teamMember?: TeamMember
    index: number
  }>({
    index: -1,
  })
  const [teamMembers, setTeamMembers] = React.useState<TeamMember[]>([])
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const [teamSearchResults, setTeamSearchResults] =
    React.useState<TeamMember[]>()
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMount.current) {
      getTeamAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getTeamAsync = async () => {
    try {
      setIsLoading(true)
      const teamResponse = await getTeam(teamId)
      const orgResponse = await getOrganization(orgId)
      const teamMembersResponse = await getAllTeamMembersOfTeam(teamId)
      setTeam(teamResponse.data)
      setTeamMembers(teamResponse.data.members)
      setAppBarTitle(teamResponse.data.name)
      setOrganization(orgResponse.data)
      setTeamMembers(teamMembersResponse.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleClickViewOrganization = () => {
    navigate(interpolateRouteUtil(RoutePaths.Organization, { id: orgId }))
  }

  const handleClickCreateTeamMember = () => {
    setCreateTeamDialogIsVisible(true)
  }

  const onTeamMemberCreated = (value: TeamMember): void => {
    teamMembers.unshift(value)
    setTeamMembers([...teamMembers])
  }

  const onTeamMemberEdited = (value: TeamMember): void => {
    teamMembers[selectedTeamMember.index] = value
    setTeamMembers([...teamMembers])
  }

  const handleClickDelete = (teamMember: TeamMember, index: number) => {
    setSelectedTeamMember({
      teamMember,
      index,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const handleClickEdit = (teamMember: TeamMember, index: number) => {
    setSelectedTeamMember({
      teamMember,
      index,
    })
    setCreateTeamDialogIsVisible(true)
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedTeamMember({
      teamMember: undefined,
      index: -1,
    })
  }

  const removeTeamMemberFromList = () => {
    if (selectedTeamMember.index === -1) {
      return
    }

    teamMembers.splice(selectedTeamMember.index, 1)
    setTeamMembers([...teamMembers])
  }

  const deleteTeamMemberAsync = async () => {
    if (!selectedTeamMember.teamMember) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteTeamMember(selectedTeamMember.teamMember.id)
      setIsDeleting(false)
      setSelectedTeamMember({ teamMember: undefined, index: -1 })
      setDeleteInputValue('')
      removeTeamMemberFromList()
      addAlert({ message: 'Team Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = teamMembers.filter(
          (tm) => tm.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
        setTeamSearchResults(filtered)
      } else {
        setTeamSearchResults(undefined)
      }
    },
    [teamMembers]
  )

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <InfoItem label="Primary Contact" value={team?.mainContact || ''} />
      <InfoItem label="Organization" value={organization?.name || ''} />
      <ViewButton
        text="View Organization"
        onClick={handleClickViewOrganization}
      />
      <Typography variant="h5" fontWeight={500} marginTop={2} marginBottom={2}>
        Team Members
      </Typography>
      <SearchInputAndButton
        buttonLabel="Create Team Member"
        onChangeSearch={handleChangeSearch}
        onClickButton={handleClickCreateTeamMember}
        inputPlaceholder="Search Team Members"
      />
      {teamMembers.length === 0 ? (
        <Typography marginTop={2}>No team members found</Typography>
      ) : (
        <TeamMembersList
          teamMembers={teamSearchResults || teamMembers}
          onClickDelete={handleClickDelete}
          onClickEdit={handleClickEdit}
        />
      )}
      <CreateTeamMemberDialog
        open={createTeamDialogIsVisible}
        onClose={() => {
          setCreateTeamDialogIsVisible(false)
          setSelectedTeamMember({ teamMember: undefined, index: -1 })
        }}
        organizationID={orgId}
        teamID={teamId}
        teamMember={selectedTeamMember.teamMember}
        onSuccess={
          selectedTeamMember.teamMember
            ? onTeamMemberEdited
            : onTeamMemberCreated
        }
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedTeamMember.teamMember?.name || ''}
        onClickDelete={deleteTeamMemberAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

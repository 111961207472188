import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { Highlight, HighlightType } from '@assembly/pages/Resources/types'
import { capitalize } from 'lodash'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { uploadMedia } from '@assembly/api/resources'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg, getMediaUrl } from '@assembly/utils'
import { Media } from '@assembly/shared/types/media'
import { CircularProgressWithLabel } from '@assembly/components'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const MediaButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}))

const UploadProgressIndicatorBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

type AddHighlightDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: (highlight: Highlight) => void
  highlight?: Highlight
}

export default function AddHighlightDialog({
  open,
  onClose,
  onSuccess,
  highlight,
}: AddHighlightDialogProps) {
  const initialFormValues = {
    title: '',
    description: '',
    question: '',
    answerMedia: null,
    answerText: '',
    answerUrl: '',
    type: HighlightType.Text,
    invalid_title: false,
    invalid_description: false,
    invalid_question: false,
    invalid_answerMedia: false,
    invalid_answerText: false,
    invalid_answerUrl: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open && highlight) {
      setFormValues({
        ...initialFormValues,
        type: highlight?.type as HighlightType,
        title: highlight?.title as string,
        description: highlight?.description as string,
        question: highlight?.question as string,
        answerMedia: highlight?.answerMedia as any,
        answerText: highlight?.answerText as string,
        answerUrl: highlight?.answerUrl as string,
      })
    }
  }, [open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      [`invalid_${event.target.name}`]: false,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      invalid_title: false,
      invalid_description: false,
      invalid_question: false,
      invalid_answerMedia: false,
      invalid_answerText: false,
      invalid_answerUrl: false,
    })
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData, (progress: any) => {
        setUploadProgress(progress)
      })
      setFormValues({
        ...formValues,
        answerMedia: data,
      })
      setIsMediaUploading(false)
      setUploadProgress(0)
    } catch (error: any) {
      setIsMediaUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickAdd = () => {
    switch (formValues.type) {
      case HighlightType.Text:
        handleAddHighlightTypeText()
        break
      case HighlightType.Url:
        handleAddHighlightTypeUrl()
        break
      case HighlightType.Media:
        handleAddHighlightTypeMedia()
        break
      default:
        break
    }
  }

  const handleAddHighlightTypeText = () => {
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.question ||
      !formValues.answerText
    ) {
      setFormValues({
        ...formValues,
        invalid_title: !formValues.title,
        invalid_description: !formValues.description,
        invalid_question: !formValues.question,
        invalid_answerText: !formValues.answerText,
      })
      return
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      question: formValues.question,
      answerText: formValues.answerText,
      answerUrl: null,
      answerMedia: null,
      type: formValues.type,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleAddHighlightTypeUrl = () => {
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.question ||
      !formValues.answerUrl
    ) {
      setFormValues({
        ...formValues,
        invalid_title: !formValues.title,
        invalid_description: !formValues.description,
        invalid_question: !formValues.question,
        invalid_answerUrl: !formValues.answerUrl,
      })
      return
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      question: formValues.question,
      answerText: null,
      answerUrl: formValues.answerUrl,
      answerMedia: null,
      type: formValues.type,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleAddHighlightTypeMedia = () => {
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.question ||
      !formValues.answerMedia
    ) {
      setFormValues({
        ...formValues,
        invalid_title: !formValues.title,
        invalid_description: !formValues.description,
        invalid_question: !formValues.question,
        invalid_answerMedia: !formValues.answerMedia,
      })
      return
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      question: formValues.question,
      answerText: null,
      answerUrl: null,
      answerMedia: formValues.answerMedia,
      type: formValues.type,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setFormValues(initialFormValues)
    }, 195)
  }

  const renderFurtherFields = () => {
    switch (formValues.type) {
      case HighlightType.Text:
        return (
          <StyledTextField
            value={formValues.answerText}
            onChange={handleChange}
            name="answerText"
            label="Answer"
            fullWidth
            rows={4}
            multiline
            error={formValues.invalid_answerText}
            helperText={formValues.invalid_answerText && 'Answer is required'}
          />
        )
      case HighlightType.Url:
        return (
          <StyledTextField
            value={formValues.answerUrl}
            onChange={handleChange}
            name="answerUrl"
            label="URL"
            fullWidth
            error={formValues.invalid_answerUrl}
            helperText={formValues.invalid_answerUrl && 'URL is required'}
          />
        )
      case HighlightType.Media:
        return (
          <Box>
            <MediaButtonBox>
              <LoadingButton
                variant="contained"
                component="label"
                sx={{ boxShadow: 'none' }}
                loading={isMediaUploading}
              >
                {formValues.answerMedia ? 'Change Media' : 'Add Media'}
                <input
                  type="file"
                  hidden
                  value={''}
                  onChange={handleChangeMedia}
                  disabled={isMediaUploading}
                  accept="video/mp4"
                />
              </LoadingButton>
              {isMediaUploading && (
                <UploadProgressIndicatorBox>
                  <CircularProgressWithLabel value={uploadProgress} />
                  <Typography variant="body2">
                    {isMediaUploading && uploadProgress < 100
                      ? 'Uploading...'
                      : 'Processing...'}
                  </Typography>
                </UploadProgressIndicatorBox>
              )}
            </MediaButtonBox>
            {formValues.invalid_answerMedia && (
              <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                Media is required
              </Typography>
            )}
            {formValues.answerMedia && (
              <VideoPlayer
                url={getMediaUrl((formValues.answerMedia as Media).media)}
              />
            )}
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{highlight ? 'Edit' : 'Add'} Highlight</DialogTitle>
      <DialogContent dividers>
        <StyledFormControl fullWidth>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            value={formValues.type}
            label="Age"
            onChange={handleChangeSelect}
            name="type"
          >
            {Object.values(HighlightType).map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledTextField
          value={formValues.title}
          onChange={handleChange}
          name="title"
          label="Title"
          error={formValues.invalid_title}
          helperText={formValues.invalid_title && 'Title is required'}
          fullWidth
        />
        <StyledTextField
          value={formValues.description}
          onChange={handleChange}
          name="description"
          label="Description"
          fullWidth
          rows={4}
          multiline
          error={formValues.invalid_description}
          helperText={
            formValues.invalid_description && 'Description is required'
          }
        />
        <StyledTextField
          value={formValues.question}
          onChange={handleChange}
          name="question"
          label="Question"
          fullWidth
          rows={2}
          multiline
          error={formValues.invalid_question}
          helperText={formValues.invalid_question && 'Question is required'}
        />
        {renderFurtherFields()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClickAdd}>{highlight ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  )
}

function VideoPlayer({ url }: any) {
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (
    <video
      controls
      ref={videoRef}
      width={'100%'}
      style={{ marginTop: '15px', borderRadius: '5px', maxHeight: '300px' }}
    >
      <source src={url} />
    </video>
  )
}

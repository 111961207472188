import { getCandidates } from '@assembly/api/candidates'
import {
  Box,
  Typography,
  Link,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import React from 'react'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import EditCandidateDialog from './UpdateCandidateDialog'
import { Candidate, CandidateState, EvaluationState } from './types'
import ApplicationsDialog from './ApplicationsDialog'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import EmailIcon from '@mui/icons-material/Email'
import EditCandidatesStateDialog from './UpdateCandidatesState'
import SendEmailDialog from './SendEmailDialog'
import moment from 'moment-timezone'
import ResumeDialog from './ResumeDialog'
import InterviewResponsesDialog from './InterviewResponsesDialog'
import ProfileReviewScoreDialog from './ProfileReviewScoreDialog'
const DIALOG_TRANSITION_DURATION = 300
const Cell = styled(Typography)(() => ({
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  textAlign: 'center',
  fontSize: '14px',
}))

const StyledLink = styled(Link)(() => ({
  fontSize: '15px',
  cursor: 'pointer',
  color: '#000',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
}))

const StyledButton = styled(LoadingButton)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
}))

const StyledTextField = styled(TextField)(() => ({
  margin: 0,
  width: '300px',
}))

const StyledFormControl = styled(FormControl)(() => ({
  margin: 0,
  width: '270px',
}))

const FiltersBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
}))

const ActionButtonsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}))

export default function Candidates() {
  const didMountRef = React.useRef<boolean>(false)
  const [rows, setRows] = React.useState<Candidate[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = React.useState<boolean>(false)
  const [selectedCandidate, setSelectedCandidate] =
    React.useState<Candidate | null>(null)
  const [applicationsDialogOpen, setApplicationsDialogOpen] =
    React.useState<boolean>(false)
  const initialSearchParams = {
    search: '',
    job_title: null,
    min_profile_score: null,
    state: '',
    evaluationState: '',
  }
  const [searchParams, setSearchParams] = React.useState(initialSearchParams)
  const [searchFiltersApplied, setSearchFiltersApplied] = React.useState(false)
  const { addAlert } = useSnackbars()
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const [updateStateDialogOpen, setUpdateStateDialogOpen] =
    React.useState(false)
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = React.useState(false)
  const [resumeDialogOpen, setResumeDialogOpen] = React.useState(false)
  const [responsesDialogOpen, setResponsesDialogOpen] = React.useState(false)
  const isGreaterThen1771 = useMediaQuery('(min-width:1771px)')
  const [profileReviewScoreDetailsDialog, setProfileReviewScoreDetailsDialog] =
    React.useState(false)

  React.useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      getCandidatesAsync()
    }
  }, [])

  const getCandidatesAsync = async () => {
    try {
      setIsLoading(true)
      const parmas = new URLSearchParams()
      parmas.append('search', '')
      const { data } = await getCandidates(parmas)
      setRows(data)
      setIsLoading(false)
      setSearchFiltersApplied(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const renderCell = (params: GridCellParams) => {
    return <Cell>{params.row[params.field]}</Cell>
  }

  const renderDateCell = (params: GridCellParams) => {
    if (!params.row[params.field]) {
      return <Cell></Cell>
    }

    return <Cell>{moment(params.row[params.field]).format('YYYY-MM-DD')}</Cell>
  }

  const renderLinkCell = (params: GridCellParams) => {
    return (
      <StyledLink href={params.row[params.field]} target="_blank">
        Link
      </StyledLink>
    )
  }

  const handleClickUpdate = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setEditDialogOpen(true)
  }

  const handleClickViewProfileReviewScoreDetails = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setProfileReviewScoreDetailsDialog(true)
  }

  const handleClickViewApplications = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setApplicationsDialogOpen(true)
  }

  const handleClickViewInterviewResponses = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setResponsesDialogOpen(true)
  }

  const ProfileReviewScoreDetailsCell = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickViewProfileReviewScoreDetails(params.row)}
      >
        View
      </StyledButton>
    )
  }

  const UpdateCell = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickUpdate(params.row)}
      >
        Update
      </StyledButton>
    )
  }

  const applicationsCell = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickViewApplications(params.row)}
      >
        View Applications
      </StyledButton>
    )
  }

  const interviewResponsesCell = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickViewInterviewResponses(params.row)}
        disabled={
          !params.row.assessmentResponses ||
          Object.keys(params.row.assessmentResponses).length === 0
        }
      >
        View Responses
      </StyledButton>
    )
  }

  const handleClickViewResume = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setResumeDialogOpen(true)
  }

  const resumeCell = (params: GridCellParams) => {
    if (!params.row.resume) {
      return <Cell>---</Cell>
    }

    return (
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickViewResume(params.row)}
      >
        View Resume
      </StyledButton>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'update',
      headerName: 'Update',
      width: 120,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: UpdateCell,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 180,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCell,
    },
    {
      type: 'string',
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      editable: false,
      filterable: true,
      sortable: false,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.firstName
      },
    },
    {
      type: 'string',
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: false,
      filterable: true,
      sortable: false,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.lastName
      },
    },
    {
      type: 'string',
      field: 'email',
      headerName: 'Email',
      width: 250,
      editable: false,
      filterable: true,
      sortable: false,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.email
      },
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 350,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'resume',
      headerName: 'Resume',
      width: 120,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: resumeCell,
    },
    {
      field: 'resumeLink',
      headerName: 'Resume Link',
      width: 120,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderLinkCell,
      align: 'center',
      headerAlign: 'center',
    },
    {
      type: 'string',
      field: 'source',
      headerName: 'Source',
      width: 150,
      editable: false,
      filterable: true,
      sortable: false,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.source
      },
    },
    {
      type: 'string',
      field: 'referral',
      headerName: 'Referral',
      width: 150,
      editable: false,
      filterable: true,
      sortable: false,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.referral
      },
    },
    {
      type: 'string',
      field: 'evaluationState',
      headerName: 'Evaluation State',
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.evaluationState
      },
    },
    {
      type: 'string',
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.state
      },
    },
    {
      type: 'date',
      field: 'lastUpdateToCandidate',
      headerName: 'Last Update To Candidate',
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      renderCell: renderDateCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return moment(params.row.lastUpdateToCandidate).toDate()
      },
    },
    {
      field: 'applications',
      headerName: 'Applications',
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: applicationsCell,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'assessmentResponses',
      headerName: 'Interview Responses',
      renderCell: interviewResponsesCell,
      width: 160,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      type: 'string',
      field: 'expectedCompUSD',
      headerName: 'Expected Comp (USD)',
      width: 150,
      editable: false,
      filterable: true,
      sortable: true,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.expectedCompUSD
      },
    },
    {
      type: 'string',
      field: 'profileReviewScore',
      headerName: 'Profile Review Score',
      width: 160,
      editable: false,
      filterable: true,
      sortable: true,
      disableColumnMenu: false,
      renderCell: renderCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.profileReviewScore
      },
    },
    {
      type: 'string',
      field: 'profileReviewScoreDetails',
      headerName: 'Profile Review Score Details',
      width: 200,
      editable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: false,
      renderCell: ProfileReviewScoreDetailsCell,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.profileReviewScoreDetails
      },
    },
  ]

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
    setSelectedCandidate(null)
  }

  const handleCloseApplicationsDialog = () => {
    setApplicationsDialogOpen(false)
    setSelectedCandidate(null)
  }

  const onUpdateCandidateSuccess = (candidate: Candidate) => {
    const index = rows.findIndex((item) => item.id === candidate.id)
    if (index !== -1) {
      const newRows = [...rows]
      newRows[index] = candidate
      setRows(newRows)
    }
  }

  const handleChangeFormField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setSearchParams({ ...searchParams, [name]: value })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setSearchParams({
      ...searchParams,
      [name]: value,
    })
  }

  const handleClickSearch = async () => {
    try {
      setIsSearchLoading(true)
      const params = new URLSearchParams()

      if (searchParams.search) {
        params.append('search', searchParams.search)
      }

      if (searchParams.job_title) {
        params.append('job_title', searchParams.job_title)
      }

      if (searchParams.min_profile_score) {
        params.append('min_profile_score', searchParams.min_profile_score)
      }

      if (searchParams.state) {
        params.append('state', searchParams.state)
      }

      if (searchParams.evaluationState) {
        params.append('evaluationState', searchParams.evaluationState)
      }

      const { data } = await getCandidates(params)
      setRows(data)
      setIsSearchLoading(false)
      setSearchFiltersApplied(true)
      setRowSelectionModel([])
    } catch (error: any) {
      setIsSearchLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickClear = async () => {
    setSearchParams(initialSearchParams)
    getCandidatesAsync()
    setRowSelectionModel([])
  }

  const isSearchBtnDisabled = () => {
    return (
      searchParams.search.length === 0 &&
      searchParams.job_title == null &&
      searchParams.min_profile_score == null &&
      searchParams.state.length === 0 &&
      searchParams.evaluationState.length === 0
    )
  }

  const handleClickSendEmail = () => {
    setSendEmailDialogOpen(true)
  }

  const handleClickUpdateState = () => {
    setUpdateStateDialogOpen(true)
  }

  const handleCloseStateUpdateDialog = () => {
    setUpdateStateDialogOpen(false)
  }

  const handleSuccessStateUpdate = (state: string) => {
    const newRows = [...rows]
    rowSelectionModel.forEach((id) => {
      const index = rows.findIndex((item) => item.id === id)
      if (index !== -1) {
        newRows[index].state = state
      }
    })
    setRows(newRows)
  }

  const handleCloseResumeDialog = () => {
    setResumeDialogOpen(false)
    setTimeout(() => {
      setSelectedCandidate(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleCloseResponsesDialog = () => {
    setResponsesDialogOpen(false)
    setTimeout(() => {
      setSelectedCandidate(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleCloseEmailDialog = () => {
    setSendEmailDialogOpen(false)
    setTimeout(() => {
      setSelectedCandidate(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleCloseProfileReviewScoreDetailsDialog = () => {
    setProfileReviewScoreDetailsDialog(false)
    setTimeout(() => {
      setSelectedCandidate(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  return (
    <Box>
      <Header>
        <FiltersBox>
          <StyledTextField
            label="Search"
            size="small"
            value={searchParams.search}
            name="search"
            onChange={handleChangeFormField}
          />
          <StyledTextField
            label="Job Title"
            size="small"
            value={searchParams.job_title}
            name="job_title"
            onChange={handleChangeFormField}
          />
          <TextField
            inputProps={{ type: 'number' }}
            label="Min Profile Score"
            size="small"
            value={searchParams.min_profile_score}
            name="min_profile_score"
            onChange={handleChangeFormField}
          />
          <StyledFormControl size="small">
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
              labelId="state-select-label"
              value={searchParams.state}
              label="State"
              name="state"
              onChange={handleChangeSelect}
            >
              {Object.entries(CandidateState).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl size="small">
            <InputLabel id="evaluation-select-label">
              Evaluation State
            </InputLabel>
            <Select
              labelId="evaluation-select-label"
              value={searchParams.evaluationState}
              label="Evaluation State"
              name="evaluationState"
              onChange={handleChangeSelect}
            >
              {Object.entries(EvaluationState).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledButton
            loading={isSearchLoading}
            variant="contained"
            onClick={handleClickSearch}
            disabled={isSearchBtnDisabled()}
          >
            Search
          </StyledButton>
          {searchFiltersApplied && (
            <StyledButton
              variant="outlined"
              onClick={handleClickClear}
              loading={isLoading}
            >
              Clear
            </StyledButton>
          )}
        </FiltersBox>
        {rowSelectionModel.length > 0 && (
          <ActionButtonsBox>
            <Tooltip title="Send Email">
              <IconButton color="primary" onClick={handleClickSendEmail}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Update State">
              <IconButton color="primary" onClick={handleClickUpdateState}>
                <MoveDownIcon />
              </IconButton>
            </Tooltip>
          </ActionButtonsBox>
        )}
      </Header>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={isLoading || isSearchLoading}
        sx={{
          height: isGreaterThen1771
            ? 'calc(100vh - 170px)'
            : 'calc(100vh - 225px)',
        }}
        checkboxSelection
        disableRowSelectionOnClick
        showCellVerticalBorder
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
        hideFooter
      />
      <EditCandidateDialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        candidate={selectedCandidate}
        onSuccess={onUpdateCandidateSuccess}
      />
      <ApplicationsDialog
        open={applicationsDialogOpen}
        onClose={handleCloseApplicationsDialog}
        candidate={selectedCandidate}
      />
      <EditCandidatesStateDialog
        open={updateStateDialogOpen}
        onClose={handleCloseStateUpdateDialog}
        candidateIds={rowSelectionModel as string[]}
        onSuccess={handleSuccessStateUpdate}
      />
      <SendEmailDialog
        open={sendEmailDialogOpen}
        onClose={handleCloseEmailDialog}
        candidateIds={rowSelectionModel as string[]}
      />
      <ResumeDialog
        open={resumeDialogOpen}
        onClose={handleCloseResumeDialog}
        candidate={selectedCandidate}
      />
      <InterviewResponsesDialog
        open={responsesDialogOpen}
        candidate={selectedCandidate}
        onClose={handleCloseResponsesDialog}
      />
      <ProfileReviewScoreDialog
        open={profileReviewScoreDetailsDialog}
        onClose={handleCloseProfileReviewScoreDetailsDialog}
        value={selectedCandidate?.profileReviewScoreDetails}
      />
    </Box>
  )
}

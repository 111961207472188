import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import {
  Chip,
  Link,
  List,
  ListItemButton,
  Typography,
  colors,
} from '@mui/material'
import { stringAvatar } from '@assembly/utils'
import { Job } from './types'
import WorkIcon from '@mui/icons-material/Work'
import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { baseURL } from '@assembly/config/axios'

interface JobsListProps {
  jobs: Job[]
  onClickDelete: (skillsAssessments: Job, index: number) => void
  onClickEdit: (skillsAssessments: Job, index: number) => void
}

export default function JobsList(props: JobsListProps) {
  const { onClickDelete, onClickEdit, jobs } = props
  const [tooltipText, setTooltipText] = React.useState('Copy link')

  const recrutingDashboardLink = baseURL?.includes('dev')
    ? 'https://recruiting-dev.assembly-industries.com/job-assessment'
    : 'https://recruiting.assembly-industries.com/job-assessment'

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    setTooltipText('Copied!')
    setTimeout(() => {
      setTooltipText('Copy link')
    }, 2000)
  }

  return (
    <List>
      {jobs.length === 0 && (
        <Typography sx={{ marginTop: 2 }}>No jobs found</Typography>
      )}
      {jobs.map((job, index) => (
        <React.Fragment key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => onClickEdit(job, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => onClickDelete(job, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton
              onClick={() => {}}
              sx={{ cursor: 'default' }}
              disableRipple
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(job.title)}>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={job.title || ''}
                secondary={
                  <Box component="span">
                    <Box marginTop={1}>
                      <b>Job ID : </b>
                      {'  ' + job.id}
                      <Tooltip title={tooltipText} sx={{ marginLeft: 1 }}>
                        <IconButton onClick={() => copyToClipboard(job.id)}>
                          <ContentCopyIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Chip
                      label={job.status}
                      size="small"
                      variant="outlined"
                      sx={{ marginTop: '10px' }}
                    />
                    <Box marginTop={1}>
                      <b>Skills Assessment Link : </b>
                      {'  '}
                      <Link
                        href={recrutingDashboardLink + '/' + job.id}
                        target="_blank"
                      >
                        {recrutingDashboardLink + '/' + job.id}
                      </Link>
                      <Tooltip title={tooltipText} sx={{ marginLeft: 1 }}>
                        <IconButton
                          onClick={() =>
                            copyToClipboard(
                              recrutingDashboardLink + '/' + job.id
                            )
                          }
                        >
                          <ContentCopyIcon sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                }
                secondaryTypographyProps={{
                  marginRight: '100px',
                  noWrap: true,
                }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )
}

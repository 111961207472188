import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { Team } from './types'
import { ListItemButton, colors } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { interpolateRouteUtil, stringAvatar } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'

interface TeamsListProps {
  teams: Team[]
  onClickDelete: (team: Team, index: number) => void
  onClickEdit: (team: Team, index: number) => void
}

export default function TeamsList(props: TeamsListProps) {
  const navigate = useNavigate()
  const params = useParams()
  const handleClickTeam = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.Team, {
        orgId: params.id || '',
        teamId: id,
      })
    )
  }

  return (
    <List>
      {props.teams.map((team, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(team, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(team, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton onClick={() => handleClickTeam(team.id)}>
              <ListItemAvatar>
                <Avatar {...stringAvatar(team.name)} />
              </ListItemAvatar>
              <ListItemText
                primary={team.name || ''}
                secondary={team.mainContact || ''}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

import { axiosWithConfig } from '@assembly/config/axios'
import { ICreateCapability } from '@assembly/pages/Capabilities/types'

export const createCapability = (payload: ICreateCapability) => {
  return axiosWithConfig.post(`/admin/assembly_line/capability`, payload)
}

export const updateCapability = (payload: ICreateCapability) => {
  return axiosWithConfig.put(`/admin/assembly_line/capability`, payload)
}

export const deleteCapability = (id: string) => {
  return axiosWithConfig.delete(`/admin/assembly_line/capability/${id}`)
}

export const getAllCapabilites = (payload: any) => {
  return axiosWithConfig.post(
    `/admin/assembly_line/capabilities/search`,
    payload,
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
  )
}


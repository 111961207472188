import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {debounce} from '@mui/material/utils'
import {CircularProgress} from '@mui/material'
import {Resource} from '@assembly/pages/Resources/types'
import {getAllResources} from '@assembly/api/resources'

type ResourceSearchAndSelectProps = {
    onChange: (resource: Resource) => void
    value: Resource | null
    error: boolean
    helperText: string
    resourceID: string | null
    assemblyStatus?: string
}

export default function ResourceSearchAndSelect({
                                                    onChange,
                                                    value,
                                                    error,
                                                    helperText,
                                                    resourceID,
                                                }: ResourceSearchAndSelectProps) {
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState<Resource[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const fetch = React.useMemo(
        () =>
            debounce(
                (request: { input: string }, callback: (results?: any) => void) => {
                    getResourcesAsync(request.input, callback)
                },
                400
            ),
        []
    )

    const getResourcesAsync = async (search: string, callback: any) => {
        try {
            setLoading(true)
            const params = new URLSearchParams()
            params.append('search', search)
            params.append('status', 'ONBOARDED,ALLOCATED,ACTIVE')
            const {data} = await getAllResources(params)
            if (!value && resourceID) {
                const resource = data.find((r: Resource) => r.id === resourceID)
                if (resource) {
                    onChange(resource)
                }
            }
            callback(data)
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    React.useEffect(() => {
        fetch({input: inputValue}, (results?: any) => {
            setOptions([...results])
        })
    }, [inputValue, fetch])

    return (
        <Autocomplete
            loading={loading}
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            value={value}
            noOptionsText="No Resources"
            onChange={(_: any, newValue: any) => {
                onChange(newValue)
            }}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select Resource"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20}/>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        <Grid
                            item
                            sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}
                        >
                            <Box component="span" sx={{fontWeight: 'bold'}}>
                                {option.firstName + ' ' + option.lastName + " ( " + option.status + " )"}
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {option.label}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {"Availability : " + option.availableWeeklyHours + " hours/week"}
                            </Typography>
                        </Grid>
                    </li>
                )
            }}
        />
    )
}

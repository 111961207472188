import { talentInvoices } from '@assembly/api/resources'
import { SnackbarType } from '@assembly/contexts'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg, interpolateRouteUtil } from '@assembly/utils'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React from 'react'
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import { RoutePaths } from '@assembly/constants'
import moment from 'moment-timezone'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  InvoiceStatusTypes,
  PayoutInvoice,
} from '../ResourcePayoutInvoices/types'
import EditPayoutInvoiceDialog from '../ResourcePayoutInvoices/EditPayoutInvoiceDialog'
import SendPaymentDialog from '../ResourcePayoutInvoices/SendPaymentDialog'
import { reprocessPayoutEntries, reprocessPayoutInvoice } from '@assembly/api/resources'

const DIALOG_TRANSITION_DURATION = 300

const ColValue = styled(Typography)(() => ({
  fontSize: '15px',
}))

const StyledLink = styled(Link)(() => ({
  fontSize: '15px',
  cursor: 'pointer',
  color: '#000',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
}))

const AssemblyCol = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
}))

const DetailCol = styled(Typography)(() => ({
  fontSize: '15px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}))

const InvoiceLink = styled(Link)(() => ({
  fontSize: '15px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  cursor: 'pointer',
  color: '#000',
}))

const StyledButton = styled(LoadingButton)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const StyledFormControl = styled(FormControl)(({}) => ({
  margin: 0,
  width: '300px',
}))

const FilterBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '20px',
  gap: '10px',
}))

export default function TalentInvoices() {
  const { setAppBarTitle } = useAppStore()
  const { addAlert } = useSnackbars()
  const didMount = React.useRef<boolean>(false)
  const [data, setData] = React.useState<PayoutInvoice[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] =
    React.useState<PayoutInvoice | null>(null)
  const [confirmPaymentDialogOpen, setConfirmPaymentDialogOpen] =
    React.useState<boolean>(false)
  const [status, setStatus] = React.useState<string>(InvoiceStatusTypes.CREATED)
  const [logsWindow, setLogsWindow] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMount.current) {
      getInvoicesAsync(InvoiceStatusTypes.CREATED)
      didMount.current = true
    }
    setAppBarTitle(`Talent Invoices`)

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getInvoicesAsync = async (status = '') => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('status', status)
      const { data } = await talentInvoices(params)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const orgNameCol = (params: GridCellParams) => {
    return <ColValue>{params.row.orgName}</ColValue>
  }

  const assemblyCol = (params: GridCellParams) => {
    return (
      <AssemblyCol>
        <StyledLink
          href={interpolateRouteUtil(RoutePaths.AssemblyLine, {
            id: params.row.billedAssemblyLine.id,
          })}
          target="_blank"
        >
          {params.row.billedAssemblyLine.name}
        </StyledLink>
      </AssemblyCol>
    )
  }

  const assemblyStartDateCol = (params: GridCellParams) => {
    // convert unix ms to YYYY-MM-DD format
    return (
      <ColValue>
        {moment(params.row.billedAssemblyLine.startDate).format('YYYY-MM-DD')}
      </ColValue>
    )
  }

  const resourceIdCol = (params: GridCellParams) => {
    return (
      <StyledLink
        href={interpolateRouteUtil(RoutePaths.Resource, {
          id: params.row.resourceID,
        })}
        target="_blank"
      >
        {params.row.resourceID}
      </StyledLink>
    )
  }

  const resourceNameCol = (params: GridCellParams) => {
    return <ColValue>{params.row.resourceName}</ColValue>
  }

  const billableAmountCol = (params: GridCellParams) => {
    return <ColValue>$ {params.row.billableAmount}</ColValue>
  }

  const billableUnitsCol = (params: GridCellParams) => {
    return <ColValue>{params.row.billableUnits}</ColValue>
  }

  const statusCol = (params: GridCellParams) => {
    return <ColValue>{capitalize(params.row.status)}</ColValue>
  }

  const dateCol = (params: GridCellParams) => {
    const formattedDate = moment(params.row[params.field], 'YYYYMMDD').format(
      'MM-DD-YYYY'
    )
    return <ColValue>{formattedDate}</ColValue>
  }

  const detailCol = (params: GridCellParams) => {
    return (
      <DetailCol>{params.row.details ? params.row.details : '---'}</DetailCol>
    )
  }

  const statusMessageCol = (params: GridCellParams) => {
    return (
      <DetailCol>
        {params.row.statusMessage ? params.row.statusMessage : '---'}
      </DetailCol>
    )
  }

  const renderInvoiceLink = (params: GridCellParams) => {
    if (!params.row.invoiceLink) {
      return <ColValue>---</ColValue>
    }

    return (
      <InvoiceLink
        href={params.row.invoiceLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Invoice
      </InvoiceLink>
    )
  }

  const timestampCol = (params: GridCellParams) => {
    const formatted = moment(params.row.timestamp).format('MM-DD-YYYY')
    return <ColValue>{formatted}</ColValue>
  }

  const transferIDCol = (params: GridCellParams) => {
    return <ColValue>{params.row.transferID}</ColValue>
  }

  const handleClickSendPayment = (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setConfirmPaymentDialogOpen(true)
  }

  const sendPaymentCol = (params: GridCellParams) => {
    if (params.row.status !== 'CREATED') {
      return <ColValue>---</ColValue>
    }

    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickSendPayment(params.row)}
      >
        Send Payment
      </StyledButton>
    )
  }

  const handleClickUpdatePayment = (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setIsDialogOpen(true)
  }

  const updatePaymentCol = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickUpdatePayment(params.row)}
      >
        Update Payment
      </StyledButton>
    )
  }

  const reprocessPayoutEntriesCol = (params: GridCellParams) => {
    return (
        <StyledButton
            variant="contained"
            color="primary"
            size="small"
            loading={false}
            onClick={() => handleClickReprocessPayoutEntries(params.row)}
        >
          Reprocess Payout Entries
        </StyledButton>
    )
  }

  const reprocessPayoutInvoiceCol = (params: GridCellParams) => {
    return (
        <StyledButton
            variant="contained"
            color="primary"
            size="small"
            loading={false}
            onClick={() => handleClickReprocessPayoutInvoice(params.row)}
        >
          Reprocess Payout Invoice
        </StyledButton>
    )
  }

  const handleClickReprocessPayoutEntries = async (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setIsLoading(true)
    try {
      // change the attribute billedAssemblyLine to point to billedAssemblyLine.idbecause the API expects the assembly line id
      const invoicePayload = { ...invoice, billedAssemblyLine: invoice.billedAssemblyLine.id }
      const { data } = await reprocessPayoutEntries(invoicePayload)
      setLogsWindow(data?.message)
      addAlert({
        message: `Payout Entries Reprocessing Status : ${data.status}`,
        type: SnackbarType.Success,
      })
      setIsLoading(false)
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const handleClickReprocessPayoutInvoice = async (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setIsLoading(true)
    try {
      const invoicePayload = { ...invoice, billedAssemblyLine: invoice.billedAssemblyLine.id }
      const { data } = await reprocessPayoutInvoice(invoicePayload)
      setLogsWindow(data?.message)
      addAlert({
        message: `Payout Invoice Reprocessing Status : ${data.status}`,
        type: SnackbarType.Success,
      })
      setIsLoading(false)
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const dataGridColumns: GridColDef[] = [
    {
      field: 'sendPayment',
      headerName: 'Send Payment',
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: sendPaymentCol,
    },
    {
      field: 'updatePayment',
      headerName: 'Update Payment',
      width: 140,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: updatePaymentCol,
    },
    {
      field: 'reprocessPayoutEntries',
      headerName: 'Reprocess Payout Entries',
      width: 190,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: reprocessPayoutEntriesCol,
    },
    {
      field: 'reprocessPayoutInvoice',
      headerName: 'Reprocess Payout Invoice',
      width: 190,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: reprocessPayoutInvoiceCol,
    },
    {
      type: 'string',
      field: 'orgName',
      headerName: 'Org Name',
      width: 220,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: orgNameCol,
      valueGetter: (params) => {
        return params.row.orgName
      },
    },
    {
      type: 'string',
      field: 'billedAssemblyLine',
      headerName: 'Assembly Name',
      width: 220,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: assemblyCol,
      valueGetter: (params) => {
        return params.row.billedAssemblyLine.name
      },
    },
    {
      type: 'date',
      field: 'billedAssemblyLineStartDate',
      headerName: 'Assembly Start Date',
      width: 220,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: assemblyStartDateCol,
      valueGetter: (params) => {
        return moment(params.row.billedAssemblyLine.startDate).toDate()
      },
    },
    {
      type: 'string',
      field: 'resourceID',
      headerName: 'Resource',
      width: 175,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: resourceIdCol,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.resourceID
      },
    },
    {
      type: 'string',
      field: 'resourceName',
      headerName: 'Contractor Name',
      width: 175,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: resourceNameCol,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.resourceName
      },
    },
    {
      field: 'billableAmount',
      headerName: 'Billable Amount',
      width: 122,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableAmountCol,
    },
    {
      field: 'billableUnits',
      headerName: 'Billable Units',
      width: 105,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableUnitsCol,
    },
    {
      type: 'date',
      field: 'billingStart',
      headerName: 'Billing Start Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
      valueGetter: (params) => {
        return moment(params.row.billingStart, 'YYYYMMDD').toDate()
      },
    },
    {
      type: 'date',
      field: 'billingEnd',
      headerName: 'Billing End Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
      valueGetter: (params) => {
        return moment(params.row.billingEnd, 'YYYYMMDD').toDate()
      },
    },
    {
      type: 'string',
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusCol,
      valueGetter: (params) => {
        return params.row.status
      },
    },
    {
      field: 'statusMessage',
      headerName: 'Status Message',
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusMessageCol,
      width: 350,
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: detailCol,
    },
    {
      field: 'invoiceLink',
      headerName: 'Invoice Link',
      width: 350,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderInvoiceLink,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 100,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: timestampCol,
    },
    {
      field: 'transferID',
      headerName: 'Transfer ID',
      width: 240,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: transferIDCol,
    },
  ]

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setTimeout(() => {
      setSelectedInvoice(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleUpdateSuccess = (invoice: PayoutInvoice) => {
    const index = data.findIndex((item) => item.id === invoice.id)
    if (index !== -1) {
      const newData = [...data]
      newData[index] = invoice
      setData(newData)
    }
  }

  const handleCloseConfirmPaymentDialog = () => {
    setConfirmPaymentDialogOpen(false)
    setTimeout(() => {
      setSelectedInvoice(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { value } = event.target
    setStatus(value)
    getInvoicesAsync(value)
  }

  const handleClickClear = () => {
    setStatus('')
    getInvoicesAsync()
  }

  return (
    <Box>
      <FilterBox>
        <StyledFormControl size="small">
          <InputLabel id="status-select-label">Filter By Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={status}
            label="Filter By Status"
            name="status"
            onChange={handleChangeSelect}
          >
            {Object.entries(InvoiceStatusTypes).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {capitalize(key.replace(/_/g, ' '))}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {status && <Button onClick={handleClickClear}>Clear</Button>}
      </FilterBox>
      <DataGrid
        columns={dataGridColumns}
        rows={data}
        loading={isLoading}
        sx={{
          height: 'calc(100vh - 180px)',
          borderWidth: '0px',
        }}
        showCellVerticalBorder
        hideFooter
        rowSelection={false}
      />
      <Box sx={{ height: '100%', overflow: 'auto', minHeight: '200px' }}>
        <Typography variant="h6" sx={{ fontSize: '20px' }}>
          Logs
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '18px' }}>
          <ul>
            {logsWindow.split('\n').map((line, index) => (
                // check to make sure the line is not empty
              line.trim() !== '' ? (
                <li key={index}>{line}</li>
              ) : null
            ))}
          </ul>
        </Typography>
      </Box>
      <EditPayoutInvoiceDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        payoutInvoice={selectedInvoice}
        onSuccess={handleUpdateSuccess}
      />
      <SendPaymentDialog
        open={confirmPaymentDialogOpen}
        onClose={handleCloseConfirmPaymentDialog}
        payoutInvoice={selectedInvoice}
        onSuccess={handleUpdateSuccess}
      />
    </Box>
  )
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { PayoutInvoice } from './types'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { sendPayment } from '@assembly/api/resources'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import React from 'react'

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
}))

type SendPaymentDialogProps = {
  open: boolean
  onClose: () => void
  payoutInvoice: PayoutInvoice | null
  onSuccess: (payoutInvoice: PayoutInvoice) => void
}

export default function SendPaymentDialog({
  open,
  onClose,
  payoutInvoice,
  onSuccess,
}: SendPaymentDialogProps) {
  const { addAlert } = useSnackbars()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleClickSend = async () => {
    try {
      setLoading(true)
      const { data } = await sendPayment(payoutInvoice?.id as string)
      setLoading(false)
      if (!data.message) {
        onSuccess(data)
      }
      addAlert({
        message: data.message || 'Payment sent successfully',
        type: SnackbarType.Success,
      })
      onClose()
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <StyledText>
          Are you sure, you want to send the amount of{' '}
          <b>${payoutInvoice?.billableAmount}</b> to resource with id{' '}
          <b>{payoutInvoice?.resourceID}</b> ?
        </StyledText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickSend}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

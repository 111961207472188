import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import { InvoiceStatusTypes, PayoutInvoice } from './types'
import { capitalize } from 'lodash'
import { updatePayment } from '@assembly/api/resources'
import { useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Box from '@mui/material/Box'
import moment from 'moment-timezone'

const StyledTextField = styled(TextField)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const StyledFormControl = styled(FormControl)(({}) => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const StyledDatePicker = styled(DatePicker)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const Row = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '15px',
}))

type EditPayoutInvoiceDialogProps = {
  open: boolean
  onClose: () => void
  payoutInvoice: PayoutInvoice | null
  onSuccess: (payoutInvoice: PayoutInvoice) => void
}

export default function EditPayoutInvoiceDialog({
  open,
  onClose,
  payoutInvoice,
  onSuccess,
}: EditPayoutInvoiceDialogProps) {
  const initialFormValues = {
    transferID: '',
    status: '',
    message: '',
    billableAmount: '',
    billableUnits: '',
    billingStart: null,
    billingEnd: null,
    invalid_transferID: false,
    invalid_billingStart: false,
    invalid_billingEnd: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open && payoutInvoice) {
      setFormValues({
        ...formValues,
        transferID: payoutInvoice.transferID,
        status: payoutInvoice.status,
        message: payoutInvoice.statusMessage || '',
        billableAmount: payoutInvoice.billableAmount.toString(),
        billableUnits: payoutInvoice.billableUnits.toString(),
        billingStart: payoutInvoice.billingStart
          ? (moment(payoutInvoice.billingStart, 'YYYYMMDD') as any)
          : null,

        billingEnd: payoutInvoice.billingEnd
          ? (moment(payoutInvoice.billingEnd, 'YYYYMMDD') as any)
          : null,
      })
    }
  }, [open])

  const handleChangeFormField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value, [`invalid_${name}`]: false })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(initialFormValues)
    onClose()
  }

  const handleClickSave = async () => {
    if (!payoutInvoice) return

    const payload = new URLSearchParams()
    payload.append('transferID', formValues.transferID)
    payload.append('status', formValues.status)
    payload.append('message', formValues.message || '')
    payload.append('billableAmount', formValues.billableAmount)
    payload.append('billableUnits', formValues.billableUnits)
    if (formValues.billingStart) {
      payload.append(
        'billingStart',
        moment(formValues.billingStart).format('YYYYMMDD')
      )
    }

    if (formValues.billingEnd) {
      payload.append(
        'billingEnd',
        moment(formValues.billingEnd).format('YYYYMMDD')
      )
    }

    setIsLoading(true)
    try {
      const { data } = await updatePayment(payoutInvoice.id, payload)
      onSuccess(data)
      addAlert({
        message: `Payout Invoice (${data.id}) updated successfully`,
        type: SnackbarType.Success,
      })
      handleClose()
      setIsLoading(false)
    } catch (error: any) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const handleChangeStartDate = (date: any) => {
    setFormValues({
      ...formValues,
      billingStart: date,
      invalid_billingStart: false,
    })
  }

  const handleChangeEndDate = (date: any) => {
    setFormValues({
      ...formValues,
      billingEnd: date,
      invalid_billingEnd: false,
    })
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update Payout Invoice</DialogTitle>
      <DialogContent dividers>
        <Row>
          <StyledDatePicker
            label="Billing Start Date"
            value={formValues.billingStart}
            onChange={handleChangeStartDate}
            slotProps={{
              textField: {
                helperText: formValues.invalid_billingStart ? 'Required' : '',
                error: formValues.invalid_billingStart,
              },
            }}
            maxDate={formValues.billingEnd}
          />
          <StyledDatePicker
            label="Billing End Date"
            value={formValues.billingEnd}
            onChange={handleChangeEndDate}
            slotProps={{
              textField: {
                helperText: formValues.invalid_billingEnd ? 'Required' : '',
                error: formValues.invalid_billingEnd,
              },
            }}
            minDate={formValues.billingStart}
          />
        </Row>
        <Row>
          <StyledTextField
            value={formValues.billableAmount}
            onChange={handleChangeFormField}
            name="billableAmount"
            label="Billable Amount"
            type="number"
          />
          <StyledTextField
            value={formValues.billableUnits}
            onChange={handleChangeFormField}
            name="billableUnits"
            label="Billable Units"
            type="number"
          />
        </Row>
        <StyledTextField
          value={formValues.transferID}
          onChange={handleChangeFormField}
          label="Transfer ID"
          name="transferID"
          error={formValues.invalid_transferID}
          helperText={
            formValues.invalid_transferID ? 'Transfer ID is required' : ''
          }
        />
        <StyledFormControl>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={formValues.status}
            label="Status"
            name="status"
            onChange={handleChangeSelect}
          >
            {Object.entries(InvoiceStatusTypes).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {capitalize(key)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledTextField
          value={formValues.message}
          onChange={handleChangeFormField}
          label="Message"
          name="message"
          multiline
          rows={2}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

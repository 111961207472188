import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import CreateAssemblyTemplateDialog from './CreateAssemblyTemplateDialog'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import {
  deleteAssemblyTemplate,
  getAllAssemblyTemplates,
} from '@assembly/api/assemblyTemplate'
import { SnackbarType } from '@assembly/contexts'
import AssemblyTemplatesList from './AssemplyTemplatesList'
import { AssemblyTemplate } from './types'

const AssemblyTemplates: React.FC = () => {
  const [
    createAssemblyTemplatesDialogIsVisible,
    setCreateAssemblyTemplatesDialogIsVisible,
  ] = React.useState<boolean>(false)
  const selectedAssemblyTemplateIntialState: {
    assemblyTemplate?: AssemblyTemplate
    index: number
  } = {
    assemblyTemplate: undefined,
    index: -1,
  }
  const [selectedAssemblyTemplate, setSelectedAssemblyTemplate] =
    React.useState(selectedAssemblyTemplateIntialState)
  const { store, setAssemblyTemplates } = useAppStore()
  const { addAlert } = useSnackbars()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const [searchResults, setSearchResults] = React.useState<AssemblyTemplate[]>()
  const didMount = React.useRef(false)

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isAssemblyTemplatesLoaded) {
        getAssemblyTemplatesAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const getAssemblyTemplatesAsync = async () => {
    try {
      setIsLoading(true)
      const payload = {
        search: '',
      }
      const { data } = await getAllAssemblyTemplates(payload)
      setAssemblyTemplates(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      addAlert({
        message: 'Error',
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const handleCloseAssemblyTemplatesDialog = () => {
    setCreateAssemblyTemplatesDialogIsVisible(false)
    setSelectedAssemblyTemplate(selectedAssemblyTemplateIntialState)
  }

  const handleClickCreateAssemblyTemplates = () => {
    setCreateAssemblyTemplatesDialogIsVisible(true)
  }

  const onAssemblyTemplateCreated = (value: AssemblyTemplate) => {
    store.assemblyTemplates.unshift(value)
    setAssemblyTemplates([...store.assemblyTemplates])
  }

  const onAssemblyTemplateUpdated = (value: AssemblyTemplate) => {
    store.assemblyTemplates[selectedAssemblyTemplate.index] = value
    setAssemblyTemplates([...store.assemblyTemplates])
  }

  const handleClickEditAssemblyTemplates = (
    assemblyTemplate: AssemblyTemplate,
    index: number
  ) => {
    setSelectedAssemblyTemplate({
      assemblyTemplate,
      index,
    })
    setCreateAssemblyTemplatesDialogIsVisible(true)
  }

  const handleClickDeleteAssemblyTemplates = (
    assemblyTemplate: AssemblyTemplate,
    index: number
  ) => {
    setSelectedAssemblyTemplate({
      assemblyTemplate,
      index,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedAssemblyTemplate(selectedAssemblyTemplateIntialState)
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const removeFromList = () => {
    if (selectedAssemblyTemplate.index === -1) {
      return
    }

    store.assemblyTemplates.splice(selectedAssemblyTemplate.index, 1)
    setAssemblyTemplates([...store.assemblyTemplates])
  }

  const deleteAsync = async () => {
    if (!selectedAssemblyTemplate.assemblyTemplate) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteAssemblyTemplate(selectedAssemblyTemplate.assemblyTemplate.id)
      setIsDeleting(false)
      setSelectedAssemblyTemplate(selectedAssemblyTemplateIntialState)
      setDeleteInputValue('')
      removeFromList()
      addAlert({
        message: 'Assembly Template Deleted!',
        type: SnackbarType.Success,
      })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleChangeSearch = React.useCallback(
    (value: string) => {
      if (value) {
        const filtered = store.assemblyTemplates.filter(
          (assemblyTemplate) =>
            assemblyTemplate.name.toLowerCase().indexOf(value.toLowerCase()) >
            -1
        )
        setSearchResults(filtered)
      } else {
        setSearchResults(undefined)
      }
    },
    [store.assemblyTemplates]
  )

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        inputPlaceholder="Search Assembly Templates"
        buttonLabel="Create Assembly Template"
        onClickButton={handleClickCreateAssemblyTemplates}
        onChangeSearch={handleChangeSearch}
      />
      <AssemblyTemplatesList
        assemblyTemplates={searchResults || store.assemblyTemplates}
        onClickDelete={handleClickDeleteAssemblyTemplates}
        onClickEdit={handleClickEditAssemblyTemplates}
      />
      <CreateAssemblyTemplateDialog
        open={createAssemblyTemplatesDialogIsVisible}
        onClose={handleCloseAssemblyTemplatesDialog}
        assemblyTemplate={selectedAssemblyTemplate.assemblyTemplate}
        onSuccess={
          selectedAssemblyTemplate.assemblyTemplate
            ? onAssemblyTemplateUpdated
            : onAssemblyTemplateCreated
        }
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedAssemblyTemplate.assemblyTemplate?.name || ''}
        onClickDelete={deleteAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

export default AssemblyTemplates

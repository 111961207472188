import {
  deleteSkillAssessment,
  getSkillsAssessment,
} from '@assembly/api/skillsAssessment'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import SkillAssessmentList from './SkillAssessmentList'
import { SkillAssessment } from './types'
import CreateSkillAssessmentDialog from './SkillAssessmentCreateDialog'
import { SnackbarType } from '@assembly/contexts'
import { debounce } from 'lodash'
import { getApiErrorMsg } from '@assembly/utils'

export default function SkillsAssessment() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const didMountRef = React.useRef<boolean>(false)
  const { store, setSkillsAssessments } = useAppStore()
  const [searchResults, setSearchResults] = React.useState<SkillAssessment[]>()
  const [isSkillAssessmentDialogOpen, setIsSkillAssessmentDialogOpen] =
    React.useState<boolean>(false)
  const [selectedSkillAssessment, setSelectedSkillAssessment] = React.useState<{
    skillAssessment: SkillAssessment | undefined
    index: number
  }>({
    skillAssessment: undefined,
    index: -1,
  })
  const [isSearching, setIsSearching] = React.useState<boolean>(false)
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isSkillsAssessmentsLoaded) {
        getSkillsAssessmentsAsync()
      } else {
        setIsLoading(false)
      }
      didMountRef.current = true
    }
  }, [])

  const getSkillsAssessmentsAsync = async () => {
    try {
      setIsLoading(true)
      const payload = {
        search: '',
      }
      const { data } = await getSkillsAssessment(payload)
      setSkillsAssessments(data)
      setIsLoading(false)
    } catch (error: any) {
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
      setIsLoading(false)
    }
  }

  const handleSearchSkillAssessments = async (search: string) => {
    try {
      setIsSearching(true)
      const payload = {
        search,
      }
      const { data } = await getSkillsAssessment(payload)
      setSearchResults(data)
      setIsSearching(false)
    } catch (error: any) {
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
      setIsSearching(false)
    }
  }

  const handleChangeSearch = debounce((value: string) => {
    if (value === '') {
      setSearchResults(undefined)
      return
    }

    handleSearchSkillAssessments(value)
  }, 500)

  const handleCloseSkillAssessmentDialog = () => {
    setIsSkillAssessmentDialogOpen(false)
    setSelectedSkillAssessment({
      skillAssessment: undefined,
      index: -1,
    })
  }

  const handleClickCreate = () => {
    setIsSkillAssessmentDialogOpen(true)
  }

  const onEdit = (skillAssessment: SkillAssessment) => {
    if (searchResults) {
      const skillAssessments = [...searchResults]
      skillAssessments[selectedSkillAssessment.index] = skillAssessment
      setSearchResults(skillAssessments)

      const indexInStore = store.skillsAssessments.findIndex(
        (s) => s.id === skillAssessment.id
      )
      if (indexInStore > -1) {
        const skillAssessments = [...store.skillsAssessments]
        skillAssessments[indexInStore] = skillAssessment
        setSkillsAssessments(skillAssessments)
      }
      return
    }

    const skillAssessments = [...store.skillsAssessments]
    skillAssessments[selectedSkillAssessment.index] = skillAssessment
    setSkillsAssessments(skillAssessments)
  }

  const onCreate = (skillAssessment: SkillAssessment) => {
    if (searchResults) {
      setSearchResults([skillAssessment, ...searchResults])
    }

    setSkillsAssessments([skillAssessment, ...store.skillsAssessments])
  }

  const handleClickEdit = (skillAssessment: SkillAssessment, index: number) => {
    setSelectedSkillAssessment({
      skillAssessment,
      index,
    })
    setIsSkillAssessmentDialogOpen(true)
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedSkillAssessment({ skillAssessment: undefined, index: -1 })
  }

  const removeFromList = () => {
    if (selectedSkillAssessment.index === -1) {
      return
    }

    if (searchResults) {
      const skillAssessments = [...searchResults]
      skillAssessments.splice(selectedSkillAssessment.index, 1)
      setSearchResults(skillAssessments)

      const indexInStore = store.skillsAssessments.findIndex(
        (s) => s.id === selectedSkillAssessment?.skillAssessment?.id
      )
      if (indexInStore > -1) {
        const skillAssessment = [...store.skillsAssessments]
        skillAssessment.splice(indexInStore, 1)
        setSkillsAssessments(skillAssessment)
      }

      return
    }

    store.skillsAssessments.splice(selectedSkillAssessment.index, 1)
    setSkillsAssessments([...store.skillsAssessments])
  }

  const deleteAsync = async () => {
    if (!selectedSkillAssessment.skillAssessment) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteSkillAssessment(selectedSkillAssessment.skillAssessment.id)
      setIsDeleting(false)
      setSelectedSkillAssessment({ skillAssessment: undefined, index: -1 })
      setDeleteInputValue('')
      removeFromList()
      addAlert({
        message: 'Skill Assessment Deleted!',
        type: SnackbarType.Success,
      })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  const handleClickDelete = (
    skillAssessment: SkillAssessment,
    index: number
  ) => {
    setSelectedSkillAssessment({
      skillAssessment,
      index,
    })
    setConfirmDeleteDialogVisible(true)
  }

  return (
    <Box>
      <SearchInputAndButton
        onChangeSearch={handleChangeSearch}
        inputPlaceholder="Search"
        buttonLabel="Create Skill Assessment"
        onClickButton={handleClickCreate}
        isSearching={isSearching}
      />
      {searchResults && searchResults.length === 0 && (
        <Box marginTop={6}>
          <Typography>No results found.</Typography>
        </Box>
      )}
      <SkillAssessmentList
        skillsAssessment={searchResults || store.skillsAssessments}
        onClickDelete={handleClickDelete}
        onClickEdit={handleClickEdit}
      />
      <CreateSkillAssessmentDialog
        open={isSkillAssessmentDialogOpen}
        onClose={handleCloseSkillAssessmentDialog}
        onSuccess={selectedSkillAssessment.skillAssessment ? onEdit : onCreate}
        skillAssessment={selectedSkillAssessment.skillAssessment}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedSkillAssessment.skillAssessment?.title || ''}
        onClickDelete={deleteAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

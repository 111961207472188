import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'

export default function LinkButton({
  onClick,
  label,
  href,
  target,
  loading,
  variant,
}: {
  onClick?: any
  label: string
  href?: string
  target?: string
  loading?: boolean
  variant?: 'contained' | 'outlined'
}) {
  const renderInner = () => {
    return (
      <>
        <span>{label}</span>
        <ArrowForwardIcon sx={{ marginLeft: '5px', fontSize: '20px' }} />
      </>
    )
  }

  if (href) {
    return (
      <LoadingButton
        loading={loading}
        variant={variant || 'outlined'}
        href={href}
        target={target}
      >
        {renderInner()}
      </LoadingButton>
    )
  }

  return (
    <LoadingButton
      loading={loading}
      variant={variant || 'outlined'}
      onClick={onClick}
    >
      {renderInner()}
    </LoadingButton>
  )
}

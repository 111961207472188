import { axiosWithConfig } from '@assembly/config/axios'
import { ICreateTeam } from '@assembly/pages/Teams/types'

export const createTeam = (payload: ICreateTeam) => {
  return axiosWithConfig.post(`/admin/team`, payload)
}

export const updateTeam = (payload: ICreateTeam) => {
  return axiosWithConfig.put(`/admin/team`, payload)
}

export const deleteTeam = (id: string) => {
  return axiosWithConfig.delete(`/admin/team/${id}`)
}

export const getTeam = (id: string) => {
  return axiosWithConfig.get(`/admin/team/${id}`)
}

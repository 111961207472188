import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import { Candidate } from './types'
import CloseIcon from '@mui/icons-material/Close'
import { Document, Page, pdfjs } from 'react-pdf'
import React from 'react'
import { getMediaUrl } from '@assembly/utils'
import 'react-pdf/dist/Page/TextLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type ResumeDialogProps = {
  open: boolean
  onClose: () => void
  candidate: Candidate | null
}

export default function ResumeDialog({
  open,
  onClose,
  candidate,
}: ResumeDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        Resume ({candidate?.firstName} {candidate?.lastName})
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <PDFViewer pdfURL={getMediaUrl(candidate?.resume?.media as string)} />
      </DialogContent>
    </Dialog>
  )
}

function PDFViewer(props: any) {
  const [numPages, setNumPages] = React.useState<number>()

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }

  return (
    <Document file={props.pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <Box>
          <Page
            renderAnnotationLayer={false}
            width={850}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
          />
          <Divider />
        </Box>
      ))}
    </Document>
  )
}

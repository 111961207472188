export enum RoutePaths {
  Root = '/',
  OrganizationManager = '/orgs',
  Organization = '/orgs/:id',
  Teams = '/orgs/:id/teams',
  Team = '/orgs/:orgId/teams/:teamId',
  TeamMember = '/orgs/:orgId/teams/:teamId/teamMembers/:teamMemberId',
  Resources = '/resources',
  Resource = '/resources/:id',
  ResourcePayoutEntries = '/resources/:id/payout-entries',
  ResourcePayoutInvoices = '/resources/:id/payout-invoices',
  TalentInvoices = '/talent-invoices',
  Capabilities = '/capabilities',
  AssemblyTemplates = '/assembly-templates',
  AssemblyTemplate = '/assembly-templates/:id',
  AssemblyLines = '/assembly-lines',
  AssemblyLine = '/assembly-lines/:id',
  Login = '/login',
  Debug = '/debug',
  Skills = '/skills',
  SkillsAssessment = '/assessment',
  Candidates = '/candidates',
  Jobs = '/jobs',
}

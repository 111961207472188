import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { Candidate } from './types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment-timezone'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

const SectionLabel = styled(Typography)(() => ({
  fontSize: '20px',
  marginBottom: '10px',
  fontWeight: 500,
  marginTop: '20px',
}))

const QuestionAndAnswerBox = styled(Box)(() => ({
  marginBottom: '10px',
}))

const Question = styled(Typography)(() => ({
  marginBottom: '5px',
}))

type EditCandidateDialogProps = {
  open: boolean
  onClose: () => void
  candidate: Candidate | null
}

export default function ApplicationsDialog({
  open,
  onClose,
  candidate,
}: EditCandidateDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Applications ({candidate?.firstName} {candidate?.lastName})
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {candidate?.applicationMetaData.map((application, index) => (
          <Accordion defaultExpanded key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">{application.jobTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <b>Apply Date:</b>{' '}
                {moment(application.applyDate).format('DD/MM/YYYY')}
              </Typography>
              <Typography>
                <b>External Job Board Candidate Link:</b>{' '}
                <a
                  href={application.externalJobBoardCandidateLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </Typography>
              <Typography>
                <b>External Job Board Id :</b> {application.externalJobBoardId}
              </Typography>
              <SectionLabel>Questionaire</SectionLabel>
              {Object.entries(application.questionnaire).map(
                ([key, value], index) => (
                  <QuestionAndAnswerBox key={index}>
                    <Question>
                      <b>
                        {index + 1}. {key}
                      </b>
                    </Question>
                    <Typography>
                      <b>Answer:</b> {`${value}`}
                    </Typography>
                  </QuestionAndAnswerBox>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
    </Dialog>
  )
}

import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  Alert,
  colors,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import {
  CapabilitiesSearchAndSelect,
  CircularProgressWithLabel,
  OrganizationSearchAndSelect,
  ResourceSearchAndSelect,
} from '@assembly/components'
import { Capability } from '../Capabilities/types'
import {
  Allocation,
  CreateAllocationForm,
  Frequency,
  RateUnit,
  Status,
  Type,
} from '@assembly/pages/AssemblyTemplates/types'
import { capitalize } from 'lodash'
import {
  Resource,
  Status as ResourceStatus,
} from '@assembly/pages/Resources/types'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { getApiErrorMsg, getMediaUrl, stringAvatar } from '@assembly/utils'
import {
  AssemblyLine,
  AssemblyLineForm,
  AssemblyLineStatus,
  ProjectTypes,
} from './types'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { defaultCurrency, defaultTimezone } from '@assembly/constants'
import { Organization } from '../OrganizationManager/types'
import moment from 'moment-timezone'
import {
  createAssemblyLine,
  estimateStartDate,
  updateAssemblyLine,
} from '@assembly/api/assemblyLine'
import OrgMembersSearchAndSelect from '@assembly/components/OrganizationMembersSearchAndSelect'
import { TeamMember } from '../Teams/types'
import { timezones } from '@assembly/shared/timezones'
import OwnerSearchAndSelect from '@assembly/components/OwnerSearchAndSelect'
import { currencySymbols } from '@assembly/shared/types/countriesAndCurrencies'
import { styled } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Media } from '@assembly/shared/types/media'
import { uploadMedia } from '@assembly/api/resources'

const EstimatedStartDateButton = styled(LoadingButton)(({ theme }) => ({
  height: '57px',
  minWidth: '40%',
}))

const EstimateAlert = styled(Alert)(() => ({
  marginTop: '10px',
  marginBottom: '25px',
}))

const SetupFeildsBox = styled(Box)(() => ({
  border: '2px solid',
  padding: '15px',
  borderColor: colors.grey[400],
  marginTop: '10px',
  borderRadius: '5px',
}))

const MainSectionLabel = styled(Typography)(() => ({
  fontSize: '22px',
  fontWeight: 500,
  marginBottom: '20px',
}))

interface CreateAssemblyLineDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateAssemblyLineDialogTitle(
  props: CreateAssemblyLineDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateAssemblyLineDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (assemblyLine: AssemblyLine) => void
  assemblyLine?: AssemblyLine
}

export default function CreateAssemblyLineDialog(
  props: CreateAssemblyLineDialogProps
) {
  const { open, onClose, assemblyLine, onSuccess } = props
  const formInitialValues: AssemblyLineForm = {
    name: '',
    description: '',
    capabilities: [],
    allocations: [],
    organization: null,
    owner: null,
    invalid_owner: false,
    monthlySubscriptionCost: null,
    startDate: null,
    endDate: null,
    members: [],
    invalid_members: false,
    timezone: defaultTimezone,
    projectType: ProjectTypes.Recurring,
    approved: false,
    approvedBy: '',
    status: AssemblyLineStatus.Initialized,
    statusMessage: '',
    projectManagementLink: '',
    submitTaskLink: '',
    kanbanLink: '',
    teamID: '',
    projectID: '',
    funding: {
      billingAmount: null,
      currency: defaultCurrency,
      frequency: null,
      startDate: null,
      endDate: null,
      lastSuccessfulPayment: null,
      status: null,
      statusMsg: '',
      setupLink: '',
      paymentLink: '',
      apContacts: [],
      invalid_billingAmount: false,
      invalid_frequency: false,
      invalid_startDate: false,
      invalid_endDate: false,
      invalid_status: false,
    },
    requirements: {},
    invalid_startDate: false,
    invalid_endDate: false,
    invalid_name: false,
    invalid_capabilities: false,
    invalid_allocations: false,
    invalid_description: false,
    invalid_monthlySubscriptionCost: false,
    invalid_organization: false,
    invalid_projectType: false,
    invalid_status: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isAllocationFormVisible, setIsAllocationFormVisible] =
    React.useState<boolean>(false)
  const allocationFormInitialValues: CreateAllocationForm = {
    resourceID: '',
    amount: null,
    rate: 160,
    rateUnit: RateUnit.Hours,
    frequency: Frequency.Monthly,
    status: Status.Initialized,
    statusMessage: '',
    approved: false,
    approvedBy: '',
    active: false,
    startDate: '',
    startDateAccepted: false,
    type: Type.Contractor,
    invalid_resourceID: false,
    invalid_amount: false,
    invalid_rate: false,
    invalid_rateUnit: false,
    invalid_frequency: false,
    invalid_status: false,
    invalid_type: false,
  }
  const [allocationFormValues, setAllocationFormValues] =
    React.useState<CreateAllocationForm>(allocationFormInitialValues)
  const { addAlert } = useSnackbars()
  const [resource, setResource] = React.useState<Resource | null>(null)
  const [selectedAllocation, setSelectedAllocation] = React.useState<{
    allocation?: Allocation
    index: number
  }>({
    index: -1,
  })
  const [checkListItems, setCheckListItems] = React.useState({})
  const [estimatedDateTextVisible, setEstimatedDateTextVisible] =
    React.useState<boolean>(false)
  const [esitmatedMsg, setEstimatedMsg] = React.useState<string>('')
  const [estimatedStartDateLoading, setEstimatedStartDateLoading] =
    React.useState<boolean>(false)
  const [savedAssemblyLine, setSavedAssemblyLine] =
    React.useState<AssemblyLine | null>(assemblyLine as AssemblyLine)
  const [allocationsChanged, setAllocationsChanged] =
    React.useState<boolean>(false)
  const [isRequirementsUploading, setIsRequirementsUploading] =
    React.useState<boolean>(false)

  const getCheckListItemLabel = (str: string) => {
    return str.split(' ').join('')
  }

  React.useEffect(() => {
    if (open) {
      if (assemblyLine) {
        setFormValues({
          ...formValues,
          ...assemblyLine,
          owner: assemblyLine.owner as TeamMember,
          startDate:
            assemblyLine.startDate && assemblyLine.startDate !== -1
              ? moment(assemblyLine.startDate)
              : null,
          endDate:
            assemblyLine.endDate && assemblyLine.startDate !== -1
              ? moment(assemblyLine.endDate)
              : null,
          organization: assemblyLine.org || null,
          projectManagementLink:
            assemblyLine.projectManagement.projectManagementLink,
          submitTaskLink: assemblyLine.projectManagement.submitTaskLink,
          kanbanLink: assemblyLine.projectManagement.kanbanLink,
          teamID: assemblyLine.projectManagement.teamID,
          projectID: assemblyLine.projectManagement.projectID,
          projectType: assemblyLine.projectType || ProjectTypes.Recurring,
          funding: {
            ...formValues.funding,
            ...assemblyLine.funding,
            startDate: assemblyLine.funding.startDate
              ? moment(Number(assemblyLine.funding.startDate))
              : null,
            endDate: assemblyLine.funding.endDate
              ? moment(Number(assemblyLine.funding.endDate))
              : null,
          },
          requirements: assemblyLine.requirements || {},
        })

        if (
          assemblyLine.status &&
          assemblyLine.checklists[assemblyLine.status]
        ) {
          const items = assemblyLine.checklists[assemblyLine.status]

          items.forEach((item: any) => {
            setCheckListItems((prev) => ({
              ...prev,
              [getCheckListItemLabel(item.title)]: {
                ...item,
                key: getCheckListItemLabel(item.title),
              },
            }))
          })
        }
      }
      setSavedAssemblyLine(assemblyLine as AssemblyLine)
    }
  }, [assemblyLine, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type } = event.target
    setFormValues({
      ...formValues,
      [name]: type === 'number' ? value || null : value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeFundingFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type } = event.target
    setFormValues({
      ...formValues,
      funding: {
        ...formValues.funding,
        [name]: type === 'number' ? value || null : value,
        [`invalid_${name}`]: false,
      },
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.organization === null ||
      formValues.name.length === 0 ||
      formValues.description.length === 0 ||
      formValues.monthlySubscriptionCost === null ||
      formValues.capabilities.length === 0 ||
      formValues.projectType === null ||
      formValues.projectType.length === 0 ||
      formValues.members.length === 0 ||
      formValues.owner === null
    ) {
      setFormValues({
        ...formValues,
        invalid_organization: formValues.organization === null,
        invalid_name: formValues.name.length === 0,
        invalid_description: formValues.description.length === 0,
        invalid_monthlySubscriptionCost:
          formValues.monthlySubscriptionCost === null,
        invalid_capabilities: formValues.capabilities.length === 0,
        invalid_projectType:
          formValues.projectType === null ||
          formValues.projectType.length === 0,
        invalid_members: formValues.members.length === 0,
        invalid_owner: formValues.owner === null,
      })
      addAlert({
        message: 'Please fill in all required fields',
        type: SnackbarType.Error,
      })
      return
    }
    setIsSaving(true)
    const payload = {
      orgID: formValues.organization?.id,
      name: formValues.name,
      description: formValues.description,
      monthlySubscriptionCost: Number(formValues.monthlySubscriptionCost),
      capabilities: formValues.capabilities.map((c) => c.id),
      allocations: formValues.allocations.map((allocation) => ({
        resourceID: allocation.resource?.id,
        amount: Number(allocation.amount),
        rate: Number(allocation.rate),
        rateUnit: allocation.rateUnit,
        frequency: allocation.frequency,
        status: allocation.status,
        statusMessage: allocation.statusMessage,
        approved: allocation.approved,
        approvedBy: allocation.approvedBy,
        active: allocation.active,
        type: allocation.type,
        startDate: allocation.startDate,
        startDateAccepted: allocation.startDateAccepted,
      })),
      startDate: moment(formValues.startDate).valueOf(),
      endDate: moment(formValues.endDate).valueOf(),
      timezone: formValues.timezone,
      funding: {
        billingAmount: formValues.funding.billingAmount,
        currency: formValues.funding.currency,
        frequency: formValues.funding.frequency,
        startDate: formValues.funding.startDate
          ? moment(formValues.funding.startDate).valueOf()
          : null,
        endDate: formValues.funding.endDate
          ? moment(formValues.funding.endDate).valueOf()
          : null,
        lastSuccessfulPayment: formValues.funding.lastSuccessfulPayment,
        status: formValues.funding.status,
        statusMsg: formValues.funding.statusMsg,
        setupLink: formValues.funding.setupLink,
        paymentLink: formValues.funding.paymentLink,
        apContacts: formValues.funding.apContacts,
      },
      projectManagement: {
        projectManagementLink: formValues.projectManagementLink,
        submitTaskLink: formValues.submitTaskLink,
        kanbanLink: formValues.kanbanLink,
        teamID: formValues.teamID,
        projectID: formValues.projectID,
      },
      approved: formValues.approved,
      approvedBy: formValues.approvedBy,
      projectType: formValues.projectType,
      status: formValues.status,
      statusMessage: formValues.statusMessage,
      members: formValues.members.map((m) => m.id),
      owner: formValues.owner?.id,
      requirements: formValues.requirements,
    }

    if (savedAssemblyLine) {
      const checklists = {
        ...savedAssemblyLine.checklists,
      }

      if (savedAssemblyLine.status && savedAssemblyLine.status !== null) {
        checklists[savedAssemblyLine.status] = Object.values(
          checkListItems
        ).map((item: any) => ({
          title: item.title,
          description: item.description,
          done: item.done,
        }))
      }

      try {
        const { data } = await updateAssemblyLine({
          id: savedAssemblyLine.id,
          ...payload,
          checklists,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Assembly Line Updated!',
          type: SnackbarType.Success,
        })
        setSavedAssemblyLine(data)
        if (
          data.projectManagement &&
          Object.keys(data.projectManagement).length > 0
        ) {
          setFormValues({
            ...formValues,
            projectManagementLink:
              data.projectManagement.projectManagementLink || '',
            submitTaskLink: data.projectManagement.submitTaskLink || '',
            kanbanLink: data.projectManagement.kanbanLink || '',
            projectID: data.projectManagement.projectID || '',
            teamID: data.projectManagement.teamID || '',
          })
        }
        setAllocationsChanged(false)
      } catch (error: any) {
        setIsSaving(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createAssemblyLine(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Assembly Line Created!',
          type: SnackbarType.Success,
        })
        setSavedAssemblyLine(data)
        if (
          data.projectManagement &&
          Object.keys(data.projectManagement).length > 0
        ) {
          setFormValues({
            ...formValues,
            projectManagementLink:
              data.projectManagement.projectManagementLink || '',
            submitTaskLink: data.projectManagement.submitTaskLink || '',
            kanbanLink: data.projectManagement.kanbanLink || '',
            projectID: data.projectManagement.projectID || '',
            teamID: data.projectManagement.teamID || '',
          })
        }
        setAllocationsChanged(false)
      } catch (error: any) {
        setIsSaving(false)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleChangeCapabilities = (capabilities: Capability[]) => {
    setFormValues({
      ...formValues,
      capabilities,
      invalid_capabilities: false,
    })
  }

  const handleChangeAllocationFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type } = event.target
    setAllocationFormValues({
      ...allocationFormValues,
      [name]: type === 'number' ? value || null : value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeAllocationFormSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setAllocationFormValues({
      ...allocationFormValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setAllocationFormValues(allocationFormInitialValues)
    setIsAllocationFormVisible(false)
    setResource(null)
    setIsSaving(false)
    setCheckListItems({})
    setEstimatedDateTextVisible(false)
    setSavedAssemblyLine(null)
    onClose()
  }

  const handleSaveAllocation = () => {
    if (
      !resource ||
      allocationFormValues.amount === null ||
      allocationFormValues.rate === null ||
      allocationFormValues.frequency.length === 0 ||
      allocationFormValues.status.length === 0 ||
      allocationFormValues.type.length === 0 ||
      allocationFormValues.rateUnit.length === 0
    ) {
      setAllocationFormValues({
        ...allocationFormValues,
        invalid_resourceID: !resource,
        invalid_amount: allocationFormValues.amount === null,
        invalid_rate: allocationFormValues.rate === null,
        invalid_frequency: allocationFormValues.frequency.length === 0,
        invalid_status: allocationFormValues.status.length === 0,
        invalid_type: allocationFormValues.type.length === 0,
        invalid_rateUnit: allocationFormValues.rateUnit.length === 0,
      })
      return
    }

    const allocation = {
      resourceID: resource.id,
      resource: resource,
      amount: allocationFormValues.amount,
      rate: allocationFormValues.rate,
      rateUnit: allocationFormValues.rateUnit as RateUnit,
      frequency: allocationFormValues.frequency as Frequency,
      status: allocationFormValues.status as Status,
      statusMessage: allocationFormValues.statusMessage,
      type: allocationFormValues.type as Type,
      active: allocationFormValues.active,
      approved: allocationFormValues.approved,
      approvedBy: allocationFormValues.approvedBy,
      startDate: allocationFormValues.startDate,
      startDateAccepted: allocationFormValues.startDateAccepted,
    }

    if (selectedAllocation.allocation && selectedAllocation.index !== -1) {
      formValues.allocations[selectedAllocation.index] = allocation
    } else {
      formValues.allocations.push(allocation)
    }

    setFormValues({
      ...formValues,
      allocations: [...formValues.allocations],
    })
    setAllocationFormValues(allocationFormInitialValues)
    setResource(null)
    setIsAllocationFormVisible(false)
    setAllocationsChanged(true)
  }

  const handleChangeResource = (resource: Resource) => {
    setResource(resource)
    setAllocationFormValues({
      ...allocationFormValues,
      invalid_resourceID: false,
    })
  }

  const handleClickEditAllocation = (allocation: Allocation, index: number) => {
    setAllocationFormValues({
      ...allocationFormValues,
      ...allocation,
      status: allocation.status || Status.Initialized,
    })
    setSelectedAllocation({
      allocation,
      index,
    })
    setResource(allocation.resource || null)

    setIsAllocationFormVisible(true)
  }

  const handleClickDeleteAllocation = (index: number) => {
    formValues.allocations.splice(index, 1)
    setFormValues({
      ...formValues,
      allocations: [...formValues.allocations],
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeOrganization = (organization: Organization) => {
    setFormValues({
      ...formValues,
      organization,
      members: [],
      owner: null,
      invalid_organization: false,
    })
  }

  const handleChangeStartDate = (date: any) => {
    let endDateInvalid = false

    if (formValues.endDate) {
      endDateInvalid = moment(formValues.endDate).isBefore(date)
    }

    setFormValues({
      ...formValues,
      startDate: date,
      invalid_startDate: false,
      invalid_endDate: endDateInvalid,
    })
  }

  const handleChangeEndDate = (date: any) => {
    setFormValues({
      ...formValues,
      endDate: date,
      invalid_endDate: false,
    })
  }

  const handleChangeFundingStartDate = (date: any) => {
    let endDateInvalid = false

    if (formValues.endDate) {
      endDateInvalid = moment(formValues.funding.endDate).isBefore(date)
    }

    setFormValues({
      ...formValues,
      funding: {
        ...formValues.funding,
        startDate: date,
        invalid_startDate: false,
        invalid_endDate: endDateInvalid,
      },
    })
  }

  const handleChangeFundingEndDate = (date: any) => {
    setFormValues({
      ...formValues,
      funding: {
        ...formValues.funding,
        endDate: date,
        invalid_endDate: false,
      },
    })
  }

  const handleChangeFundingFormSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      funding: {
        ...formValues.funding,
        [name]: value,
        [`invalid_${name}`]: false,
      },
    })
  }

  const handleChangeMembers = (teamMembers: TeamMember[]) => {
    setFormValues({
      ...formValues,
      members: teamMembers,
      invalid_members: false,
    })
  }

  const handleChangeOwner = (teamMember: TeamMember) => {
    setFormValues({
      ...formValues,
      owner: teamMember,
      invalid_owner: false,
    })
  }

  const handleClickEstimateStartDate = async () => {
    try {
      setEstimatedStartDateLoading(true)
      const { data } = await estimateStartDate(
        assemblyLine?.id || savedAssemblyLine?.id || ''
      )
      if (data.message) {
        setEstimatedMsg(data.message)
        setEstimatedDateTextVisible(true)
      }
      if (data.proposedStartDate) {
        setFormValues({
          ...formValues,
          startDate: moment(data.proposedStartDate),
        })
      }
      setEstimatedStartDateLoading(false)
      setAllocationsChanged(false)
    } catch (error) {
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setEstimatedStartDateLoading(false)
    }
  }

  const RequirementsUploaderBox = styled(Box)(() => ({
    display: 'flex',
    gap: '10px',
  }))

  const UploadProgressIndicatorBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }))

  const [uploadProgress, setUploadProgress] = React.useState<number>(0)

  const handleChangeUploadRequirements = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsRequirementsUploading(true)
      const { data } = await uploadMedia(formData, (progress: any) => {
        setUploadProgress(progress)
      })
      setFormValues({
        ...formValues,
        requirements: data,
      })
      setIsRequirementsUploading(false)
      setUploadProgress(0)
    } catch (error: any) {
      setIsRequirementsUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error Uploading Requirements',
        type: SnackbarType.Error,
      })
    }
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateAssemblyLineDialogTitle onClose={handleClose}>
          {assemblyLine ? 'Edit' : 'Create'} Assembly Line
        </CreateAssemblyLineDialogTitle>
        <DialogContent dividers>
          <SetupFeildsBox>
            <MainSectionLabel>Setup Fields</MainSectionLabel>
            <OrganizationSearchAndSelect
              onChange={handleChangeOrganization}
              value={formValues.organization || null}
              error={formValues.invalid_organization}
              helperText={formValues.invalid_organization ? 'Required' : ''}
            />
            <TextField
              value={formValues.name || ''}
              margin="dense"
              label="Name"
              type="text"
              name="name"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
              error={formValues.invalid_name}
              helperText={formValues.invalid_name ? 'Required' : ''}
              sx={{ marginTop: '18px' }}
            />
            <TextField
              value={formValues.description || ''}
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              variant="outlined"
              error={formValues.invalid_description}
              onChange={handleChangeFormField}
              helperText={formValues.invalid_description ? 'Required' : ''}
              sx={{ marginTop: '15px' }}
              multiline
              rows={4}
            />
            <TextField
              value={formValues.monthlySubscriptionCost || ''}
              margin="dense"
              name="monthlySubscriptionCost"
              label="Client Budget"
              type="number"
              fullWidth
              variant="outlined"
              error={formValues.invalid_monthlySubscriptionCost}
              onChange={handleChangeFormField}
              helperText={
                formValues.invalid_monthlySubscriptionCost ? 'Required' : ''
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              sx={{ marginTop: '15px' }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              sx={{ marginTop: '15px' }}
            >
              <DatePicker
                label="Start Date"
                sx={{ width: '100%' }}
                value={formValues.startDate}
                onChange={handleChangeStartDate}
                slotProps={{
                  textField: {
                    helperText: formValues.invalid_startDate
                      ? 'Invalid Start Date'
                      : '',
                    error: formValues.invalid_startDate,
                  },
                }}
              />
              <EstimatedStartDateButton
                loading={estimatedStartDateLoading}
                variant="outlined"
                onClick={handleClickEstimateStartDate}
                disabled={!savedAssemblyLine || allocationsChanged}
              >
                Estimate Start Date
              </EstimatedStartDateButton>
            </Box>
            {(!savedAssemblyLine || allocationsChanged) && (
              <EstimateAlert severity="info">
                Add Allocations and Save the Assembly Line before getting
                estimated start date projections
              </EstimateAlert>
            )}
            {estimatedDateTextVisible && (
              <EstimateAlert severity="info">{esitmatedMsg}</EstimateAlert>
            )}
            <Box marginTop="20px" display="flex" justifyContent="space-between">
              <Box width="100%">
                <FormControl fullWidth>
                  <InputLabel id="project-select-label">
                    Project Type
                  </InputLabel>
                  <Select
                    labelId="project-select-label"
                    value={formValues.projectType}
                    label="Project Type"
                    name="projectType"
                    onChange={handleChangeSelect}
                    error={formValues.invalid_projectType}
                  >
                    {Object.values(ProjectTypes).map((value) => (
                      <MenuItem key={value} value={value}>
                        {capitalize(value)}
                      </MenuItem>
                    ))}
                  </Select>
                  {formValues.invalid_projectType && (
                    <FormHelperText error={formValues.invalid_projectType}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            {formValues.organization && (
              <>
                <Box marginTop="15px">
                  <OwnerSearchAndSelect
                    orgId={formValues.organization?.id}
                    value={formValues.owner}
                    onChange={handleChangeOwner}
                    error={formValues.invalid_owner}
                    helperText={formValues.invalid_owner ? 'Select Owner' : ''}
                  />
                </Box>
                <Box marginTop="15px">
                  <OrgMembersSearchAndSelect
                    orgId={formValues.organization?.id}
                    value={formValues.members}
                    onChange={handleChangeMembers}
                    error={formValues.invalid_members}
                    helperText={
                      formValues.invalid_members ? 'Select members' : ''
                    }
                  />
                </Box>
              </>
            )}
            <Box marginTop="15px">
              <CapabilitiesSearchAndSelect
                onChange={handleChangeCapabilities}
                value={formValues.capabilities}
                error={formValues.invalid_capabilities}
                helperText={formValues.invalid_capabilities ? 'Required' : ''}
              />
            </Box>
            <Box marginTop="20px">
              <RequirementsUploaderBox>
                <LoadingButton
                  variant="contained"
                  component="label"
                  sx={{ boxShadow: 'none' }}
                  loading={isRequirementsUploading}
                >
                  {formValues.requirements &&
                  (formValues.requirements as Media).media
                    ? 'Change Requirements PDF'
                    : 'Add Requirements PDF'}
                  <input
                    type="file"
                    hidden
                    value={''}
                    onChange={handleChangeUploadRequirements}
                    disabled={isRequirementsUploading}
                    accept="application/pdf"
                  />
                </LoadingButton>
                {isRequirementsUploading && (
                  <UploadProgressIndicatorBox>
                    <CircularProgressWithLabel value={uploadProgress} />
                    <Typography variant="body2">
                      {isRequirementsUploading && uploadProgress < 100
                        ? 'Uploading...'
                        : 'Processing...'}
                    </Typography>
                  </UploadProgressIndicatorBox>
                )}
              </RequirementsUploaderBox>
            </Box>
            <Box marginTop="20px">
              {formValues.requirements &&
                (formValues.requirements as Media).media && (
                  <a
                    style={{ marginTop: '20px' }}
                    href={getMediaUrl((formValues.requirements as Media).media)}
                    target="_blank"
                  >
                    {' '}
                    Requirements
                  </a>
                )}
            </Box>
          </SetupFeildsBox>
          <Box marginTop="20px">
            <MainSectionLabel>Other Fields</MainSectionLabel>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Timezone</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={formValues.timezone || ''}
                label="Timezone"
                name="timezone"
                onChange={handleChangeSelect}
              >
                {timezones.map((timezone, index) => (
                  <MenuItem key={index} value={timezone.value}>
                    {timezone.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box marginTop="25px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight={500}>
                Allocations
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsAllocationFormVisible(true)
                  setEstimatedDateTextVisible(false)
                }}
                sx={{ opacity: isAllocationFormVisible ? 0 : 1 }}
                disabled={isAllocationFormVisible}
              >
                Add Allocation
              </Button>
            </Box>
          </Box>
          {isAllocationFormVisible && (
            <Paper sx={{ padding: 2 }}>
              <ResourceSearchAndSelect
                value={resource}
                onChange={handleChangeResource}
                error={allocationFormValues.invalid_resourceID}
                helperText={
                  allocationFormValues.invalid_resourceID
                    ? 'Please select a resource'
                    : ''
                }
                resourceID={
                  selectedAllocation.allocation?.resourceID
                    ? selectedAllocation.allocation.resourceID
                    : null
                }
              />
              <Box display="flex" flexDirection="row" gap={3} marginTop="18px">
                <TextField
                  value={allocationFormValues.amount || ''}
                  margin="dense"
                  label="Amount (in $ USD)"
                  type="number"
                  name="amount"
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeAllocationFormField}
                  error={allocationFormValues.invalid_amount}
                  helperText={
                    allocationFormValues.invalid_amount ? 'Required' : ''
                  }
                  sx={{ margin: 0 }}
                />
                <TextField
                  value={allocationFormValues.rate}
                  margin="dense"
                  name="rate"
                  label={`Rate (in ${allocationFormValues.rateUnit.toLowerCase()})`}
                  type="number"
                  fullWidth
                  variant="outlined"
                  error={allocationFormValues.invalid_rate}
                  onChange={handleChangeAllocationFormField}
                  helperText={
                    allocationFormValues.invalid_rate ? 'Required' : ''
                  }
                  sx={{ margin: 0 }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop="18px"
              >
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="rateUnit-select-label">
                      Rate Unit
                    </InputLabel>
                    <Select
                      labelId="rateUnit-select-label"
                      value={allocationFormValues.rateUnit}
                      label="Rate Unit"
                      name="rateUnit"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_rateUnit}
                    >
                      {Object.values(RateUnit).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_rateUnit && (
                      <FormHelperText
                        error={allocationFormValues.invalid_rateUnit}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="frequency-select-label">
                      Frequency (Weekly / Monthly)
                    </InputLabel>
                    <Select
                      labelId="frequency-select-label"
                      value={allocationFormValues.frequency}
                      label="Frequency (Weekly / Monthly)"
                      name="frequency"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_frequency}
                    >
                      {Object.values(Frequency).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_frequency && (
                      <FormHelperText
                        error={allocationFormValues.invalid_frequency}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop="18px"
              >
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                      labelId="type-select-label"
                      value={allocationFormValues.type}
                      label="Type"
                      name="type"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_type}
                    >
                      {Object.values(Type).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_type && (
                      <FormHelperText error={allocationFormValues.invalid_type}>
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      value={allocationFormValues.status || Status.Initialized}
                      label="Status"
                      name="status"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_status}
                    >
                      {Object.values(Status).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_status && (
                      <FormHelperText
                        error={allocationFormValues.invalid_status}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop="18px"
              >
                <Box width="48%">
                  <FormControl fullWidth>
                    <TextField
                      id="date"
                      type="date"
                      label="Start Date"
                      name="startDate"
                      value={allocationFormValues.startDate}
                      onChange={handleChangeAllocationFormField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Box>
                <Box width="48%">
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={allocationFormValues.startDateAccepted}
                          onChange={(event) => {
                            setAllocationFormValues({
                              ...allocationFormValues,
                              startDateAccepted: event.target.checked,
                            })
                          }}
                        />
                      }
                      label="Start Date Accepted"
                    />
                  </FormControl>
                </Box>
              </Box>

              <TextField
                value={allocationFormValues.statusMessage || ''}
                margin="dense"
                name="statusMessage"
                label="Status Message ( System Generated )"
                type="text"
                fullWidth
                variant="outlined"
                disabled={true}
                onChange={handleChangeAllocationFormField}
                sx={{ margin: 0, marginTop: '18px' }}
              />
              <Box
                display="flex"
                alignItems="center"
                height={65}
                marginTop="18px"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={allocationFormValues.approved}
                      onChange={(event) => {
                        setAllocationFormValues({
                          ...allocationFormValues,
                          approved: event.target.checked,
                          approvedBy: '',
                        })
                      }}
                    />
                  }
                  label="Approved"
                />
                {allocationFormValues.approved && (
                  <TextField
                    value={allocationFormValues.approvedBy || ''}
                    margin="dense"
                    name="approvedBy"
                    label="Approved By"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAllocationFormField}
                  />
                )}
              </Box>
              <Box marginTop="20px">
                <FormControlLabel
                  control={
                    <Switch
                      checked={allocationFormValues.active}
                      onChange={(event) => {
                        setAllocationFormValues({
                          ...allocationFormValues,
                          active: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="Active"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAllocationFormValues(allocationFormInitialValues)
                    setIsAllocationFormVisible(false)
                    setResource(null)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveAllocation}
                  sx={{ marginLeft: 1 }}
                >
                  Save Allocation
                </Button>
              </Box>
            </Paper>
          )}
          <List>
            {formValues.allocations.map((allocation, index) => (
              <Paper key={index}>
                <ListItem
                  secondaryAction={
                    <Box display="flex" gap={3}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() =>
                          handleClickEditAllocation(allocation, index)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleClickDeleteAllocation(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      {...stringAvatar(allocation.resource?.firstName || '')}
                      src={getMediaUrl(
                        allocation.resource?.profilePicture?.media || ''
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      allocation.resource?.firstName +
                      ' ' +
                      allocation.resource?.lastName
                    }
                    secondary={capitalize(allocation.type)}
                  />
                </ListItem>
                {formValues.allocations.length - 1 !== index && <Divider />}
              </Paper>
            ))}
          </List>
          <Divider />
          <Box marginTop={3}>
            <Typography variant="h5" fontWeight={500}>
              Funding
            </Typography>
            <TextField
              value={formValues.funding.billingAmount || ''}
              margin="dense"
              name="billingAmount"
              label="Billing Amount"
              type="number"
              fullWidth
              variant="outlined"
              error={formValues.funding.invalid_billingAmount}
              onChange={handleChangeFundingFormField}
              helperText={
                formValues.funding.invalid_billingAmount ? 'Required' : ''
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbols[formValues.funding.currency]}
                  </InputAdornment>
                ),
              }}
              sx={{ marginTop: '18px' }}
            />
            {/*<Box display="flex" justifyContent="space-between" marginTop="18px">*/}
            {/*  <Box width="48%">*/}
            {/*    <FormControl fullWidth>*/}
            {/*      <InputLabel id="currency-select-label">Currency</InputLabel>*/}
            {/*      <Select*/}
            {/*        labelId="currency-select-label"*/}
            {/*        value={formValues.funding.currency || ''}*/}
            {/*        label="Currency"*/}
            {/*        name="currency"*/}
            {/*        onChange={handleChangeFundingFormSelect}*/}
            {/*      >*/}
            {/*        {supportedCoutries.map((country) => (*/}
            {/*          <MenuItem*/}
            {/*            key={country.countryCode}*/}
            {/*            value={country.currency}*/}
            {/*          >*/}
            {/*            {country.currency}*/}
            {/*          </MenuItem>*/}
            {/*        ))}*/}
            {/*      </Select>*/}
            {/*    </FormControl>*/}
            {/*  </Box>*/}
            {/*  <Box width="48%">*/}
            {/*    <FormControl fullWidth>*/}
            {/*      <InputLabel id="frequency-select-label">Frequency</InputLabel>*/}
            {/*      <Select*/}
            {/*        labelId="frequency-select-label"*/}
            {/*        value={formValues.funding.frequency || ''}*/}
            {/*        label="Frequency"*/}
            {/*        name="frequency"*/}
            {/*        onChange={handleChangeFundingFormSelect}*/}
            {/*        error={formValues.funding.invalid_frequency}*/}
            {/*      >*/}
            {/*        {Object.values(FundingFrequency).map((value) => (*/}
            {/*          <MenuItem key={value} value={value}>*/}
            {/*            {capitalize(value)}*/}
            {/*          </MenuItem>*/}
            {/*        ))}*/}
            {/*      </Select>*/}
            {/*      {formValues.funding.invalid_frequency && (*/}
            {/*        <FormHelperText*/}
            {/*          error={formValues.funding.invalid_frequency}*/}
            {/*        >*/}
            {/*          Required*/}
            {/*        </FormHelperText>*/}
            {/*      )}*/}
            {/*    </FormControl>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*  display="flex"*/}
            {/*  justifyContent="space-between"*/}
            {/*  gap={2}*/}
            {/*  marginTop="18px"*/}
            {/*>*/}
            {/*  <DatePicker*/}
            {/*    label="Start Date"*/}
            {/*    sx={{ width: '100%' }}*/}
            {/*    value={formValues.funding.startDate}*/}
            {/*    onChange={handleChangeFundingStartDate}*/}
            {/*    slotProps={{*/}
            {/*      textField: {*/}
            {/*        helperText: formValues.funding.invalid_startDate*/}
            {/*          ? 'Invalid Start Date'*/}
            {/*          : '',*/}
            {/*        error: formValues.funding.invalid_startDate,*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  />*/}
            {/*<DatePicker*/}
            {/*  label="End Date"*/}
            {/*  sx={{ width: '100%' }}*/}
            {/*  value={formValues.funding.endDate}*/}
            {/*  onChange={handleChangeFundingEndDate}*/}
            {/*  minDate={formValues.funding.startDate}*/}
            {/*  disabled={!formValues.funding.startDate}*/}
            {/*  slotProps={{*/}
            {/*    textField: {*/}
            {/*      helperText: formValues.funding.invalid_endDate*/}
            {/*        ? 'End Date should be after Start Date'*/}
            {/*        : '',*/}
            {/*      error: formValues.funding.invalid_endDate,*/}
            {/*    },*/}
            {/*  }}*/}
            {/*/>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*  display="flex"*/}
            {/*  justifyContent="space-between"*/}
            {/*  gap={2}*/}
            {/*  marginTop="18px"*/}
            {/*>*/}
            {/*  <Box width="48%">*/}
            {/*    <TextField*/}
            {/*      value={formValues.funding.lastSuccessfulPayment || ''}*/}
            {/*      margin="dense"*/}
            {/*      name="lastSuccessfulPayment"*/}
            {/*      label="Last Successful Payment"*/}
            {/*      type="number"*/}
            {/*      fullWidth*/}
            {/*      variant="outlined"*/}
            {/*      onChange={handleChangeFundingFormField}*/}
            {/*      InputProps={{*/}
            {/*        startAdornment: (*/}
            {/*          <InputAdornment position="start">*/}
            {/*            {currencySymbols[formValues.funding.currency]}*/}
            {/*          </InputAdornment>*/}
            {/*        ),*/}
            {/*      }}*/}
            {/*      sx={{ margin: 0 }}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*  <Box width="48%">*/}
            {/*    <FormControl fullWidth>*/}
            {/*      <InputLabel id="status-select-label">Status</InputLabel>*/}
            {/*      <Select*/}
            {/*        labelId="status-select-label"*/}
            {/*        value={formValues.funding.status || ''}*/}
            {/*        label="Status"*/}
            {/*        name="status"*/}
            {/*        onChange={handleChangeFundingFormSelect}*/}
            {/*        error={formValues.funding.invalid_status}*/}
            {/*      >*/}
            {/*        {Object.values(FundingStatus).map((value) => (*/}
            {/*          <MenuItem key={value} value={value}>*/}
            {/*            {capitalize(value)}*/}
            {/*          </MenuItem>*/}
            {/*        ))}*/}
            {/*      </Select>*/}
            {/*      {formValues.funding.invalid_status && (*/}
            {/*        <FormHelperText error={formValues.funding.invalid_status}>*/}
            {/*          Required*/}
            {/*        </FormHelperText>*/}
            {/*      )}*/}
            {/*    </FormControl>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <TextField
              value={formValues.funding.statusMsg || ''}
              margin="dense"
              name="statusMsg"
              label="Status Msg (System Generated)"
              disabled={true}
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFundingFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
            <TextField
              value={formValues.funding.setupLink || ''}
              margin="dense"
              name="setupLink"
              label="Setup Link (optional)"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFundingFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
            <TextField
              value={formValues.funding.paymentLink || ''}
              margin="dense"
              name="paymentLink"
              label="Payment Link (optional)"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFundingFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
          </Box>
          <Box marginTop={3}>
            <Typography variant="h5" fontWeight={500}>
              Project Management
            </Typography>
            <TextField
              value={formValues.projectManagementLink || ''}
              margin="dense"
              name="projectManagementLink"
              label="Project Management Link (optional)"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
            <TextField
              value={formValues.submitTaskLink || ''}
              margin="dense"
              name="submitTaskLink"
              label="Submit Task Link (optional)"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
            <TextField
              value={formValues.kanbanLink || ''}
              margin="dense"
              name="kanbanLink"
              label="Kanban Link (optional)"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
              sx={{ margin: 0, marginTop: '18px' }}
            />
            <Box display="flex" justifyContent="space-between" gap={2}>
              <TextField
                value={formValues.teamID || ''}
                margin="dense"
                name="teamID"
                label="Team ID"
                disabled={true}
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
                sx={{ margin: 0, marginTop: '18px' }}
              />
              <TextField
                value={formValues.projectID || ''}
                margin="dense"
                name="projectID"
                label="Project ID"
                disabled={true}
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
                sx={{ margin: 0, marginTop: '18px' }}
              />
            </Box>
          </Box>
          {assemblyLine && (
            <Box marginTop={5}>
              <Typography>
                Billing Setup Complete:{' '}
                {assemblyLine.org?.billing.billingSetupComplete ? 'Yes' : 'No'}
              </Typography>
            </Box>
          )}
          <Box marginTop={assemblyLine ? 3 : 5}>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Assembly Status</InputLabel>
              <Select
                labelId="status-select-label"
                value={formValues.status || ''}
                label="Assembly Status"
                name="status"
                onChange={(event) => {
                  handleChangeSelect(event)
                  setResource(null)
                  setAllocationFormValues({
                    ...allocationFormValues,
                    invalid_resourceID: false,
                  })
                }}
                error={formValues.invalid_status}
              >
                {Object.values(AssemblyLineStatus).map((value) => (
                  <MenuItem key={value} value={value}>
                    {capitalize(value)}
                  </MenuItem>
                ))}
              </Select>
              {formValues.invalid_status && (
                <FormHelperText error={formValues.invalid_status}>
                  Required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box>
            <TextField
              value={formValues.statusMessage || ''}
              margin="dense"
              name="statusMessage"
              label="Status Message (System Generated)"
              disabled={true}
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChangeFormField}
              sx={{ marginTop: '18px' }}
            />
          </Box>
          <Box display="flex" alignItems="center" height={65} marginTop="18px">
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.approved}
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      approved: event.target.checked,
                      approvedBy: '',
                    })
                  }}
                />
              }
              label="Approved"
            />
            {formValues.approved && (
              <TextField
                value={formValues.approvedBy || ''}
                margin="dense"
                name="approvedBy"
                label="Approved By"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChangeFormField}
              />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={2}
            sx={{ marginTop: '15px' }}
          >
            <DatePicker
              label="End Date"
              sx={{ width: '100%' }}
              value={formValues.endDate}
              onChange={handleChangeEndDate}
              slotProps={{
                textField: {
                  helperText: formValues.invalid_endDate
                    ? 'Invalid End Date'
                    : '',
                  error: formValues.invalid_endDate,
                },
              }}
            />
          </Box>
          {assemblyLine && (
            <Box marginTop={3}>
              {Object.values(checkListItems).length > 0 && (
                <Typography variant="h5" fontWeight={500} marginBottom={1}>
                  Checklist
                </Typography>
              )}
              <Box display="flex" flexWrap="wrap" gap={4}>
                {Object.values(checkListItems).map((item: any) => (
                  <FormControlLabel
                    key={item.key}
                    control={
                      <Switch
                        checked={item.done}
                        onChange={(event) => {
                          setCheckListItems({
                            ...checkListItems,
                            [item.key]: {
                              ...item,
                              done: event.target.checked,
                            },
                          })
                        }}
                      />
                    }
                    label={item.title}
                  />
                ))}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isAllocationFormVisible}
            loading={isSaving}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

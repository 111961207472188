import { useAppStore } from '@assembly/hooks'
import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  colors,
  Button,
} from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { InfoItem } from '@assembly/components'
import {
  getMediaUrl,
  interpolateRouteUtil,
  stringAvatar,
} from '@assembly/utils'
import { capitalize } from 'lodash'
import { getAssemblyLine } from '@assembly/api/assemblyLine'
import { AssemblyLine as IAssemblyLine } from '../AssemblyLines/types'
import moment from 'moment-timezone'
import { RoleLabelMapping, TeamMember } from '../Teams/types'
import { RoutePaths } from '@assembly/constants'
import { currencySymbols } from '@assembly/shared/types/countriesAndCurrencies'
import { styled } from '@mui/material/styles'
import CreateAssemblyLineDialog from '../AssemblyLines/CreateAssemblyLineDialog'

const EditBtnBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
})

const EditBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  marginBottom: '10px',
})

const AssemblyLine: React.FC = () => {
  const { setAppBarTitle } = useAppStore()
  const didMount = React.useRef(false)
  const params = useParams()
  const [data, setData] = React.useState<IAssemblyLine>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const navigate = useNavigate()
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)
  const { store, setAssemblyLines } = useAppStore()

  React.useEffect(() => {
    if (!didMount.current) {
      getDataAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getDataAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAssemblyLine(params.id || '')
      setAppBarTitle(data.name)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleClickMember = (member: TeamMember) => {
    navigate(
      interpolateRouteUtil(RoutePaths.TeamMember, {
        orgId: member.organizationID,
        teamId: member.primaryTeam,
        teamMemberId: member.id,
      })
    )
  }

  const handleClickEdit = () => {
    setEditDialogOpen(true)
  }

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  const onEditSuccess = (value: IAssemblyLine): void => {
    const index = store.assemblyLines.findIndex(
      (assemblyLine) => assemblyLine.id === value.id
    )
    if (index !== -1) {
      store.assemblyLines[index] = value
      setAssemblyLines([...store.assemblyLines])
      setData({ ...value })
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <EditBtnBox>
        <EditBtn variant="contained" onClick={handleClickEdit}>
          Edit
        </EditBtn>
      </EditBtnBox>
      <InfoItem label="Name" value={data?.name || '---'} />
      <InfoItem label="Description" value={data?.description || '---'} />
      <Box display="flex" justifyContent="center" gap={1.5}>
        <InfoItem label="Organization" value={data?.org?.name || '---'} />
        <InfoItem
          label="Client Budget"
          value={'$' + data?.monthlySubscriptionCost || '---'}
        />
      </Box>
      <InfoItem
        label="Capabilities"
        value={
          data?.capabilities?.map((capability) => capability.name).join(', ') ||
          '---'
        }
      />
      <Box display="flex" justifyContent="center" gap={1.5}>
        <InfoItem
          label="Start Date"
          value={
            data?.startDate
              ? moment(data?.startDate).format('DD-MMMM-YYYY')
              : '---'
          }
        />
        <InfoItem
          label="End Date"
          value={
            data?.endDate ? moment(data?.endDate).format('DD-MMMM-YYYY') : '---'
          }
        />
      </Box>
      <Box display="flex" justifyContent="center" gap={1.5}>
        <InfoItem label="Time Zone" value={data?.timezone || '---'} />
        <InfoItem label="Project Type" value={data?.projectType || '---'} />
      </Box>
      <Box display="flex" justifyContent="center" gap={1.5}>
        <InfoItem label="Status" value={data?.status || '---'} />
        <InfoItem label="Status Message" value={data?.statusMessage || '---'} />
      </Box>
      <Box display="flex" justifyContent="center" gap={1.5}>
        <InfoItem
          label="Approved"
          value={data?.approved ? 'Yes' : 'No' || '---'}
        />
        <InfoItem label="Approved By" value={data?.approvedBy || '---'} />
      </Box>
      <InfoItem
        label="Owner"
        value={
          data?.owner
            ? `${(data.owner as TeamMember).name} (${
                (data.owner as TeamMember).email
              })`
            : '---'
        }
      />
      <Typography
        variant="h5"
        fontWeight={500}
        marginBottom={1.2}
        marginTop={4}
      >
        Project
      </Typography>
      <InfoItem
        label="Project Management Link"
        value={data?.projectManagement.projectManagementLink || '---'}
        isLink={!!data?.projectManagement.projectManagementLink}
      />
      <InfoItem
        label="Submit Task Link"
        value={data?.projectManagement.submitTaskLink || '---'}
        isLink={!!data?.projectManagement.submitTaskLink}
      />
      <InfoItem
        label="Kanban Link"
        value={data?.projectManagement.kanbanLink || '---'}
        isLink={!!data?.projectManagement.kanbanLink}
      />
      <InfoItem
        label="Team ID"
        value={data?.projectManagement.teamID || '---'}
      />
      <InfoItem
        label="Project ID"
        value={data?.projectManagement.projectID || '---'}
      />
      <Typography variant="h5" fontWeight={500} marginTop={4}>
        Members
      </Typography>
      <Box>
        <List>
          {data?.members.map((member, index) => (
            <div key={index}>
              <ListItem
                sx={{
                  padding: 0,
                  border: '1px solid',
                  borderColor: colors.grey[200],
                  marginBottom: 2,
                }}
                onClick={() => handleClickMember(member)}
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar {...stringAvatar(member.name || member.email)} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={member.name || member.email}
                    secondary={RoleLabelMapping[member.role]}
                  />
                </ListItemButton>
              </ListItem>
            </div>
          ))}
        </List>
      </Box>
      <Typography variant="h5" fontWeight={500} marginTop={1}>
        Allocations
      </Typography>
      {data?.allocations.length === 0 && (
        <Typography marginTop={1.2}>No Allocations</Typography>
      )}
      {data?.allocations.map((allocation, index) => (
        <Paper
          key={index}
          sx={{
            marginBottom: 2,
            padding: 2,
            marginTop: 2,
            boxShadow: 'none',
            border: '1px solid',
            borderColor: colors.grey[200],
          }}
        >
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Avatar
              {...stringAvatar(allocation.resource?.firstName || '')}
              src={getMediaUrl(
                allocation.resource?.profilePicture?.media || ''
              )}
            />
            <Box>
              <Typography marginLeft={2} fontSize={17}>
                {allocation.resource?.firstName +
                  ' ' +
                  allocation.resource?.lastName}
              </Typography>
              <Typography variant="body2" marginLeft={2}>
                {allocation.resource?.label}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" gap={2} marginLeft={7}>
            <Typography>
              <b>Type: </b>
              {capitalize(allocation.type)}
            </Typography>
            <Typography>
              <b>Amount: </b>
              {allocation.amount}
            </Typography>
            <Typography>
              <b>Rate: </b>
              {allocation.rate}
            </Typography>
            <Typography>
              <b>Rate Unit: </b>
              {allocation.rateUnit}
            </Typography>
            <Typography>
              <b>Frequency: </b>
              {capitalize(allocation.frequency)}
            </Typography>
            <Typography>
              <b>Status: </b>
              {capitalize(allocation.status)}
            </Typography>
            <Typography>
              <b>Status Message: </b>
              {capitalize(allocation.statusMessage || '---')}
            </Typography>
            <Typography>
              <b>Approved: </b>
              {allocation.approved ? 'Yes' : 'No'}
            </Typography>
            <Typography>
              <b>Approved By: </b>
              {allocation.approvedBy || '---'}
            </Typography>
            <Typography>
              <b>Active: </b>
              {allocation.active ? 'Yes' : 'No'}
            </Typography>
          </Box>
        </Paper>
      ))}
      <Typography variant="h5" fontWeight={500} marginTop={4}>
        Funding
      </Typography>
      <Box marginTop={1.2}>
        <Box display="flex" justifyContent="center" gap={1.5}>
          <InfoItem
            label="Billing Amount"
            value={
              data?.funding.billingAmount
                ? currencySymbols[data?.funding.currency || ''] +
                  ' ' +
                  data?.funding.billingAmount
                : '---'
            }
          />
          <InfoItem
            label="Frequency"
            value={data?.funding.frequency || '---'}
          />
        </Box>
        <Box display="flex" justifyContent="center" gap={1.5}>
          <InfoItem
            label="Funding Start Date"
            value={
              data?.funding.startDate
                ? moment(Number(data?.funding.startDate)).format('DD-MMMM-YYYY')
                : '---'
            }
          />
          <InfoItem
            label="Funding End Date"
            value={
              data?.funding.endDate
                ? moment(Number(data?.funding.endDate)).format('DD-MMMM-YYYY')
                : '---'
            }
          />
        </Box>
        <InfoItem
          label="Last Successful Payment"
          value={
            data?.funding.lastSuccessfulPayment
              ? currencySymbols[data?.funding.currency || ''] +
                ' ' +
                data?.funding.lastSuccessfulPayment
              : '---'
          }
        />
        <Box display="flex" justifyContent="center" gap={1.5}>
          <InfoItem label="Status" value={data?.funding.status || '---'} />
          <InfoItem
            label="Status Message"
            value={data?.funding.statusMsg || '---'}
          />
        </Box>
        <InfoItem label="Setup Link" value={data?.funding.setupLink || '---'} />
      </Box>
      <CreateAssemblyLineDialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        assemblyLine={data}
        onSuccess={onEditSuccess}
      />
    </Box>
  )
}

export default AssemblyLine

export function getArrayDifferenceById(array1: any[], array2: any[]) {
  const array2Ids = array2.map((obj) => obj.id)

  const difference = array1.filter((obj) => !array2Ids.includes(obj.id))

  return difference
}

export function getApiErrorMsg(error: any) {
  if (
    error.response &&
    error.response.data &&
    error.response.data.message &&
    typeof error.response.data.message === 'string'
  ) {
    return error.response.data.message
  }

  if (error.response && error.response.data && error.response.data.messages) {
    return error.response.data.messages.map((m: any) => m).join(', ')
  }

  return 'Something went wrong'
}

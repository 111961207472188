import { getPayoutInvoices } from '@assembly/api/resources'
import { SnackbarType } from '@assembly/contexts'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg, interpolateRouteUtil } from '@assembly/utils'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import { RoutePaths } from '@assembly/constants'
import moment from 'moment-timezone'
import { InvoiceStatusTypes, PayoutInvoice } from './types'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import EditPayoutInvoiceDialog from './EditPayoutInvoiceDialog'
import SendPaymentDialog from './SendPaymentDialog'

const DIALOG_TRANSITION_DURATION = 300

const ColValue = styled(Typography)(() => ({
  fontSize: '14px',
}))

const StyledLink = styled(Link)(() => ({
  fontSize: '15px',
  cursor: 'pointer',
  color: '#000',
  whiteSpace: 'normal',
  wordBreak: 'break-all',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
}))

const AssemblyCol = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
}))

const DetailCol = styled(Typography)(() => ({
  fontSize: '14px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}))

const InvoiceLink = styled(Link)(() => ({
  fontSize: '14px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  cursor: 'pointer',
  color: '#000',
}))

const StyledButton = styled(LoadingButton)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const StyledFormControl = styled(FormControl)(({}) => ({
  margin: 0,
  width: '300px',
}))

const FilterBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '20px',
  gap: '10px',
}))

export default function ResourcePayoutInvoices() {
  const { setAppBarTitle } = useAppStore()
  const pathParams = useParams<{ id: string }>()
  const { addAlert } = useSnackbars()
  const didMount = React.useRef<boolean>(false)
  const [data, setData] = React.useState<PayoutInvoice[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] =
    React.useState<PayoutInvoice | null>(null)
  const [confirmPaymentDialogOpen, setConfirmPaymentDialogOpen] =
    React.useState<boolean>(false)
  const [status, setStatus] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMount.current) {
      getPayoutInvoicesAsync()
      didMount.current = true
    }
    setAppBarTitle(`Payout Invoices For Resource (${pathParams.id})`)

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getPayoutInvoicesAsync = async (status = '') => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('contractorID', pathParams.id as string)
      params.append('status', status)
      const { data } = await getPayoutInvoices(params)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const assemblyCol = (params: GridCellParams) => {
    return (
      <AssemblyCol>
        <StyledLink
          href={interpolateRouteUtil(RoutePaths.AssemblyLine, {
            id: params.row.billedAssemblyLine.id,
          })}
          target="_blank"
        >
          {params.row.billedAssemblyLine.name}
        </StyledLink>
      </AssemblyCol>
    )
  }

  const resourceIdCol = (params: GridCellParams) => {
    return (
      <StyledLink
        href={interpolateRouteUtil(RoutePaths.Resource, {
          id: params.row.resourceID,
        })}
        target="_blank"
      >
        {params.row.resourceID}
      </StyledLink>
    )
  }

  const billableAmountCol = (params: GridCellParams) => {
    return <ColValue>$ {params.row.billableAmount}</ColValue>
  }

  const billableUnitsCol = (params: GridCellParams) => {
    return <ColValue>{params.row.billableUnits}</ColValue>
  }

  const statusCol = (params: GridCellParams) => {
    return <ColValue>{capitalize(params.row.status)}</ColValue>
  }

  const dateCol = (params: GridCellParams) => {
    const formattedDate = moment(params.row[params.field], 'YYYYMMDD').format(
      'MM-DD-YYYY'
    )
    return <ColValue>{formattedDate}</ColValue>
  }

  const detailCol = (params: GridCellParams) => {
    return (
      <DetailCol>{params.row.details ? params.row.details : '---'}</DetailCol>
    )
  }

  const statusMessageCol = (params: GridCellParams) => {
    return (
      <DetailCol>
        {params.row.statusMessage ? params.row.statusMessage : '---'}
      </DetailCol>
    )
  }

  const renderInvoiceLink = (params: GridCellParams) => {
    if (!params.row.invoiceLink) {
      return <ColValue>---</ColValue>
    }

    return (
      <InvoiceLink
        href={params.row.invoiceLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Invoice
      </InvoiceLink>
    )
  }

  const timestampCol = (params: GridCellParams) => {
    const formatted = moment(params.row.timestamp).format('MM-DD-YYYY')
    return <ColValue>{formatted}</ColValue>
  }

  const transferIDCol = (params: GridCellParams) => {
    return <ColValue>{params.row.transferID}</ColValue>
  }

  const handleClickSendPayment = (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setConfirmPaymentDialogOpen(true)
  }

  const sendPaymentCol = (params: GridCellParams) => {
    if (params.row.status !== 'CREATED') {
      return <ColValue>---</ColValue>
    }

    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickSendPayment(params.row)}
      >
        Send Payment
      </StyledButton>
    )
  }

  const handleClickUpdatePayment = (invoice: PayoutInvoice) => {
    setSelectedInvoice(invoice)
    setIsDialogOpen(true)
  }

  const updatePaymentCol = (params: GridCellParams) => {
    return (
      <StyledButton
        variant="contained"
        color="primary"
        size="small"
        loading={false}
        onClick={() => handleClickUpdatePayment(params.row)}
      >
        Update Payment
      </StyledButton>
    )
  }

  const dataGridColumns: GridColDef[] = [
    {
      type: 'string',
      field: 'billedAssemblyLine',
      headerName: 'Assembly',
      width: 180,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: assemblyCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.billedAssemblyLine.name
      },
    },
    {
      type: 'string',
      field: 'resourceID',
      headerName: 'Resource',
      width: 155,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      renderCell: resourceIdCol,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params: GridCellParams) => {
        return params.row.resourceID
      },
    },
    {
      field: 'billableAmount',
      headerName: 'Billable Amount',
      width: 122,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableAmountCol,
    },
    {
      field: 'billableUnits',
      headerName: 'Billable Units',
      width: 105,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableUnitsCol,
    },
    {
      field: 'billingStart',
      headerName: 'Billing Start Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
    },
    {
      field: 'billingEnd',
      headerName: 'Billing End Date',
      width: 130,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
    },
    {
      type: 'string',
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
      sortable: true,
      filterable: true,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusCol,
      valueGetter: (params: GridCellParams) => {
        return params.row.status
      },
    },
    {
      field: 'statusMessage',
      headerName: 'Status Message',
      width: 350,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusMessageCol,
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: detailCol,
    },
    {
      field: 'invoiceLink',
      headerName: 'Invoice Link',
      width: 350,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderInvoiceLink,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 100,
      editable: false,
      sortable: true,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: timestampCol,
    },
    {
      field: 'transferID',
      headerName: 'Transfer ID',
      width: 230,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: transferIDCol,
    },
    {
      field: 'sendPayment',
      headerName: 'Send Payment',
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: sendPaymentCol,
    },
    {
      field: 'updatePayment',
      headerName: 'Update Payment',
      width: 140,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: updatePaymentCol,
    },
  ]

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setTimeout(() => {
      setSelectedInvoice(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleUpdateSuccess = (invoice: PayoutInvoice) => {
    const index = data.findIndex((item) => item.id === invoice.id)
    if (index !== -1) {
      const newData = [...data]
      newData[index] = invoice
      setData(newData)
    }
  }

  const handleCloseConfirmPaymentDialog = () => {
    setConfirmPaymentDialogOpen(false)
    setTimeout(() => {
      setSelectedInvoice(null)
    }, DIALOG_TRANSITION_DURATION)
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { value } = event.target
    setStatus(value)
    getPayoutInvoicesAsync(value)
  }

  const handleClickClear = () => {
    setStatus('')
    getPayoutInvoicesAsync()
  }

  return (
    <Box>
      <FilterBox>
        <StyledFormControl size="small">
          <InputLabel id="status-select-label">Filter By Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={status}
            label="Filter By Status"
            name="status"
            onChange={handleChangeSelect}
          >
            {Object.entries(InvoiceStatusTypes).map(([key, value]) => (
              <MenuItem key={key} value={value}>
                {capitalize(key.replace(/_/g, ' '))}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {status && <Button onClick={handleClickClear}>Clear</Button>}
      </FilterBox>
      <DataGrid
        columns={dataGridColumns}
        rows={data}
        loading={isLoading}
        sx={{ height: 'calc(100vh - 180px)', borderWidth: '0px' }}
        showCellVerticalBorder
      />
      <EditPayoutInvoiceDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        payoutInvoice={selectedInvoice}
        onSuccess={handleUpdateSuccess}
      />
      <SendPaymentDialog
        open={confirmPaymentDialogOpen}
        onClose={handleCloseConfirmPaymentDialog}
        payoutInvoice={selectedInvoice}
        onSuccess={handleUpdateSuccess}
      />
    </Box>
  )
}

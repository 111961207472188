import { AssemblyLine } from '@assembly/pages/AssemblyLines/types'
import { AssemblyTemplate } from '@assembly/pages/AssemblyTemplates/types'
import { Capability } from '@assembly/pages/Capabilities/types'
import { Job } from '@assembly/pages/Jobs/types'
import { Organization } from '@assembly/pages/OrganizationManager/types'
import { Resource } from '@assembly/pages/Resources/types'
import { Skill } from '@assembly/pages/Skills/types'
import { SkillAssessment } from '@assembly/pages/SkillsAssessment/types'
import { User } from '@assembly/shared/types/user'
import { createContext, useState, useMemo, useCallback, useEffect } from 'react'

type Store = {
  appBarTitle: string
  organizations: Organization[]
  isOrganizationsLoaded: boolean
  resources: Resource[]
  isResoucesLoaded: boolean
  capabilities: Capability[]
  isCapabilitiesLoaded: boolean
  assemblyTemplates: AssemblyTemplate[]
  isAssemblyTemplatesLoaded: boolean
  assemblyLines: AssemblyLine[]
  isAssemblyLinesLoaded: boolean
  userInfo: User | null
  skills: Skill[]
  isSkillsLoaded: boolean
  skillsAssessments: SkillAssessment[]
  isSkillsAssessmentsLoaded: boolean
  jobs: Job[]
  isJobsLoaded: boolean
}

interface IAppContext {
  store: Store
  setOrganizations: (organizations: Organization[]) => void
  setAppBarTitle: (title: string) => void
  setResources: (resources: Resource[]) => void
  setCapabilities: (capabilities: Capability[]) => void
  setAssemblyTemplates: (assemblyTemplates: AssemblyTemplate[]) => void
  setAssemblyLines: (assemblyLines: AssemblyLine[]) => void
  setSkills: (skills: Skill[]) => void
  setSkillsAssessments: (skillsAssessments: SkillAssessment[]) => void
  setJobs: (jobs: Job[]) => void
}

export const AppContext = createContext<IAppContext>({
  store: {
    appBarTitle: '',
    organizations: [],
    isOrganizationsLoaded: false,
    resources: [],
    isResoucesLoaded: false,
    capabilities: [],
    isCapabilitiesLoaded: false,
    assemblyTemplates: [],
    isAssemblyTemplatesLoaded: false,
    assemblyLines: [],
    isAssemblyLinesLoaded: false,
    userInfo: null,
    skills: [],
    isSkillsLoaded: false,
    skillsAssessments: [],
    isSkillsAssessmentsLoaded: false,
    jobs: [],
    isJobsLoaded: false,
  },
  setOrganizations: (organizations: Organization[]) => {},
  setAppBarTitle: (title: string) => {},
  setResources: (resources: Resource[]) => {},
  setCapabilities: (capabilites: Capability[]) => {},
  setAssemblyTemplates: (assemblyTemplates: AssemblyTemplate[]) => {},
  setAssemblyLines: (assemblyLines: AssemblyLine[]) => {},
  setSkills: (skills: Skill[]) => {},
  setSkillsAssessments: (skillsAssessments: SkillAssessment[]) => {},
  setJobs: (jobs: Job[]) => {},
})

interface AppProviderProps {
  children?: React.ReactNode
  user: User
}

export function AppProvider({ children, user }: AppProviderProps) {
  const [store, setStore] = useState<Store>({
    appBarTitle: '',
    organizations: [],
    isOrganizationsLoaded: false,
    resources: [],
    isResoucesLoaded: false,
    capabilities: [],
    isCapabilitiesLoaded: false,
    assemblyTemplates: [],
    isAssemblyTemplatesLoaded: false,
    assemblyLines: [],
    isAssemblyLinesLoaded: false,
    userInfo: null,
    skills: [],
    isSkillsLoaded: false,
    skillsAssessments: [],
    isSkillsAssessmentsLoaded: false,
    jobs: [],
    isJobsLoaded: false,
  })

  useEffect(() => {
    if (user) {
      setStore({
        ...store,
        userInfo: user,
      })
    }
  }, [user])

  const setOrganizations = useCallback((organizations: Organization[]) => {
    setStore((store) => ({
      ...store,
      organizations,
      isOrganizationsLoaded: true,
    }))
  }, [])

  const setResources = useCallback((resources: Resource[]) => {
    setStore((store) => ({
      ...store,
      resources,
      isResoucesLoaded: true,
    }))
  }, [])

  const setCapabilities = useCallback((capabilities: Capability[]) => {
    setStore((store) => ({
      ...store,
      capabilities,
      isCapabilitiesLoaded: true,
    }))
  }, [])

  const setAppBarTitle = useCallback((title: string) => {
    setStore((store) => ({
      ...store,
      appBarTitle: title,
    }))
  }, [])

  const setAssemblyTemplates = useCallback(
    (assemblyTemplates: AssemblyTemplate[]) => {
      setStore((store) => ({
        ...store,
        assemblyTemplates,
        isAssemblyTemplatesLoaded: true,
      }))
    },
    []
  )

  const setAssemblyLines = useCallback((assemblyLines: AssemblyLine[]) => {
    setStore((store) => ({
      ...store,
      assemblyLines,
      isAssemblyLinesLoaded: true,
    }))
  }, [])

  const setSkills = useCallback((skills: Skill[]) => {
    setStore((store) => ({
      ...store,
      skills,
      isSkillsLoaded: true,
    }))
  }, [])

  const setSkillsAssessments = useCallback(
    (skillsAssessments: SkillAssessment[]) => {
      setStore((store) => ({
        ...store,
        skillsAssessments,
        isSkillsAssessmentsLoaded: true,
      }))
    },
    []
  )

  const setJobs = useCallback((jobs: Job[]) => {
    setStore((store) => ({
      ...store,
      jobs,
      isJobsLoaded: true,
    }))
  }, [])

  const value = useMemo(
    () => ({
      setOrganizations,
      store,
      setAppBarTitle,
      setResources,
      setCapabilities,
      setAssemblyTemplates,
      setAssemblyLines,
      setSkills,
      setSkillsAssessments,
      setJobs,
    }),
    [
      setOrganizations,
      store,
      setAppBarTitle,
      setResources,
      setCapabilities,
      setAssemblyTemplates,
      setAssemblyLines,
      setSkills,
      setSkillsAssessments,
      setJobs,
    ]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
  Divider,
  ListItem,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import {
  OnboardingPage,
  OnboardingQuestion,
} from '@assembly/pages/Resources/types'
import AddQuestionDialog from './AddQuestionDialog'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import QuizIcon from '@mui/icons-material/Quiz'
import isURL from 'validator/lib/isURL'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const AddQuestionBtn = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  marginBottom: theme.spacing(2.5),
}))

const OneLineTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  width: '80%',
}))

type AddOnboardingPageDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: (onboardingPage: OnboardingPage) => void
  onboardingPage?: OnboardingPage
}

export default function AddOnboardingPageDialog({
  open,
  onClose,
  onSuccess,
  onboardingPage,
}: AddOnboardingPageDialogProps) {
  const initialFormValues = {
    title: '',
    link: '',
    challengeQuestions: [],
    invalid_link: false,
    invalid_title: false,
    invalid_challengeQuestions: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [questionDialogOpen, setQuestionDialogOpen] = React.useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState(-1)

  React.useEffect(() => {
    if (open && onboardingPage) {
      setFormValues({
        ...initialFormValues,
        link: onboardingPage.link,
        title: onboardingPage.title || '',
        challengeQuestions: onboardingPage.challengeQuestions as any,
      })
    }
  }, [open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      [`invalid_${event.target.name}`]: false,
    })
  }

  const handleClickAdd = () => {
    if (
      !formValues.link.trim() ||
      !isURL(formValues.link) ||
      !formValues.title.trim() ||
      formValues.challengeQuestions.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_link: !formValues.link.trim() || !isURL(formValues.link),
        invalid_challengeQuestions: formValues.challengeQuestions.length === 0,
        invalid_title: !formValues.title.trim(),
      })
      return
    }

    const payload = {
      link: formValues.link,
      title: formValues.title,
      challengeQuestions: formValues.challengeQuestions,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setFormValues(initialFormValues)
    }, 195)
  }

  const handleClickAddQuestion = () => {
    setQuestionDialogOpen(true)
  }

  const handleQuestionDialogClose = () => {
    setQuestionDialogOpen(false)
    setSelectedQuestionIndex(-1)
  }

  const onQuestionCreated = (question: OnboardingQuestion) => {
    setFormValues({
      ...formValues,
      challengeQuestions: [...formValues.challengeQuestions, question] as any,
      invalid_challengeQuestions: false,
    })
  }

  const onQuestionEdited = (question: OnboardingQuestion) => {
    const questions = [...formValues.challengeQuestions] as any
    questions[selectedQuestionIndex] = question
    setFormValues({
      ...formValues,
      challengeQuestions: questions as any,
    })
    setSelectedQuestionIndex(-1)
  }

  const handleClickEditQuestion = (index: number) => {
    setSelectedQuestionIndex(index)
    setQuestionDialogOpen(true)
  }

  const handleClickDeleteQuestion = (index: number) => {
    const questions = [...formValues.challengeQuestions] as any
    questions.splice(index, 1)
    setFormValues({
      ...formValues,
      challengeQuestions: questions as any,
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {onboardingPage ? 'Edit' : 'Add'} Onboarding Page
      </DialogTitle>
      <DialogContent dividers>
        <StyledTextField
          value={formValues.title}
          onChange={handleChange}
          name="title"
          label="Title"
          error={formValues.invalid_title}
          helperText={formValues.invalid_title ? 'Title is required' : ''}
          fullWidth
        />
        <StyledTextField
          value={formValues.link}
          onChange={handleChange}
          name="link"
          label="Link"
          error={formValues.invalid_link}
          helperText={
            formValues.invalid_link
              ? formValues.link.length === 0
                ? 'Link is required'
                : 'Link is invalid'
              : ''
          }
          fullWidth
        />
        <AddQuestionBtn variant="contained" onClick={handleClickAddQuestion}>
          Add Question
        </AddQuestionBtn>
        {formValues.invalid_challengeQuestions && (
          <Typography color="error" variant="body2">
            Please add at least one question
          </Typography>
        )}
        <List>
          {formValues.challengeQuestions.map(
            (item: OnboardingQuestion, index) => (
              <React.Fragment>
                <ListItem
                  key={index}
                  secondaryAction={
                    <Box>
                      <IconButton
                        onClick={() => handleClickEditQuestion(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDeleteQuestion(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <QuizIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <OneLineTypography>{item.question}</OneLineTypography>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            )
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClickAdd}>
          {onboardingPage ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
      <AddQuestionDialog
        open={questionDialogOpen}
        onClose={handleQuestionDialogClose}
        onSuccess={
          selectedQuestionIndex === -1 ? onQuestionCreated : onQuestionEdited
        }
        onboardingQuestion={
          formValues.challengeQuestions[selectedQuestionIndex]
        }
      />
    </Dialog>
  )
}

import { Button } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

type ViewButtonProps = {
  onClick: () => void
  text: string
}

export default function ViewButton({ onClick, text }: ViewButtonProps) {
  return (
    <Button variant="outlined" onClick={onClick}>
      <span>{text}</span>
      <ArrowForwardIcon sx={{ marginLeft: '5px', fontSize: '20px' }} />
    </Button>
  )
}

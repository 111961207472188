import { deleteSkill, getSkills } from '@assembly/api/skills'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import SkillsList from './SkillsList'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { Skill } from './types'
import CreateSkillDialog from './CreateSkillDialog'
import { debounce } from 'lodash'

export default function Skills() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const didMount = React.useRef(false)
  const { store, setSkills } = useAppStore()
  const { addAlert } = useSnackbars()
  const [searchResults, setSearchResults] = React.useState<Skill[]>()
  const [isSearching, setIsSearching] = React.useState<boolean>(false)
  const [createSkillDialogIsVisible, setCreateSkillDialogIsVisible] =
    React.useState<boolean>(false)
  const [selectedSkill, setSelectedSkill] = React.useState<{
    skill: Skill | undefined
    index: number
  }>({
    skill: undefined,
    index: -1,
  })
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isSkillsLoaded) {
        getSkillsAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const getSkillsAsync = async () => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams()
      params.append('search', '')
      const { data } = await getSkills(params)
      setSkills(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleSearchSkills = async (value: string) => {
    try {
      setIsSearching(true)
      const params = new URLSearchParams()
      params.append('search', value)
      const { data } = await getSkills(params)
      setSearchResults(data)
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeSearch = debounce((value: string) => {
    if (value === '') {
      setSearchResults(undefined)
      return
    }

    handleSearchSkills(value)
  }, 500)

  const handleCloseSkillDialog = () => {
    setCreateSkillDialogIsVisible(false)
    setSelectedSkill({
      skill: undefined,
      index: -1,
    })
  }

  const onSkillCreated = (skill: Skill) => {
    setSkills([skill, ...store.skills])
  }

  const onSkillEdited = (skill: Skill) => {
    if (searchResults) {
      const skills = [...searchResults]
      skills[selectedSkill.index] = skill
      setSearchResults(skills)

      const skillIndexInStore = store.skills.findIndex((s) => s.id === skill.id)
      if (skillIndexInStore > -1) {
        const skills = [...store.skills]
        skills[skillIndexInStore] = skill
        setSkills(skills)
      }
      return
    }

    const skills = [...store.skills]
    skills[selectedSkill.index] = skill
    setSkills(skills)
  }

  const handleClickEditSkill = (skill: Skill, index: number) => {
    setSelectedSkill({
      skill,
      index,
    })
    setCreateSkillDialogIsVisible(true)
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedSkill({ skill: undefined, index: -1 })
  }

  const removeSkillFromList = () => {
    if (selectedSkill.index === -1) {
      return
    }

    if (searchResults) {
      const skills = [...searchResults]
      skills.splice(selectedSkill.index, 1)
      setSearchResults(skills)

      const skillIndexInStore = store.skills.findIndex(
        (s) => s.id === selectedSkill.skill?.id
      )
      if (skillIndexInStore > -1) {
        const skills = [...store.skills]
        skills.splice(skillIndexInStore, 1)
        setSkills(skills)
      }

      return
    }

    store.skills.splice(selectedSkill.index, 1)
    setSkills([...store.skills])
  }

  const deleteSkillAsync = async () => {
    if (!selectedSkill.skill) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteSkill(selectedSkill.skill.id)
      setIsDeleting(false)
      setSelectedSkill({ skill: undefined, index: -1 })
      setDeleteInputValue('')
      removeSkillFromList()
      addAlert({ message: 'Skill Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      setIsDeleting(false)
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
    }
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const handleClickDeleteSkill = (skill: Skill, index: number) => {
    setSelectedSkill({
      skill,
      index,
    })
    setConfirmDeleteDialogVisible(true)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        onChangeSearch={handleChangeSearch}
        inputPlaceholder="Search Skills"
        buttonLabel="Create Skill"
        onClickButton={() => setCreateSkillDialogIsVisible(true)}
        isSearching={isSearching}
      />
      {searchResults && searchResults.length === 0 && (
        <Typography marginTop={6}>No skills found</Typography>
      )}
      <SkillsList
        skills={searchResults || store.skills}
        onClickDelete={handleClickDeleteSkill}
        onClickEdit={handleClickEditSkill}
      />
      <CreateSkillDialog
        open={createSkillDialogIsVisible}
        onClose={handleCloseSkillDialog}
        skill={selectedSkill.skill}
        onSuccess={selectedSkill.skill ? onSkillEdited : onSkillCreated}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedSkill.skill?.name || ''}
        onClickDelete={deleteSkillAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

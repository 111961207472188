import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, Typography, colors } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { interpolateRouteUtil, stringAvatar } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import { AssemblyLine } from './types'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import { capitalize } from 'lodash'

interface AssemblyLinesListProps {
  assemblyLines: AssemblyLine[]
  onClickDelete: (assemblyLine: AssemblyLine, index: number) => void
  onClickEdit: (assemblyLine: AssemblyLine, index: number) => void
}

export default function AssemblyLinesList(props: AssemblyLinesListProps) {
  const navigate = useNavigate()

  const handleClickAssemblyLine = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.AssemblyLine, {
        id,
      })
    )
  }

  return (
    <List>
      {props.assemblyLines.map((assemblyLine, index) => {
        if (!assemblyLine) return null

        return (
          <div key={index}>
            <ListItem
              sx={{ padding: 0 }}
              secondaryAction={
                <Box display="flex" gap={3}>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => props.onClickEdit(assemblyLine, index)}
                      edge="end"
                      aria-label="delete"
                      sx={{ color: colors.grey[800] }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => props.onClickDelete(assemblyLine, index)}
                      edge="end"
                      aria-label="delete"
                      sx={{ color: colors.grey[800] }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            >
              <ListItemButton
                onClick={() => handleClickAssemblyLine(assemblyLine.id)}
              >
                <ListItemAvatar>
                  <Avatar {...stringAvatar(assemblyLine.name)}>
                    <LinearScaleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={assemblyLine.name || ''}
                  secondary={
                    <Box
                      component="span"
                      sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography variant="body2" component="span">
                        {assemblyLine.description || ''}
                      </Typography>
                      <Box
                        component="span"
                        sx={{ display: 'flex', gap: '10px' }}
                      >
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ marginTop: '10px', color: 'black' }}
                        >
                          <b>Status:</b> {capitalize(assemblyLine.status) || ''}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ marginTop: '10px', color: 'black' }}
                        >
                          <b>Org:</b> {assemblyLine.org?.name || ''}
                        </Typography>
                      </Box>
                    </Box>
                  }
                  sx={{ marginRight: '100px' }}
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </div>
        )
      })}
    </List>
  )
}

export enum JobStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
  CLOSED = 'CLOSED',
}

export type Job = {
  id: string
  title: string
  description: string
  atsLink: string
  atsID: string
  videoInterviewLink: string
  skillAssessmentIDs: string[]
  status: JobStatus
}

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, colors } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { interpolateRouteUtil, stringAvatar } from '@assembly/utils'
import { AssemblyTemplate } from './types'
import { RoutePaths } from '@assembly/constants'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import LinkIcon from '@mui/icons-material/Link'
import React from 'react'

interface AssemblyTemplatesListProps {
  assemblyTemplates: AssemblyTemplate[]
  onClickDelete: (assemblyTemplate: AssemblyTemplate, index: number) => void
  onClickEdit: (assemblyTemplate: AssemblyTemplate, index: number) => void
}

export default function AssemblyTemplatesList(
  props: AssemblyTemplatesListProps
) {
  const navigate = useNavigate()
  const tooltipLeaveDelay = 200
  const [copyTooltipText, setCopyTooltipText] = React.useState(
    'Copy Assembly Page Link'
  )

  const handleClickAssemblyTemplate = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.AssemblyTemplate, {
        id,
      })
    )
  }

  const handleClickLinkCopy = (id: string) => {
    const origin = process.env.REACT_APP_ENV_PARTNER_DASHBOARD_URL
    const path = `/assembly/${id}/public`
    const link = origin + path
    navigator.clipboard.writeText(link)
    setCopyTooltipText('Copied')
  }

  const handleMouseLeaveCopy = () => {
    setTimeout(() => {
      setCopyTooltipText('Copy Assembly Page Link')
    }, tooltipLeaveDelay)
  }

  return (
    <List>
      {props.assemblyTemplates.map((assemblyTemplate, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title={copyTooltipText}>
                  <IconButton
                    onClick={() => handleClickLinkCopy(assemblyTemplate.id)}
                    onMouseLeave={handleMouseLeaveCopy}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(assemblyTemplate, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(assemblyTemplate, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton
              onClick={() => handleClickAssemblyTemplate(assemblyTemplate.id)}
            >
              <ListItemAvatar>
                <Avatar {...stringAvatar(assemblyTemplate.name)}>
                  <BlurLinearIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={assemblyTemplate.name || ''}
                secondary={assemblyTemplate.description || ''}
                sx={{ marginRight: 15 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

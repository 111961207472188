import React from 'react'
import { ConfirmDeleteDialog, SearchInputAndButton } from '@assembly/components'
import { Box, CircularProgress, Typography } from '@mui/material'
import CreateOrganizationDialog from './CreateOrganizationDialog'
import {
  deleteOrganization,
  getOrganizations,
  searchOrganizations,
} from '@assembly/api/organization'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import OrganizationsList from './OrganizationsList'
import { Organization } from './types'
import { SnackbarType } from '@assembly/contexts'
import { debounce } from 'lodash'
import { getApiErrorMsg } from '@assembly/utils'

export default function OrganizationManager() {
  const [createOrgDialogIsVisible, setCreateOrgDialogIsVisible] =
    React.useState(false)
  const [organizationSearchResults, setOrganizationSearchResults] =
    React.useState<Organization[]>()
  const [isSearching, setIsSearching] = React.useState<boolean>(false)
  const [selectedOrganization, setSelectedOrganization] = React.useState<{
    organization?: Organization
    index: number
  }>({
    index: -1,
  })
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [confirmDeleteDialogIsVisible, setConfirmDeleteDialogVisible] =
    React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [deleteInputValue, setDeleteInputValue] = React.useState<string>('')
  const didMount = React.useRef(false)
  const { store, setOrganizations } = useAppStore()
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!store.isOrganizationsLoaded) {
        getOrganizationsAsync()
      } else {
        setIsLoading(false)
      }
    }
  }, [])

  const getOrganizationsAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getOrganizations()
      if (data && Array.isArray(data)) {
        setOrganizations([...data.reverse()])
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
    }
  }

  const handleClickCreateButton = () => {
    toggleCreateOrganizationDialogVisibility(true)
  }

  const toggleCreateOrganizationDialogVisibility = (value: boolean) => {
    setCreateOrgDialogIsVisible(value)
  }

  const handleClickDeleteOrganization = (
    organization: Organization,
    index: number
  ): void => {
    setSelectedOrganization({
      index: index,
      organization,
    })
    setConfirmDeleteDialogVisible(true)
  }

  const handleClickEditOrganization = (
    organization: Organization,
    index: number
  ): void => {
    setSelectedOrganization({
      index: index,
      organization,
    })
    toggleCreateOrganizationDialogVisibility(true)
  }

  const handleSearchOrganizations = async (value: string) => {
    try {
      setIsSearching(true)
      const { data } = await searchOrganizations(value)
      setOrganizationSearchResults(data)
      setIsSearching(false)
    } catch (error) {
      setIsSearching(false)
      addAlert({ message: getApiErrorMsg(error), type: SnackbarType.Error })
    }
  }

  const handleChangeSearch = debounce((value: string) => {
    if (value === '') {
      setOrganizationSearchResults(undefined)
      return
    }

    handleSearchOrganizations(value)
  }, 500)

  // const handleChangeSearch = React.useCallback(
  //   (value: string) => {
  //     if (value) {
  //       const filtered = store.organizations.filter(
  //         (org) => org.name.toLowerCase().indexOf(value.toLowerCase()) > -1
  //       )
  //       setOrganizationSearchResults(filtered)
  //     } else {
  //       setOrganizationSearchResults(undefined)
  //     }
  //   },
  //   [store.organizations]
  // )

  const onOrganizationCreated = (value: Organization): void => {
    store.organizations.unshift(value)
    setOrganizations([...store.organizations])
  }

  const onOrganizationEdited = (value: Organization): void => {
    store.organizations[selectedOrganization.index] = value
    setOrganizations([...store.organizations])
  }

  const handleCloseConfirmDeleteDialog = (): void => {
    setConfirmDeleteDialogVisible(false)
    setDeleteInputValue('')
    setSelectedOrganization({
      organization: undefined,
      index: -1,
    })
  }

  const handleChangeDeleteInputValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDeleteInputValue(e.target.value)
  }

  const removeOrganizationFromList = () => {
    if (selectedOrganization.index === -1) {
      return
    }

    store.organizations.splice(selectedOrganization.index, 1)
    setOrganizations([...store.organizations])
  }

  const deleteOrganizationAsync = async () => {
    if (!selectedOrganization.organization) {
      return
    }

    setIsDeleting(true)

    try {
      await deleteOrganization(selectedOrganization.organization.id)
      setIsDeleting(false)
      setSelectedOrganization({ organization: undefined, index: -1 })
      setDeleteInputValue('')
      removeOrganizationFromList()
      addAlert({ message: 'Organization Deleted!', type: SnackbarType.Success })
      setConfirmDeleteDialogVisible(false)
    } catch (error) {
      console.error(error)
      setIsDeleting(false)
      addAlert({ message: 'Error', type: SnackbarType.Error })
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <SearchInputAndButton
        buttonLabel="Create Organization"
        onChangeSearch={handleChangeSearch}
        onClickButton={handleClickCreateButton}
        inputPlaceholder="Search Organizations"
        isSearching={isSearching}
      />
      {organizationSearchResults && organizationSearchResults.length === 0 && (
        <Typography marginTop={6}>No organizations found</Typography>
      )}
      <OrganizationsList
        organizations={organizationSearchResults || store.organizations}
        onClickDelete={handleClickDeleteOrganization}
        onClickEdit={handleClickEditOrganization}
      />
      <CreateOrganizationDialog
        open={createOrgDialogIsVisible}
        onClose={() => {
          setSelectedOrganization({
            organization: undefined,
            index: -1,
          })
          toggleCreateOrganizationDialogVisibility(false)
        }}
        onSuccess={
          selectedOrganization.organization
            ? onOrganizationEdited
            : onOrganizationCreated
        }
        organization={selectedOrganization.organization}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteDialogIsVisible}
        onClose={handleCloseConfirmDeleteDialog}
        name={selectedOrganization.organization?.name || ''}
        onClickDelete={deleteOrganizationAsync}
        isLoading={isDeleting}
        inputValue={deleteInputValue}
        onChangeInput={handleChangeDeleteInputValue}
      />
    </Box>
  )
}

import React from 'react'
import {Avatar, Box, Button, CircularProgress, Typography} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppStore, useSnackbars} from '@assembly/hooks'
import {SnackbarType} from '@assembly/contexts'
import {getMediaUrl, interpolateRouteUtil} from '@assembly/utils'
import {InfoItem, LinkButton} from '@assembly/components'
import {getResource} from '@assembly/api/resources'
import {Resource as IResource} from '../Resources/types'
import {capitalize} from 'lodash'
import {styled} from '@mui/material/styles'
import {RoutePaths} from '@assembly/constants'
import CreateResourceDialog from '../Resources/CreateResourceDialog'
import CreateResourcePayoutDialog from './CreateResourcePayoutDialog'
import moment from "moment";
import {baseURL} from "@assembly/config/axios";
import {Label, Title} from "@mui/icons-material";
import {Media} from "@assembly/shared/types/media";

const PayoutBtnsBox = styled(Box)({
  display: 'flex',
  gap: '15px',
  marginTop: '15px',
})

const EditBtnBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
})

const EditBtn = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  marginBottom: '-30px',
})

export default function Resource() {
  const params = useParams<{ id: string }>()
  const id = params.id || ''
  const didMount = React.useRef(false)
  const [resourceData, setResourceData] = React.useState<IResource>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { addAlert } = useSnackbars()
  const { setAppBarTitle } = useAppStore()
  const navigate = useNavigate()
  const [resourceDialogOpen, setResourceDialogOpen] = React.useState(false)
  const { store, setResources } = useAppStore()
  const [resourcePayoutDialogOpen, setResourcePayoutDialogOpen] =
    React.useState(false)

  React.useEffect(() => {
    if (!didMount.current) {
      getResourceAsync()
      didMount.current = true
    }

    return () => {
      setAppBarTitle('')
    }
  }, [])

  const getResourceAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getResource(id)
      setResourceData(data)
      setAppBarTitle(`${data.firstName} ${data.lastName}`)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: 'Error: Failed to load Resource',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickPayoutEntries = () => {
    navigate(interpolateRouteUtil(RoutePaths.ResourcePayoutEntries, { id }))
  }

  const handleClickPayoutInvoices = () => {
    navigate(interpolateRouteUtil(RoutePaths.ResourcePayoutInvoices, { id }))
  }

  const handleCloseResourceDialog = () => {
    setResourceDialogOpen(false)
  }

  const handleClickEdit = () => {
    setResourceDialogOpen(true)
  }

  const MainSectionLabel = styled(Typography)(() => ({
        fontSize: '20px',
        fontWeight: 500,
        marginBottom: '10px',
    }))

  const onEditResource = (value: IResource): void => {
    const index = store.resources.findIndex(
      (resource) => resource.id === value.id
    )
    if (index !== -1) {
      store.resources[index] = value
      setResources([...store.resources])
      setResourceData({ ...value })
    }
  }

  const handleCloseDialog = () => {
    setResourcePayoutDialogOpen(false)
  }

  const handleClickCreatePayout = () => {
    setResourcePayoutDialogOpen(true)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }



  return (
    <Box>
      <EditBtnBox>
        <EditBtn variant="contained" onClick={handleClickEdit}>
          Edit
        </EditBtn>
      </EditBtnBox>
      <Box marginBottom={2} display="flex" justifyContent="center">
        <Avatar
          src={getMediaUrl(resourceData?.profilePicture?.media || '')}
          sx={{ width: 150, height: 150 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <InfoItem label="Label" value={resourceData?.label || '---'} />
        <InfoItem label="First Name" value={resourceData?.firstName || '---'} />
        <InfoItem label="Last Name" value={resourceData?.lastName || '---'} />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <InfoItem label="Email" value={resourceData?.email || '---'} />
        <InfoItem
          label="Assembly Email"
          value={resourceData?.assemblyEmail || '---'}
        />
        <InfoItem label="Phone" value={resourceData?.phone || '---'} />
      </Box>
      <InfoItem
        label="Profile Link"
        value={resourceData?.profileLink || '---'}
        isLink={!!resourceData?.profileLink}
      />
      <InfoItem
        label="Intro Link"
        value={resourceData?.introLink || '---'}
        isLink={!!resourceData?.introLink}
      />
      <InfoItem
        label="Capabilites"
        value={
          resourceData?.capabilities
            .map((capability) => capability.name)
            .join(', ') || '---'
        }
      />
      <InfoItem label="Bio" value={resourceData?.bio || '---'} />
      <InfoItem
        label="Available Start Date"
        value={resourceData?.availableStartDate || '---'}
      />
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <InfoItem
          label="Status"
          value={capitalize(resourceData?.status) || '---'}
        />
        <InfoItem
          label="Payout Frequency"
          value={capitalize(resourceData?.payoutFrequency) || '---'}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <InfoItem
          label="Payout Currency"
          value={resourceData?.payoutCurrency || '---'}
        />
        <InfoItem
          label="Payment Country"
          value={resourceData?.paymentCountry || '---'}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <InfoItem
          label="Desired Weekly Hours"
          value={resourceData?.desiredWeeklyHours?.toString() || '---'}
        />
        <InfoItem
          label="Desired Hourly Rate"
          value={'$' + resourceData?.desiredHourlyRate?.toString() || '---'}
        />
        <InfoItem
          label="Available Weekly Hours"
          value={resourceData?.availableWeeklyHours?.toString() || '---'}
        />
      </Box>
        <Box display="flex" justifyContent="space-between" gap={1.5}>
            <MainSectionLabel variant="body1">Must complete onboarding items</MainSectionLabel>
        </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
            <InfoItem
                label="Has Signed Contract"
                value={resourceData?.hasSignedContract ? 'Yes' : 'No' || '---'}
            />
            <InfoItem
                label="Has Completed Onboarding"
                value={resourceData?.hasCompletedOnboarding ? 'Yes' : 'No' || '---'}
            />
          <InfoItem
              label="Has completed profile"
              value={resourceData?.hasCompletedProfile ? 'Yes' : 'No' || '---'}
          />
          <InfoItem
              label="Has completed payment setup"
              value={resourceData?.paymentSetupComplete ? 'Yes' : 'No' || '---'}
          />
          <InfoItem
              label="Connect Account ID"
              value={resourceData?.paymentAccountID ? resourceData?.paymentAccountID : 'Not setup'}
          />
          <InfoItem
              label="Has completed W8BEN form"
              value={resourceData?.w8BEN !== null ? 'Yes' : 'No' || '---'}
          />
        </Box>
        <Box style={{marginBottom: 10}}>
            <MainSectionLabel variant="body1">Tax Forms</MainSectionLabel>
            <Box>
                <Box>
                    <Typography>
                        W8BEN form :
                        {resourceData?.w8BEN ? (
                            <a href={baseURL + "/media/private/admin/file/" + (resourceData?.w8BEN as Media).id} target="_blank"> Download </a>
                        ) : 'No W8BEN form uploaded'}
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box  display="flex" justifyContent="space-between" gap={1.5}>
            <MainSectionLabel variant="body1">Associated Assembly Lines</MainSectionLabel>
        </Box>
        {resourceData?.associatedAssemblyLines.map((assembly) => (
            <Box style={{marginTop: 10}}  display="flex" justifyContent="space-between" gap={1.5} key={assembly.id}>
                <PayoutBtnsBox>
                    <LinkButton variant={"contained"} label={"Assembly"} onClick={() => { navigate(`/assembly-lines/${assembly.id}`) }} />
                </PayoutBtnsBox>
                <PayoutBtnsBox>
                    <LinkButton variant={"contained"} label={"Org"} onClick={() => { navigate(`/orgs/${assembly.orgID}`) }} />
                </PayoutBtnsBox>
                <InfoItem
                    label="Assembly Name"
                    value={assembly.name}
                />
                <InfoItem
                    label="Start Date"
                    value={moment(assembly.startDate).format(
                        'MM-DD-YYYY'
                    )}
                />
            </Box>
        ))}
        <Box  style={{marginTop: 10}} display="flex" justifyContent="space-between" gap={1.5}>
            <MainSectionLabel variant="body1">Payout Operations</MainSectionLabel>
        </Box>
      <PayoutBtnsBox>
        <LinkButton label="Payout Entries" onClick={handleClickPayoutEntries} />
        <LinkButton
          label="Payout Invoices"
          onClick={handleClickPayoutInvoices}
        />
        <LinkButton label="Create Payout" onClick={handleClickCreatePayout} />
      </PayoutBtnsBox>
      <CreateResourceDialog
        open={resourceDialogOpen}
        onClose={handleCloseResourceDialog}
        resource={resourceData}
        onSuccess={onEditResource}
      />
      <CreateResourcePayoutDialog
        open={resourcePayoutDialogOpen}
        onClose={handleCloseDialog}
        resource={resourceData}
      />
    </Box>
  )
}

import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'

const PlusIcon = styled(AddIcon)({
  marginRight: '5px',
  width: '18px',
})

const ButtonStyled = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '10px',
  paddingRight: '12px',
})

const SearchIconStyled = styled(SearchIcon)({
  width: '25px',
})

const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}))

const SearchInputBox = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: '40px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginBottom: '10px',
  },
}))

type SearchInputProps = {
  onClickButton?: () => void
  buttonLabel?: string
  onChangeSearch: (value: string) => void
  inputPlaceholder: string
  isSearching?: boolean
}

export default function SearchInputAndButton(props: SearchInputProps) {
  const {
    onChangeSearch,
    onClickButton,
    buttonLabel,
    inputPlaceholder,
    isSearching,
  } = props
  const [searchInput, setSearchInput] = React.useState<string>('')

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchInput(value)
    onChangeSearch(value)
  }

  return (
    <MainBox>
      <SearchInputBox>
        <TextField
          value={searchInput}
          margin="dense"
          id="name"
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          autoComplete="off"
          onChange={handleChangeSearch}
          placeholder={inputPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconStyled />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {isSearching && <CircularProgress size={20} />}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          sx={{ margin: 0 }}
        />
      </SearchInputBox>
      {buttonLabel && (
        <ButtonBox>
          <ButtonStyled variant="contained" onClick={onClickButton}>
            <PlusIcon />
            <span>{buttonLabel}</span>
          </ButtonStyled>
        </ButtonBox>
      )}
    </MainBox>
  )
}

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { ListItemButton, colors } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { interpolateRouteUtil, stringAvatar } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import { RoleLabelMapping, TeamMember } from '@assembly/pages/Teams/types'

interface TeamMembersListProps {
  teamMembers: TeamMember[]
  onClickDelete: (teamMember: TeamMember, index: number) => void
  onClickEdit: (teamMember: TeamMember, index: number) => void
}

export default function TeamMembersList(props: TeamMembersListProps) {
  const navigate = useNavigate()
  const params = useParams()
  const handleClickTeamMember = (id: string) => {
    navigate(
      interpolateRouteUtil(RoutePaths.TeamMember, {
        orgId: params.orgId || '',
        teamId: params.teamId,
        teamMemberId: id,
      })
    )
  }

  return (
    <List>
      {props.teamMembers.map((teamMember, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(teamMember, index)}
                    edge="end"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(teamMember, index)}
                    edge="end"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton
              onClick={() => handleClickTeamMember(teamMember.id)}
            >
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(teamMember.name || teamMember.email || '')}
                />
              </ListItemAvatar>
              <ListItemText
                primary={teamMember.name || teamMember.email || ''}
                secondary={RoleLabelMapping[teamMember.role] || ''}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

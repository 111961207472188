import { Allocation } from '@assembly/pages/AssemblyTemplates/types'
import { Capability } from '@assembly/pages/Capabilities/types'
import { TeamMember } from '@assembly/pages/Teams/types'
import { Organization } from '@assembly/pages/OrganizationManager/types'

export type AssemblyLine = {
  id: string
  name: string
  description: string
  orgID: string
  org?: Organization | null
  capabilities: Capability[]
  monthlySubscriptionCost: number | null
  allocations: Allocation[]
  startDate: number
  endDate: number
  timezone: string
  owner: string | TeamMember | null
  members: TeamMember[]
  funding: Funding
  approved: boolean
  approvedBy: string
  projectType: string
  status: string
  statusMessage: string
  projectManagement: ProjectManagement
  manager: ProjectManagement
  checklists: any
  requirements: {}
}

export type Funding = {
  billingAmount: number | null
  currency: string
  frequency: string
  startDate: string
  endDate: string
  lastSuccessfulPayment: number | null
  status: string
  statusMsg: string
  setupLink: string
  paymentLink: string
  apContacts: string[]
}

export type FundingForm = {
  billingAmount: number | null
  currency: string
  frequency: string | null
  startDate: any
  endDate: any
  lastSuccessfulPayment: number | null
  status: string | null
  statusMsg: string
  setupLink: string
  paymentLink: string
  apContacts: string[]
  invalid_billingAmount: boolean
  invalid_frequency: boolean
  invalid_startDate: boolean
  invalid_endDate: boolean
  invalid_status: boolean
}

export type AssemblyLineForm = {
  name: string
  invalid_name: boolean
  description: string
  invalid_description: boolean
  organization?: Organization | null
  owner: TeamMember | null
  invalid_owner: boolean
  invalid_organization: boolean
  capabilities: Capability[]
  invalid_capabilities: boolean
  monthlySubscriptionCost: number | null
  invalid_monthlySubscriptionCost: boolean
  allocations: Allocation[]
  invalid_allocations: boolean
  startDate: any
  invalid_startDate: boolean
  invalid_endDate: boolean
  endDate: any
  timezone: string
  members: TeamMember[]
  invalid_members: boolean
  funding: FundingForm
  approved: boolean
  approvedBy: string
  projectType: string
  invalid_projectType: boolean
  status: string | null
  invalid_status: boolean
  statusMessage: string
  // Project Management
  projectManagementLink: string
  submitTaskLink: string
  kanbanLink: string
  teamID: string
  projectID: string
  requirements: {}
}

export enum FundingFrequency {
  OneTime = 'ONE_TIME',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
}

export enum FundingStatus {
  Initialized = 'INITIALIZED',
  SetupComplete = 'SETUP_COMPLETE',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Error = 'ERROR',
}

export enum ProjectTypes {
  Recurring = 'RECURRING',
  OneTime = 'ONE_TIME',
  OneTimeToRecurring = 'ONE_TIME_TO_RECURRING',
  MonthlyInvoice_net0 = 'MONTHLY_INVOICE_NET_0',
  MonthlyInvoice_net7 = 'MONTHLY_INVOICE_NET_7',
  MonthlyInvoice_net15 = 'MONTHLY_INVOICE_NET_15',
  MonthlyInvoice_net30 = 'MONTHLY_INVOICE_NET_30'
}

export enum AssemblyLineStatus {
  Initialized = 'INITIALIZED',
  Setup = 'SETUP',
  Proposal = 'PROPOSAL',
  Provisioning = 'PROVISIONING',
  Ready = 'READY',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Complete = 'COMPLETE',
  Archived = 'ARCHIVED',
  Modify = 'MODIFY',
}

type ProjectManagement = {
  projectManagementLink: string
  submitTaskLink: string
  kanbanLink: string
  teamID: string
  projectID: string
}

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import { Resource } from './types'
import { ListItemButton, Typography, colors } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  getMediaUrl,
  interpolateRouteUtil,
  stringAvatar,
} from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'
import { capitalize } from 'lodash'
import LinkIcon from '@mui/icons-material/Link'
import React from 'react'

interface ResourcesListProps {
  resources: Resource[]
  onClickDelete: (resource: Resource, index: number) => void
  onClickEdit: (resource: Resource, index: number) => void
}

export default function ResourcesList(props: ResourcesListProps) {
  const tooltipLeaveDelay = 200

  const [copyTooltipText, setCopyTooltipText] =
    React.useState('Copy Profile Link')
  const navigate = useNavigate()
  const handleClickResource = (id: string) => {
    navigate(interpolateRouteUtil(RoutePaths.Resource, { id }))
  }

  const handleClickLinkCopy = (id: string) => {
    const origin = process.env.REACT_APP_ENV_PARTNER_DASHBOARD_URL
    const path = `/talent/${id}`
    const link = origin + path
    navigator.clipboard.writeText(link)
    setCopyTooltipText('Copied')
  }

  const handleMouseLeaveCopy = () => {
    setTimeout(() => {
      setCopyTooltipText('Copy Profile Link')
    }, tooltipLeaveDelay)
  }

  return (
    <List>
      {props.resources.map((resource, index) => (
        <div key={index}>
          <ListItem
            sx={{ padding: 0 }}
            secondaryAction={
              <Box display="flex" gap={3}>
                <Tooltip title={copyTooltipText}>
                  <IconButton
                    onClick={() => handleClickLinkCopy(resource.id)}
                    onMouseLeave={handleMouseLeaveCopy}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => props.onClickEdit(resource, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => props.onClickDelete(resource, index)}
                    edge="end"
                    aria-label="delete"
                    sx={{ color: colors.grey[800] }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            <ListItemButton onClick={() => handleClickResource(resource.id)}>
              <ListItemAvatar>
                <Avatar
                  {...stringAvatar(resource.firstName)}
                  src={getMediaUrl(resource.profilePicture?.media || '')}
                  alt={`${resource.firstName} ${resource.lastName}`}
                  imgProps={{
                    loading: 'lazy',
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${resource.firstName} ${resource.lastName}` || ''}
                secondary={
                  <Typography component="span" variant="body2">
                    <b>Status: </b>
                    {capitalize(resource.status) || ''}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  )
}

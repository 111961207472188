import React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/lab/LoadingButton'

interface ConfirmDeleteDialogProps {
  open: boolean
  onClose: () => void
  name: string
  onClickDelete: () => void
  isLoading: boolean
  inputValue: string
  onChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const deleteStr: string = 'delete'

export default function ConfirmDeleteDialog(props: ConfirmDeleteDialogProps) {
  const {
    open,
    onClose,
    name,
    isLoading,
    onClickDelete,
    inputValue,
    onChangeInput,
  } = props

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To confirm you want to delete {name}, type delete in the field.
          </DialogContentText>
          <TextField
            value={inputValue}
            margin="dense"
            label="delete"
            type="text"
            fullWidth
            variant="standard"
            onChange={onChangeInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={inputValue !== deleteStr}
            onClick={onClickDelete}
            loading={isLoading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: '#1b17f514',
      color: '#000',
      fontWeight: 600,
    },
    children: `${name.split(' ')[0][0]}`,
  }
}

import { Capability } from "../Capabilities/types"
import { Skill } from "../Skills/types"

export type SkillAssessment = {
    id: string
    capabilities: Capability[]
    skills: Skill[]
    title: string
    description: string
    instructions: string
    hint: string
    durationSecs: number
    difficulty: SkillAssessmentDifficulty
    internalNotes: string
    assessmentCreationPrompt: string
    assessmentScoringPrompt: string
    type: SkillAssessmentType
}

export enum SkillAssessmentType {
    CODING = 'CODING',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    VIDEO = 'VIDEO',
    WRITTEN = 'WRITTEN',
}

export enum SkillAssessmentDifficulty {
    EASY = 'EASY',
    MEDIUM = 'MEDIUM',
    HARD = 'HARD',
}
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography,
  Divider,
  Autocomplete,
  Chip,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import {
  OnboardingPage,
  OnboardingQuestion,
  Portfolio,
  PortfolioType,
} from '@assembly/pages/Resources/types'
import { capitalize } from 'lodash'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { uploadMedia } from '@assembly/api/resources'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { Media } from '@assembly/shared/types/media'
import { CircularProgressWithLabel } from '@assembly/components'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const AddQuestionBtn = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

type AddQuestionDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: (onboardingQuestion: OnboardingQuestion) => void
  onboardingQuestion?: OnboardingQuestion
}

export default function AddQuestionDialog({
  open,
  onClose,
  onSuccess,
  onboardingQuestion,
}: AddQuestionDialogProps) {
  const initialFormValues = {
    question: '',
    answer: '',
    choices: [],
    invalid_question: false,
    invalid_answer: false,
    invalid_choices: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open && onboardingQuestion) {
      setFormValues({
        ...initialFormValues,
        question: onboardingQuestion.question,
        answer: onboardingQuestion.answer,
        choices: onboardingQuestion.choices as any,
      })
    }
  }, [open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      [`invalid_${event.target.name}`]: false,
    })
  }

  const handleClickAdd = () => {
    if (
      !formValues.question.trim() ||
      !formValues.answer.trim() ||
      formValues.choices.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_question: !formValues.question.trim(),
        invalid_answer: !formValues.answer.trim(),
        invalid_choices: formValues.choices.length === 0,
      })
      return
    }

    const payload = {
      question: formValues.question,
      answer: formValues.answer,
      choices: formValues.choices,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setFormValues(initialFormValues)
    }, 195)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{onboardingQuestion ? 'Edit' : 'Add'} Question</DialogTitle>
      <DialogContent dividers>
        <StyledTextField
          value={formValues.question}
          onChange={handleChange}
          name="question"
          label="Question"
          error={formValues.invalid_question}
          helperText={formValues.invalid_question && 'Question is required'}
          fullWidth
        />
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={formValues.choices}
          onChange={(event: any, newValue: any) => {
            setFormValues({
              ...formValues,
              choices: newValue,
              invalid_choices: false,
            })
          }}
          renderTags={(value: string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choices"
              error={formValues.invalid_choices}
              helperText={formValues.invalid_choices && 'Choices are required'}
            />
          )}
          sx={{ marginBottom: 2.5 }}
        />
        <StyledTextField
          value={formValues.answer}
          onChange={handleChange}
          name="answer"
          label="Answer"
          error={formValues.invalid_answer}
          helperText={formValues.invalid_answer && 'Answer is required'}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClickAdd}>
          {onboardingQuestion ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

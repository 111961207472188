import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import {
  CapabilitiesSearchAndSelect,
  ResourceSearchAndSelect,
} from '@assembly/components'
import { Capability } from '../Capabilities/types'
import {
  Allocation,
  AssemblyTemplate,
  CreateAllocationForm,
  CreateAssemblyTemplateForm,
  Frequency,
  ICreateAssemblyTemplate,
  RateUnit,
  Status,
  Type,
} from './types'
import { capitalize } from 'lodash'
import { Resource } from '@assembly/pages/Resources/types'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { getMediaUrl, stringAvatar } from '@assembly/utils'
import {
  createAssemblyTemplate,
  updateAssemblyTemplate,
} from '@assembly/api/assemblyTemplate'

interface CreateAssemblyTemplateDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateAssemblyTemplateDialogTitle(
  props: CreateAssemblyTemplateDialogTitleProps
) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface CreateAssemblyTemplateDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (template: AssemblyTemplate) => void
  assemblyTemplate?: AssemblyTemplate
}

export default function CreateAssemblyTemplateDialog(
  props: CreateAssemblyTemplateDialogProps
) {
  const { open, onClose, assemblyTemplate, onSuccess } = props
  const formInitialValues: CreateAssemblyTemplateForm = {
    name: '',
    description: '',
    capabilities: [],
    approximateMonthlyBudget: null,
    allocations: [],
    invalid_name: false,
    invalid_description: false,
    invalid_capabilities: false,
    invalid_approximateMonthlyBudget: false,
    invalid_allocations: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isAllocationFormVisible, setIsAllocationFormVisible] =
    React.useState<boolean>(false)
  const allocationFormInitialValues: CreateAllocationForm = {
    resourceID: '',
    amount: null,
    rate: null,
    rateUnit: '',
    frequency: '',
    status: '',
    statusMessage: '',
    approved: false,
    approvedBy: '',
    active: false,
    type: '',
    startDate: '',
    startDateAccepted: false,
    invalid_resourceID: false,
    invalid_amount: false,
    invalid_rate: false,
    invalid_rateUnit: false,
    invalid_frequency: false,
    invalid_status: false,
    invalid_type: false,
  }
  const [allocationFormValues, setAllocationFormValues] =
    React.useState<CreateAllocationForm>(allocationFormInitialValues)
  const { addAlert } = useSnackbars()
  const [resource, setResource] = React.useState<Resource | null>(null)
  const [selectedAllocation, setSelectedAllocation] = React.useState<{
    allocation?: Allocation
    index: number
  }>({
    index: -1,
  })

  React.useEffect(() => {
    if (open) {
      if (assemblyTemplate) {
        setFormValues({
          ...formValues,
          ...assemblyTemplate,
        })
      }
    }
  }, [assemblyTemplate, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.name.length === 0 ||
      formValues.description.length === 0 ||
      formValues.approximateMonthlyBudget === null ||
      formValues.capabilities.length === 0
    ) {
      setFormValues({
        ...formValues,
        invalid_name: formValues.name.length === 0,
        invalid_description: formValues.description.length === 0,
        invalid_approximateMonthlyBudget:
          formValues.approximateMonthlyBudget === null,
        invalid_capabilities: formValues.capabilities.length === 0,
      })
      return
    }

    setIsSaving(true)
    const payload: ICreateAssemblyTemplate = {
      name: formValues.name,
      description: formValues.description,
      capabilities: formValues.capabilities.map((capability) => capability.id),
      approximateMonthlyBudget: formValues.approximateMonthlyBudget,
      allocations: formValues.allocations.map((allocation) => ({
        resourceID: allocation.resource?.id || allocation.resourceID,
        amount: allocation.amount,
        rate: allocation.rate,
        rateUnit: allocation.rateUnit,
        frequency: allocation.frequency,
        status: allocation.status,
        statusMessage: allocation.statusMessage,
        approved: allocation.approved,
        approvedBy: allocation.approvedBy,
        active: allocation.active,
        type: allocation.type,
        startDate: allocation.startDate,
        startDateAccepted: allocation.startDateAccepted,
      })),
    }

    if (assemblyTemplate) {
      try {
        const { data } = await updateAssemblyTemplate({
          id: assemblyTemplate.id,
          ...payload,
        })
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Assembly Template Updated!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    } else {
      try {
        const { data } = await createAssemblyTemplate(payload)
        setIsSaving(false)
        onSuccess(data)
        addAlert({
          message: 'Assembly Template Created!',
          type: SnackbarType.Success,
        })
        handleClose()
      } catch (error: any) {
        setIsSaving(false)
        console.error(error)
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          typeof error.response.data.message === 'string'
        ) {
          addAlert({
            message: error.response.data.message,
            type: SnackbarType.Error,
          })
        }
      }
    }
  }

  const handleChangeCapabilities = (capabilities: Capability[]) => {
    setFormValues({
      ...formValues,
      capabilities,
      invalid_capabilities: false,
    })
  }

  const handleChangeAllocationFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setAllocationFormValues({
      ...allocationFormValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeAllocationFormSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setAllocationFormValues({
      ...allocationFormValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClose = () => {
    setFormValues(formInitialValues)
    setAllocationFormValues(allocationFormInitialValues)
    setSelectedAllocation({ index: -1 })
    setIsAllocationFormVisible(false)
    setIsSaving(false)
    onClose()
  }

  const handleSaveAllocation = () => {
    if (
      !resource ||
      allocationFormValues.amount === null ||
      allocationFormValues.rate === null ||
      allocationFormValues.frequency.length === 0 ||
      allocationFormValues.status.length === 0 ||
      allocationFormValues.type.length === 0 ||
      allocationFormValues.rateUnit.length === 0
    ) {
      setAllocationFormValues({
        ...allocationFormValues,
        invalid_resourceID: !resource,
        invalid_amount: allocationFormValues.amount === null,
        invalid_rate: allocationFormValues.rate === null,
        invalid_frequency: allocationFormValues.frequency.length === 0,
        invalid_status: allocationFormValues.status.length === 0,
        invalid_type: allocationFormValues.type.length === 0,
        invalid_rateUnit: allocationFormValues.rateUnit.length === 0,
      })
      return
    }

    const allocation = {
      resourceID: resource.id,
      resource: resource,
      amount: allocationFormValues.amount,
      rate: allocationFormValues.rate,
      rateUnit: allocationFormValues.rateUnit as RateUnit,
      frequency: allocationFormValues.frequency as Frequency,
      status: allocationFormValues.status as Status,
      statusMessage: allocationFormValues.statusMessage,
      type: allocationFormValues.type as Type,
      active: allocationFormValues.active,
      approved: allocationFormValues.approved,
      approvedBy: allocationFormValues.approvedBy,
      startDateAccepted: allocationFormValues.startDateAccepted,
      startDate: allocationFormValues.startDate
    }

    if (selectedAllocation.allocation && selectedAllocation.index !== -1) {
      formValues.allocations[selectedAllocation.index] = allocation
    } else {
      formValues.allocations.push(allocation)
    }

    setFormValues({
      ...formValues,
      allocations: [...formValues.allocations],
    })
    setAllocationFormValues(allocationFormInitialValues)
    setResource(null)
    setIsAllocationFormVisible(false)
  }

  const handleChangeResource = (resource: Resource) => {
    setResource(resource)
    setAllocationFormValues({
      ...allocationFormValues,
      invalid_resourceID: false,
    })
  }

  const handleClickEditAllocation = (allocation: Allocation, index: number) => {
    setAllocationFormValues({
      ...allocationFormValues,
      ...allocation,
    })
    setSelectedAllocation({
      allocation,
      index,
    })
    setResource(allocation.resource || null)

    setIsAllocationFormVisible(true)
  }

  const handleClickDeleteAllocation = (index: number) => {
    formValues.allocations.splice(index, 1)
    setFormValues({
      ...formValues,
      allocations: [...formValues.allocations],
    })
  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <CreateAssemblyTemplateDialogTitle onClose={handleClose}>
          {assemblyTemplate ? 'Edit' : 'Create'} Assembly Template
        </CreateAssemblyTemplateDialogTitle>
        <DialogContent dividers>
          <TextField
            value={formValues.name}
            margin="dense"
            label="Name"
            type="text"
            name="name"
            fullWidth
            onChange={handleChangeFormField}
            error={formValues.invalid_name}
            helperText={formValues.invalid_name ? 'Required' : ''}
          />
          <TextField
            value={formValues.description}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            error={formValues.invalid_description}
            onChange={handleChangeFormField}
            helperText={formValues.invalid_description ? 'Required' : ''}
            sx={{ margin: 0, marginTop: '20px' }}
          />
          <TextField
            value={formValues.approximateMonthlyBudget || ''}
            margin="dense"
            name="approximateMonthlyBudget"
            label="Approximate Monthly Budget"
            type="number"
            fullWidth
            error={formValues.invalid_approximateMonthlyBudget}
            onChange={handleChangeFormField}
            helperText={
              formValues.invalid_approximateMonthlyBudget ? 'Required' : ''
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            sx={{ margin: 0, marginTop: '20px' }}
          />
          <Box marginTop="20px">
            <CapabilitiesSearchAndSelect
              onChange={handleChangeCapabilities}
              value={formValues.capabilities}
              error={formValues.invalid_capabilities}
              helperText={formValues.invalid_capabilities ? 'Required' : ''}
            />
          </Box>
          <Box marginTop="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight={500}>
                Allocations
              </Typography>
              <Button
                variant="outlined"
                onClick={() => setIsAllocationFormVisible(true)}
                sx={{ opacity: isAllocationFormVisible ? 0 : 1 }}
                disabled={isAllocationFormVisible}
              >
                Add Allocation
              </Button>
            </Box>
          </Box>
          {isAllocationFormVisible && (
            <Paper sx={{ padding: 2 }}>
              <ResourceSearchAndSelect
                value={resource}
                onChange={handleChangeResource}
                error={allocationFormValues.invalid_resourceID}
                helperText={
                  allocationFormValues.invalid_resourceID
                    ? 'Please select a resource'
                    : ''
                }
                resourceID={
                  selectedAllocation.allocation?.resourceID
                    ? selectedAllocation.allocation.resourceID
                    : null
                }
              />
              <Box display="flex" flexDirection="row" gap={3} marginTop="18px">
                <TextField
                  value={allocationFormValues.amount || ''}
                  margin="dense"
                  label="Amount (in $ USD)"
                  type="number"
                  name="amount"
                  fullWidth
                  variant="outlined"
                  onChange={handleChangeAllocationFormField}
                  error={allocationFormValues.invalid_amount}
                  helperText={
                    allocationFormValues.invalid_amount ? 'Required' : ''
                  }
                  sx={{ margin: 0 }}
                />
                <TextField
                  value={allocationFormValues.rate || 160}
                  margin="dense"
                  name="rate"
                  label={`Rate (in ${allocationFormValues.rateUnit.toLowerCase()})`}
                  type="number"
                  fullWidth
                  variant="outlined"
                  error={allocationFormValues.invalid_rate}
                  onChange={handleChangeAllocationFormField}
                  helperText={
                    allocationFormValues.invalid_rate ? 'Required' : ''
                  }
                  sx={{ margin: 0 }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop="18px"
              >
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="rateUnit-select-label">
                      Rate Unit
                    </InputLabel>
                    <Select
                      labelId="rateUnit-select-label"
                      value={allocationFormValues.rateUnit}
                      label="Rate Unit"
                      name="rateUnit"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_rateUnit}
                    >
                      {Object.values(RateUnit).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_rateUnit && (
                      <FormHelperText
                        error={allocationFormValues.invalid_rateUnit}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="frequency-select-label">
                      Frequency (Weekly / Monthly)
                    </InputLabel>
                    <Select
                      labelId="frequency-select-label"
                      value={allocationFormValues.frequency}
                      label="Frequency (Weekly / Monthly)"
                      name="frequency"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_frequency}
                    >
                      {Object.values(Frequency).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_frequency && (
                      <FormHelperText
                        error={allocationFormValues.invalid_frequency}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginBottom={1}
                marginTop="20px"
              >
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select
                      labelId="type-select-label"
                      value={allocationFormValues.type}
                      label="Type"
                      name="type"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_type}
                    >
                      {Object.values(Type).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_type && (
                      <FormHelperText error={allocationFormValues.invalid_type}>
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box width="48%">
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">Status</InputLabel>
                    <Select
                      labelId="status-select-label"
                      value={allocationFormValues.status || Status.Initialized}
                      label="Status"
                      name="status"
                      onChange={handleChangeAllocationFormSelect}
                      error={allocationFormValues.invalid_status}
                    >
                      {Object.values(Status).map((value) => (
                        <MenuItem key={value} value={value}>
                          {capitalize(value)}
                        </MenuItem>
                      ))}
                    </Select>
                    {allocationFormValues.invalid_status && (
                      <FormHelperText
                        error={allocationFormValues.invalid_status}
                      >
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <TextField
                value={allocationFormValues.statusMessage || ''}
                margin="dense"
                name="statusMessage"
                label="Status Message ( System Generated )"
                type="text"
                fullWidth
                variant="outlined"
                disabled={true}
                onChange={handleChangeAllocationFormField}
                sx={{ margin: 0, marginTop: '15px' }}
              />
              <Box
                display="flex"
                alignItems="center"
                height={65}
                marginTop="18px"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={allocationFormValues.approved}
                      onChange={(event) => {
                        setAllocationFormValues({
                          ...allocationFormValues,
                          approved: event.target.checked,
                          approvedBy: '',
                        })
                      }}
                    />
                  }
                  label="Approved"
                />
                {allocationFormValues.approved && (
                  <TextField
                    value={allocationFormValues.approvedBy || ''}
                    margin="dense"
                    name="approvedBy"
                    label="Approved By"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeAllocationFormField}
                  />
                )}
              </Box>
              <Box marginTop="20px">
                <FormControlLabel
                  control={
                    <Switch
                      checked={allocationFormValues.active}
                      onChange={(event) => {
                        setAllocationFormValues({
                          ...allocationFormValues,
                          active: event.target.checked,
                        })
                      }}
                    />
                  }
                  label="Active"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAllocationFormValues(allocationFormInitialValues)
                    setIsAllocationFormVisible(false)
                    setResource(null)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSaveAllocation}
                  sx={{ marginLeft: 1 }}
                >
                  Save Allocation
                </Button>
              </Box>
            </Paper>
          )}
          <List>
            {formValues.allocations.map((allocation, index) => (
              <Paper key={index}>
                <ListItem
                  secondaryAction={
                    <Box display="flex" gap={3}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() =>
                          handleClickEditAllocation(allocation, index)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleClickDeleteAllocation(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      {...stringAvatar(allocation.resource?.firstName || '')}
                      src={getMediaUrl(
                        allocation.resource?.profilePicture?.media || ''
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      allocation.resource?.firstName +
                      '' +
                      allocation.resource?.lastName
                    }
                    secondary={capitalize(allocation.type)}
                  />
                </ListItem>
                {formValues.allocations.length - 1 !== index && <Divider />}
              </Paper>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import { Chip, CircularProgress } from '@mui/material'
import { getArrayDifferenceById } from '@assembly/utils'
import { SkillAssessment } from '@assembly/pages/SkillsAssessment/types'
import { getSkillsAssessment } from '@assembly/api/skillsAssessment'

type SkillAssessmentSearchAndSelectType = {
  onChange: (skillAssessment: SkillAssessment[]) => void
  value: SkillAssessment[]
  error?: boolean
  helperText?: string
}

export default function SkillAssessmentSearchAndSelect({
  onChange,
  value,
  error,
  helperText,
}: SkillAssessmentSearchAndSelectType) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<SkillAssessment[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const fetch = React.useMemo(
    () =>
      debounce(
        (request: { input: string }, callback: (results?: any) => void) => {
          getSkillAssessmentsAsync(request.input, callback)
        },
        400
      ),
    []
  )

  const getSkillAssessmentsAsync = async (search: string, callback: any) => {
    try {
      setLoading(true)
      const params = { search }
      const { data } = await getSkillsAssessment(params)
      callback(data)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetch({ input: inputValue }, (results?: any) => {
      setOptions([...results])
    })
  }, [inputValue, fetch])

  return (
    <Autocomplete
      multiple
      loading={loading}
      getOptionLabel={(option) => option.title}
      filterOptions={(x) => x}
      options={getArrayDifferenceById(options, value)}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_: any, newValue: any) => {
        onChange(newValue)
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add Skill Assessment"
          placeholder={value.length === 0 ? 'Search skill assessment' : ''}
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid
              item
              sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
            >
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {option.title}
              </Box>
              <Typography variant="body2" color="text.secondary">
                {option.description}
              </Typography>
            </Grid>
          </li>
        )
      }}
      renderTags={(value: any, getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            color="primary"
            label={option.title}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}

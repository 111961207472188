import { axiosWithConfig } from '@assembly/config/axios'
import { ICreateOrganization } from '@assembly/pages/OrganizationManager/types'

export const createOrganization = (payload: ICreateOrganization) => {
  return axiosWithConfig.post(`/admin/org`, payload)
}

export const updateOrganization = (payload: ICreateOrganization) => {
  return axiosWithConfig.put(`/admin/org`, payload)
}

export const deleteOrganization = (id: string) => {
  return axiosWithConfig.delete(`/admin/org/${id}`)
}

export const getOrganizations = () => {
  return axiosWithConfig.get(`/admin/org/all`)
}

export const getOrganization = (id: string) => {
  return axiosWithConfig.get(`/admin/org/${id}`)
}

export const getAllTeamsOfOrganization = (organizationId: string) => {
  return axiosWithConfig.get(`/admin/org/${organizationId}/teams`)
}

export const searchOrganizations = (query: string) => {
  const params = new URLSearchParams()
  params.append('search', query)
  return axiosWithConfig.post(`/admin/org/search`, params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}

export const getOrganizationMembers = (organizationId: string) => {
  return axiosWithConfig.get(`/admin/team_member/org/${organizationId}`)
}

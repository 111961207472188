import { axiosWithConfig } from '@assembly/config/axios'

export const getCandidates = (payload: any) => {
    return axiosWithConfig.post(`/recruiting/admin/candidates/search`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const updateCandidate = (payload: any) => {
    return axiosWithConfig.post('/recruiting/admin/candidate', payload)
}

export const updateCandidatesState = (payload: any, state: string) => {
    return axiosWithConfig.post(`/recruiting/admin/candidates/state/${state}`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const uploadMedia = (payload: any, onProgressChange?: any) => {
    return axiosWithConfig.put(`/media/admin/candidate/setup`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: any) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (onProgressChange) onProgressChange(percentCompleted)
        },
    })
}

export const sendEmail = (payload: any) => {
    return axiosWithConfig.post(`/recruiting/admin/candidates/email_update`, payload, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
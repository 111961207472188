import React, { createContext, useState, useMemo, useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface ISnackBarContext {
  addAlert: (alert: any) => void
}

const AUTO_DISMISS = 10000

export const SnackBarContext = createContext<ISnackBarContext>({
  addAlert: () => {},
})

interface SnackBarProviderProps {
  children?: React.ReactNode
}

export enum SnackbarType {
  Success = 'success',
  Error = 'error',
}

type AlertType = {
  message: string
  type: SnackbarType
}

export function SnackBarProvider({ children }: SnackBarProviderProps) {
  const [alert, setAlert] = useState<AlertType>()
  const [open, setOpen] = useState<boolean>(false)
  const [key, setKey] = useState<number>()

  const addAlert = useCallback((content: AlertType) => {
    setAlert(content)
    setOpen(true)
    setKey(new Date().getTime())
  }, [])

  const value = useMemo(() => ({ addAlert }), [addAlert])

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleExited = () => {
    setAlert(undefined)
  }

  return (
    <SnackBarContext.Provider value={value}>
      <>
        {children}
        <Snackbar
          key={alert ? key : undefined}
          open={open}
          onClose={handleClose}
          autoHideDuration={AUTO_DISMISS}
          TransitionProps={{ onExited: handleExited }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={handleClose}
            severity={alert?.type}
            sx={{ width: '100%' }}
          >
            {alert?.message}
          </Alert>
        </Snackbar>
      </>
    </SnackBarContext.Provider>
  )
}
